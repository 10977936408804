<template>
  <rj-faqs :items="faqs" />
</template>

<script>
import RjFaqs from '@/views/common/support/RjFaqs.vue';

export default {
  name: 'RjSupport',

  components: { RjFaqs },

  data() {
    return {
      faqs: [
        {
          id: 0,
          question: 'What phone number are the surveys sent from?',
          answer: 'When SMS messaging is active, each location is assigned a unique mobile number from which the text messages are sent. You can see this number on the location settings page.\n\nNote, SMS messaging is only available on the High Jump plan.',
        },
        {
          id: 1,
          question: 'What email address are the surveys sent from?',
          answer: 'Emails will be sent from the email address specified in on the funnel settings page.',
        },
        {
          id: 2,
          question: 'What happens if a customer calls the mobile number?',
          answer: 'When the SMS number is called, it may be automatically forwarded to the location\'s phone number. A checkbox for this setting can be found on the location settings page.\n\nNote, SMS messaging is only available on the High Jump plan.',
        },
        {
          id: 3,
          question: 'What is the Reputation Performance Grade and how is it calculated?',
          answer: 'Otherwise known as RPG, this number is a proprietary algorithm created by RevenueJump in order to simplify a company\'s online reputation with a score from 1 to 100. The RPG is based on the quantity, quality, and consistency of a company\'s online reviews. The higher the number, the better.',
        },
        {
          id: 4,
          question: 'What is the Competitor Review Score and how is it calculated?',
          answer: 'Otherwise known as CRS, this number is a propriety algorithm created by RevenueJump in order to simplify how a company stacks up to the competition. A score of 0 means the company is on par with their competitors. A negative score means they are falling behind, while a positive score means they are doing better than average.',
        },
        {
          id: 5,
          question: 'When sending a survey, is both the email address and mobile number required?',
          answer: 'No, only one of these fields are requried. However, we recommend inputting both because some recipients will respond to one better than the other.\n\nNote, SMS messaging is only available on the High Jump plan.',
        },
        {
          id: 6,
          question: 'When should customers be surveyed?',
          answer: 'More often than not, the best time to survey a customer is 30 minutes after the sale. However, it could also be after a non-sale, service call, referral, inquiry, etc. You will need to customize the timing for your particular business situation.',
        },
        {
          id: 7,
          question: 'What is the average conversion rate?',
          answer: 'The conversion rate for every RevenueJump account varies depending on how surveys are sent and how well they have been communicating with their customers prior to the survey. \n\n Regardless, we have found that text message surveys convert 72% better than email surveys. Note, SMS messaging is only available on the High Jump plan.',
        },
        {
          id: 8,
          question: 'How do we improve customer response rates?',
          answer: 'Although not required, conversion rates increase when the customer is expecting the survey.\n\nHere is a proven script that may be useed: "I\'m going to send you a text message and would really appreciate it if you would take just a second and answer a 1-click survey. Would you mind doing that for me please?"\n\nNote, SMS messaging is only available on the High Jump plan.',
        },
        {
          id: 9,
          question: 'Should old customers be sent surveys?',
          answer: 'Yes, of course! We recommend going back no further than 3-6 months. This is a great way to see immediate results. \n\n Note, sending an unusually high number of surveys in a review funnel within a short period of time can result in an influx of new reviews. It\'s best to ramp up the surveys over time to avoid any red flags.',
        },
        {
          id: 10,
          question: 'Can a company have multiple locations?',
          answer: 'Yes, the High Jump plan supports multiple locations. If on the Low Jump plan, upgrading to the High Jump plan would be required.',
        },
        {
          id: 11,
          question: 'How do we change the survey messages?',
          answer: 'Most funnel settings should not be changed since they were created based on years of experience and testing. If a change is still desired, the request may be given to either the RevenueJump partner or by opening a new support ticket.',
        },
        {
          id: 12,
          question: 'We sent a survey, but why did nothing happen?',
          answer: 'Chances are, you just need to wait a bit longer. Depending on the funnel settings, there may be a delay set between when the survey is initiated and when it is received. \n\n If waiting does not solve this, the contact information could be inaccurate (i.e. a typo or sending an SMS to a landline), duplicate surveys are not enabled, the message ended up in the email spam folder, or the cell phone carrier filtered out the SMS message.',
        },
        {
          id: 13,
          question: 'Is it okay to text or email customers?',
          answer: 'Requirements for sending text and/or email messages may be governed by the CAN-SPAM Act, the Telephone and Consumer Protection Act, and other national and/or state level laws. We suggest you review each of these to see which ones apply specifically to your business and locality. \n\n RevenueJump requires texts and emails to only be sent to recent customers who have consented to receive such messages. Replying to your assigned mobile number or adding a clause to your paperwork, invoices, receipts, signage, and/or website, may be a way of obtaining this consent. Reminding customers before sending surveys, is also a good idea; not only because it gives you further confirmation that it\'s okay to send such messages, but because it also warms them up to giving their honest feedback! \n\n A good rule of thumb— Customers should be expecting the messages you are sending to them.\n\nNote, SMS messaging is only available on the High Jump plan.',
        },
        {
          id: 14,
          question: 'Can customers opt-out of receiving text and email messages?',
          answer: 'Yes. For text messages, it is as simple as replying "STOP" to the text message. For emails, clicking the link at the bottom of the email will do the same. When viewing the survey page, there is an opt out link in the footer. If a customer requests to opt out in any other manner, honor their request by viewing the survey details screen and stopping all follow up messages.\n\nNote, SMS messaging is only available on the High Jump plan.',
        },
        {
          id: 15,
          question: 'How often does the monitoring service capture reviews?',
          answer: 'It varies from site to site, but RevenueJump is constantly monitoring the review sites. We try to capture reviews from the sites we monitor at least once per day. It just depends on the review site and what they allow.',
        },
        {
          id: 16,
          question: 'Is it good to respond to reviews?',
          answer: 'Absolutely! We found that a public response to positive reviews may help them avoid being caught by the directory\'s filtering mechanism. Also, saying "thank-you" to positive reviewers will also solidify your customer\'s loyalty to your brand. \n\n Don\'t forget to respond to negative reviews, too! Hopefully these are few and far between, but when they do come in, see them as an opportunity to show off your customer service skills. Responding with empathy and a willingness to make things right with that customer will go a long way with prospective customers reading your reviews. In reality, a negative review can be turned into something more valuable and beneficial than a 5-star review.',
        },
        {
          id: 17,
          question: 'A customer said it was not easy to leave a review or give a referral. Why?',
          answer: 'We rarely hear this, but is to be expected from time to time. There are literally dozens of factors in play when communicating with a customer, thus creating hundreds of potential obstacles to navigate through. These factors can include the messaging platform, review site, browser type, login status, installed apps, type of device, operating system, user history, etc. \n\n For example, referring a friend on Facebook from a desktop computer is much different than referring a friend via text message. Likewise, leaving a review on Google from an Android phone is much different than from an iPhone. \n\n Although we\'ve optimized RevenueJump to streamline as many of these paths as possible, unfortunately some of the obstacles are unavoidable. So, if your customer said it wasn\'t easy, chances are, they happened to run into one of these rare obstacles. \n\n Remember, sending surveys is a numbers game. Not every request you send out will turn into a referral or review. But, the more surveys you send, the more you\'re sure to get.',
        },
      ],
    };
  },
};
</script>
