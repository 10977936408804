<template>
  <vs-prompt
    title="Embed Widget"
    :active="active"
    class="rj-medium-modal rj-confirm-modal"
    accept-text="Copy"
    @accept="copy"
    @close="close"
  >
    <p>Copy and paste the code below where you want the widget to be displayed.</p>
    <!-- eslint-disable vue/no-v-html -->
    <autosize-textarea
      ref="code"
      class="border border-gray-300 rounded-lg px-2 py-3 mt-1 mr-2 bg-gray-200 break-all"
      style="font-family: monospace;"
      readonly
      :value="snippet"
    />
    <!--eslint-enable-->
  </vs-prompt>
</template>

<script>
import { AutosizeTextarea } from 'vue-autosizer';

export default {
  name: 'RjWidgetEmbedCode',

  components: { AutosizeTextarea },

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    widgetId: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
  },

  computed: {
    snippet() {
      // eslint-disable-next-line
      return `<script src="https://cdn.jsdelivr.net/npm/vue@2.6.14/dist/vue.min.js"><\/script><script src="${process.env.VUE_APP_RJ_SOCIAL_PROOF_WIDGET_URL}" async><\/script><rj-social-proof-widget id=\"${this.widgetId}\"><\/rj-social-proof-widget>`;
    },
  },

  methods: {
    async copy() {
      await this.clipboard(this.snippet, this.$refs.code);
      this.$vs.notify({
        title: 'Copied',
        text: 'Your widget code snippet was copied to your clipboard.',
        color: 'success',
      });
    },

    clipboard(text, element) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        return;
      }

      element.$el.select();
      document.execCommand('Copy');
      element.$el.setSelectionRange(0, 0);
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
