<template>
  <rj-base-widget-builder>
    <template #design="{ designConfig, update }">
      <rj-widget-design-picker v-bind="{ designConfig, update }" />
    </template>

    <template #settings="{ designConfig, update }">
      <rj-widget-base-settings v-bind="{ designConfig, update }" />

      <div class="flex flex-wrap flex-col sm:flex-row sm:space-x-10 mt-3">
        <div class="mt-4 w-full relative sm:w-64">
          <span class="absolute right-0 top-0 mt-8 text-sm z-40 mr-3 bg-white">ms</span>
          <vs-input
            :value="designConfig.delay"
            name="delay"
            label="Display Time"
            class="w-full"
            @input="(value) => update('designConfig', 'delay', parseInt(value))"
          />
        </div>

        <div class="mt-3">
          <label class="vs-input--label">Autoplay</label>
          <vs-switch
            :value="designConfig.autoplay"
            class="mt-2"
            @input="(value) => update('designConfig', 'autoplay', value)"
          >
            <span slot="on">ON</span>
            <span slot="off">OFF</span>
          </vs-switch>
        </div>
      </div>
    </template>
  </rj-base-widget-builder>
</template>

<script>
import RjBaseWidgetBuilder from '@/views/company-dashboard/integrations/widgets/builder/RjBaseWidgetBuilder.vue';
import RjWidgetBaseSettings from '@/views/company-dashboard/integrations/widgets/partials/RjWidgetBaseSettings.vue';
import RjWidgetDesignPicker from '@/views/company-dashboard/integrations/widgets/partials/RjWidgetDesignPicker.vue';

export default {
  name: 'RjCarouselWidgetBuilder',

  components: {
    RjBaseWidgetBuilder,
    RjWidgetBaseSettings,
    RjWidgetDesignPicker,
  },
};
</script>
