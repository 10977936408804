<template functional>
  <div class="widget__avatar-wrapper">
    <img
      v-if="props.avatar"
      :src="props.avatar"
      :class="['widget__avatar', props.size]"
    >
    <img
      v-else
      src="https://storage.googleapis.com/reviewjump-prod.appspot.com/social-proof/default-avatar.png"
      :class="['widget__avatar', 'widget__avatar_default', props.size]"
    >
  </div>
</template>

<script>
export default {
  name: 'RjWidgetAvatar',
  props: {
    avatar: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'large',
    },
  },
};
</script>
