<template>
  <section>
    <!-- Layout -->
    <div class="vx-row">
      <div class="vx-col w-full mt-3">
        <vs-card class="p-3">
          <div>
            <h2 class="text-2xl font-bold">
              #1: Name & Layout
            </h2>
            <p class="text-gray-500 text-base mt-1 leading-tight">
              Give your widget a name (internal reference only) and select the layout.
            </p>
          </div>
          <rj-widget-layouts-card v-bind="{ widget, update }" />
        </vs-card>
      </div>
    </div>

    <!-- Content -->
    <div class="vx-row">
      <div class="vx-col w-full mt-3">
        <vs-card class="p-3">
          <div class="space-y-1">
            <h2 class="text-2xl font-bold">
              #2: Content
            </h2>
            <p class="text-gray-500 text-base leading-tight">
              Specify the testimonials and/or reviews you would like to display.
            </p>
          </div>

          <!-- Sorting -->
          <rj-widget-sort-card v-bind="{ sourceConfig, update }" />

          <template v-if="sourceConfig.sort !== 'specific'">
            <!-- Sources -->
            <rj-widget-sources-card v-bind="{ sourceConfig, locations, update }" />

            <!-- Filters -->
            <rj-widget-filters-card v-bind="{ designConfig, sourceConfig, update }" />
          </template>
        </vs-card>
      </div>
    </div>

    <!-- Design -->
    <div class="vx-row">
      <div class="vx-col w-full mt-2">
        <vs-card class="p-3">
          <div>
            <h2 class="text-2xl font-bold">
              #3: Design
            </h2>
            <p class="text-gray-500 text-base mt-1 leading-tight">
              Based on the layout chosen above, here are the available designs for your widget.
            </p>
          </div>

          <slot
            name="design"
            v-bind="{ designConfig, update }"
          />
        </vs-card>
      </div>
    </div>

    <!-- Settings -->
    <div class="vx-row">
      <div class="vx-col w-full mt-2">
        <vs-card class="p-3">
          <div>
            <h2 class="text-2xl font-bold">
              #4: Settings
            </h2>
            <p class="text-gray-500 text-base mt-1 leading-tight">
              Just finalize a few last details and your widget is good to go!
            </p>
          </div>

          <slot
            name="settings"
            v-bind="{ designConfig, update }"
          />
        </vs-card>
      </div>
    </div>
  </section>
</template>

<script>
import RjWidgetSortCard from '@/views/company-dashboard/integrations/widgets/cards/RjWidgetSortCard.vue';
import RjWidgetSourcesCard from '@/views/company-dashboard/integrations/widgets/cards/RjWidgetSourcesCard.vue';
import RjWidgetFiltersCard from '@/views/company-dashboard/integrations/widgets/cards/RjWidgetFiltersCard.vue';
import RjWidgetLayoutsCard from '@/views/company-dashboard/integrations/widgets/cards/RjWidgetLayoutsCard.vue';

export default {
  name: 'RjBaseWidgetBuilder',

  components: {
    RjWidgetSortCard,
    RjWidgetFiltersCard,
    RjWidgetLayoutsCard,
    RjWidgetSourcesCard,
  },

  computed: {
    widget() {
      return this.$store.getters['widgets/widget'];
    },

    designConfig() {
      return this.$store.getters['widgets/designConfig'];
    },

    sourceConfig() {
      return this.$store.getters['widgets/sourceConfig'];
    },

    locations() {
      return this.$store.getters['locations/locations'].map((l) => ({
        id: l.id,
        label: l.name,
      }));
    },
  },

  methods: {
    update(fn, key, value) {
      const functions = {
        designConfig: this.updateDesignConfig,
        widget: this.updateWidget,
        sourceConfig: this.updateSourceConfig,
      };

      if (!Object.keys(functions).includes(fn)) {
        throw new Error(`${fn} is not a valid function`);
      }

      return functions[fn](key, value);
    },

    updateWidget(key, value) {
      this.$store.commit('widgets/UPDATE_WIDGET_PROP', { key, value });
    },

    updateSourceConfig(key, value) {
      this.$store.commit('widgets/UPDATE_SOURCE_CONFIG', { key, value });
    },

    updateDesignConfig(key, value) {
      this.$store.commit('widgets/UPDATE_DESIGN_CONFIG', { key, value });
    },
  },
};
</script>
