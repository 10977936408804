<template>
  <div>
    <vx-card
      class="overflow-hidden cursor-pointer transform hover:-translate-y-1"
      @click="onClick()"
    >
      <template #no-body>
        <div class="p-3 flex flex-col items-center justify-center text-center">
          <div class="flex items-center justify-between mb-4">
            <img
              :src="application.logo"
              :alt="application.name"
            >
          </div>

          <div class="truncate">
            <h2 class="mb-1 font-bold text-lg">
              {{ application.name }}
            </h2>
            <span class="text-gray-500">
              {{ application.type }}
            </span>
          </div>
        </div>
      </template>
    </vx-card>

    <rj-connect
      :active.sync="connecting"
      @connected="handleConnection"
    />

    <rj-revenue-jump-token
      :active="managing"
      :token="token"
      @token-deleted="connect"
    />
  </div>
</template>

<script>
import { bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import RjConnect from '@/views/company-dashboard/integrations/apps/revenuejump/RjConnect.vue';
import RjRevenueJumpToken from '@/views/company-dashboard/integrations/apps/revenuejump/RjRevenueJumpToken.vue';

export default {
  name: 'RjRevenueJumpApp',

  components: {
    RjConnect,
    RjRevenueJumpToken,
  },

  mixins: [Authorizable],

  props: {
    application: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      connecting: false,
      token: null,
    };
  },

  computed: {
    managing() {
      return this.application.managing;
    },
  },

  methods: {
    onClick() {
      if (this.isLowJump) {
        return bus.$emit('upgrade-company');
      }

      return (this.application.connected) ? this.manage() : this.connect();
    },

    connect() {
      this.connecting = true;
    },

    handleConnection() {
      this.manage();
    },

    manage() {
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'revenuejump', isManaging: true });
    },
  },
};
</script>
