import RjWideDesignRender from '@/views/company-dashboard/integrations/widgets/previewer/designs/RjWideDesignRender.vue';
import RjSmallDesignRender from '@/views/company-dashboard/integrations/widgets/previewer/designs/RjSmallDesignRender.vue';
import RjVerticalDesignRender from '@/views/company-dashboard/integrations/widgets/previewer/designs/RjVerticalDesignRender.vue';
import RjStandardDesignRender from '@/views/company-dashboard/integrations/widgets/previewer/designs/RjStandardDesignRender.vue';
import RjQuoteTopDesignRender from '@/views/company-dashboard/integrations/widgets/previewer/designs/RjQuoteTopDesignRender.vue';
import RjAvatarTopDesignRender from '@/views/company-dashboard/integrations/widgets/previewer/designs/RjAvatarTopDesignRender.vue';

export default {
  RjWideDesignRender,
  RjSmallDesignRender,
  RjVerticalDesignRender,
  RjStandardDesignRender,
  RjQuoteTopDesignRender,
  RjAvatarTopDesignRender,
};
