<template>
  <div>
    <vx-card
      class="overflow-hidden cursor-pointer transform hover:-translate-y-1"
      @click="onClick()"
    >
      <template #no-body>
        <div class="p-3 flex flex-col items-center justify-center text-center">
          <div class="flex items-center justify-between mb-4">
            <img
              :src="application.logo"
              :alt="application.name"
            >
          </div>

          <div class="truncate">
            <h2 class="mb-1 font-bold text-lg">
              {{ application.name }}
            </h2>
            <span class="text-gray-500">
              {{ application.type }}
            </span>
          </div>
        </div>
      </template>
    </vx-card>

    <rj-connect
      :active.sync="connecting"
      @connected="handleTagConnection"
    />

    <rj-tag-settings
      :active="tagging"
      :application="application"
      :locations="locations"
      :funnels="funnels"
      :employees="employees"
      :tag="tag"
      @tagged="handleTags"
    />

    <rj-connected-tags
      :active="managing"
      :application="application"
      :tags="tags"
      @add-tag="handleTagConnection"
      @edit-tag="handleTagConnection"
      @tag-removed="fetchTags"
    />
  </div>
</template>

<script>
import RjConnect from '@/views/company-dashboard/integrations/apps/infusionsoft/RjConnect.vue';
import RjTagSettings from '@/views/company-dashboard/integrations/apps/infusionsoft/RjTagSettings.vue';
import RjConnectedTags from '@/views/company-dashboard/integrations/apps/infusionsoft/RjConnectedTags.vue';

export default {
  name: 'RjInfusionsoftApp',

  components: {
    RjConnect,
    RjTagSettings,
    RjConnectedTags,
  },

  props: {
    application: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tags: [],
      tag: null,
      connecting: false,
    };
  },

  computed: {
    locations() {
      return this.$store.getters['locations/locations'];
    },

    funnels() {
      return this.$store.getters['funnels/funnels'];
    },

    employees() {
      return this.$store.getters['users/users'].map((user) => ({
        fullName: `${user.firstName} ${user.lastName}`, ...user,
      }));
    },

    tagging() {
      return this.application.tagging;
    },

    managing() {
      return this.application.managing;
    },
  },

  mounted() {
    this.fetchTags();
  },

  methods: {
    onClick() {
      return (this.application.connected) ? this.manage() : this.connect();
    },

    connect() {
      this.connecting = true;
    },

    handleTagConnection(tag = null) {
      this.tag = tag;
      this.$store.commit('companies/SET_INFUSIONSOFT_TAGGING', true);
    },

    async handleTags() {
      this.manage();
    },

    async fetchTags() {
      this.tags = [];

      if (!this.application.cloudBridgeCredentialsId) {
        return;
      }

      const { tags = {} } = await this.$store.dispatch('companies/getCloudBridgeCredentialsById', this.application.cloudBridgeCredentialsId);

      for (const tagId of Object.keys(tags)) {
        const {
          locationId, userId, surveyTemplateId, name,
        } = tags[tagId];
        const location = this.locations.find((l) => l.id === locationId);
        const employee = this.employees.find((e) => e.id === userId);
        let employeeName = 'Missing Employee';
        if (employee) {
          employeeName = `${employee.firstName || ''} ${employee.lastName || ''}`;
        }
        const funnel = this.funnels.find((f) => f.id === surveyTemplateId);

        this.tags.push({
          id: parseInt(tagId, 10),
          locationId,
          userId: employee?.id,
          surveyTemplateId: funnel?.id,
          name,
          locationName: location?.name || 'Missing Location',
          employeeName,
          funnelName: funnel?.name || 'Missing Funnel',
        });
      }

      if (!this.tags.length) {
        this.$store.commit('companies/SET_INFUSIONSOFT_CONNECTED', false);
      }
    },

    async manage() {
      await this.fetchTags();
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'infusionsoft', isManaging: true });
    },
  },
};
</script>
