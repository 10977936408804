<template>
  <vs-prompt
    title="Filter"
    :active="active"
    :buttons-hidden="true"
    class="rj-confirm-modal"
    @close="close"
  >
    <div class="vx-row">
      <!-- Start Date -->
      <div class="vx-col w-full">
        <label class="vs-input--label">Timeframe</label>
        <div class="flex justify-between">
          <datepicker
            v-model="filters.startDate"
            :disabled-dates="disabledDates.startDate"
            format="MMM dd, yyyy"
            input-class="p-2"
            placeholder="Start Date"
          />
          <datepicker
            v-model="filters.endDate"
            :disabled-dates="disabledDates.endDate"
            format="MMM dd, yyyy"
            input-class="p-2"
            placeholder="End Date"
          />
        </div>
      </div>
    </div>

    <!-- Rating -->
    <div class="mt-3">
      <label class="vs-input--label">Rating</label>
      <rj-select
        v-model="filters.sentiment"
        :options="sentiments"
        :reduce="(sentiment) => sentiment.value"
        :clearable="false"
        placeholder="Select a Rating..."
      />
    </div>

    <!-- Location -->
    <div
      v-if="isHighJump"
      class="mt-3"
    >
      <label class="vs-input--label">Location</label>
      <rj-select
        v-model="filters.location"
        :options="locations"
        :reduce="(location) => location.id"
        :clearable="false"
        placeholder="Select a Location..."
      />
    </div>

    <!-- Employee -->
    <div
      v-if="isHighJump"
      class="mt-3"
    >
      <label class="vs-input--label">Employee</label>
      <rj-select
        v-model="filters.employee"
        :options="employees"
        :reduce="(employee) => employee.id"
        :clearable="false"
        placeholder="Select a Employee..."
      />
    </div>

    <!-- Funnel -->
    <div class="mt-3">
      <label class="vs-input--label">Funnel</label>
      <rj-select
        v-model="filters.funnel"
        :options="funnels"
        :reduce="(funnel) => funnel.id"
        :clearable="false"
        placeholder="Select a Funnel..."
      />
    </div>

    <!-- Widget -->
    <div class="mt-3">
      <label class="vs-input--label">Widget Visibility</label>
      <rj-select
        v-model="filters.appearOnWidget"
        :options="widgets"
        :reduce="(widget) => widget.value"
        :clearable="false"
        placeholder="Select a Widget..."
      />
    </div>

    <template v-if="hasFiltersApplied || !pristine">
      <div class="pt-4 flex justify-end border-t border-gray-300 mt-5">
        <vs-button
          v-if="hasFiltersApplied"
          type="border"
          @click="reset"
        >
          Reset
        </vs-button>

        <vs-button
          v-if="!pristine"
          class="ml-3"
          @click="apply"
        >
          Filter
        </vs-button>
      </div>
    </template>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import Datepicker from 'vuejs-datepicker';
import { isPristine } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import filters from '@/views/company-dashboard/results/filters/testimonials';

export default {
  name: 'RjFilterTestimonialsDialog',

  components: { Datepicker },

  mixins: [Authorizable],

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    appliedFilters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      old: {},
      pristine: true,
      filters: { ...this.appliedFilters },
      sentiments: [
        { value: null, label: 'All Ratings' },
        { value: 0, label: 'Negative' },
        { value: 1, label: 'Positive' },
      ],
      widgets: [
        { value: null, label: 'Both' },
        { value: false, label: 'Hidden' },
        { value: true, label: 'Visible' },
      ],
    };
  },

  computed: {
    employees() {
      const employees = this.$store.getters['users/users'].map((user) => ({
        id: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));
      return [
        { id: null, label: 'All Employees' },
        ...employees,
      ];
    },

    funnels() {
      const funnels = this.$store.getters['funnels/funnels'].map((f) => ({
        id: f.id,
        label: f.name,
      }));
      return [
        { id: null, label: 'All Funnels' },
        ...funnels,
      ];
    },

    locations() {
      const locations = this.$store.getters['locations/locations'].map((location) => ({
        id: location.id,
        label: location.name,
      }));
      return [
        { id: null, label: 'All Locations' },
        ...locations,
      ];
    },

    disabledDates() {
      return {
        startDate: { from: new Date() },
        endDate: { from: new Date(), to: this.filters.startDate },
      };
    },

    hasFiltersApplied() {
      return !isPristine(this.appliedFilters, filters());
    },
  },

  watch: {
    filters: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  mounted() {
    this.old = { ...this.filters };
  },

  methods: {
    apply() {
      this.$emit('apply-filters', this.filters);
      this.close();
    },

    reset() {
      this.filters = filters();
      this.old = { ...this.filters };
      this.$emit('reset-filters', filters());
      // this.close();
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      this.pristine = isPristine(current, this.old);
    }),

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
