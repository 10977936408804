<template functional>
  <div
    class="widget-layout widget-layout__single"
    :class="[data.class, data.staticClass]"
  >
    <slot
      name="item"
      :item="props.widget.items[0]"
    />
  </div>
</template>

<script>
export default {
  name: 'RjSingleLayoutRender',

  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
};
</script>
