<template>
  <vs-prompt
    title="Infusionsoft"
    :active="active"
    accept-text="Connect"
    class="rj-medium-modal rj-confirm-modal"
    @accept="connect"
    @close="close"
  >
    <div class="space-y-2">
      <p>Connect your Infusionsoft account to send surveys to contacts after applying a specified tag.</p>
      <p>Click the Connect button below to be redirected to an Infusionsoft authentication screen.</p>
    </div>
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjConnect',

  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    currentRole() {
      return this.$store.getters['auth/currentRole'];
    },
  },

  methods: {
    connect() {
      // make sure we return to Company Dashboard if we are an admin or partner
      // user viewing the Company Dashboard
      if (/^Admin/.test(this.currentRole) || /^Partner/.test(this.currentRole)) {
        const companyId = this.$store.getters['companies/company'].id;
        localStorage.setItem('actAsCompanyId', companyId);
      }
      // redirect to Infusionsoft oauth
      window.location.href = `https://accounts.infusionsoft.com/app/oauth/authorize?client_id=${process.env.VUE_APP_INFUSIONSOFT_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_RJ_APP_URL}/company/integrations/applications&response_type=code&scope=full`;
      this.close();
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
