<template>
  <div class="relative bg-primary overflow-hidden ">
    <span class="relative flex items-center justify-center h-full overflow-hidden">
      <img
        :src="avatar || defaultAvatar"
        alt="Customer Avatar"
        :class="[avatar ? 'h-full w-full' : 'w-1/2 h-auto']"
        class="object-cover rounded-full"
      >
    </span>

    <div class="absolute flex items-center justify-center inset-0 w-full h-full bg-gray-800 bg-opacity-75 cursor-pointer">
      <input
        ref="uploader"
        type="file"
        class="hidden"
        @change="preview"
      >
      <feather-icon
        icon="CameraIcon"
        svg-classes="w-5 h-5 text-white"
        @click.prevent="browsePhotos"
      />
      <feather-icon
        v-if="avatar"
        icon="XIcon"
        svg-classes="w-5 h-5 text-white ml-3"
        @click.prevent="removeAvatar"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RjAvatarUploader',

  props: {
    avatar: {
      type: [Object, String, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      defaultAvatar: require('@/assets/images/pages/default-avatar.png'),
    };
  },

  methods: {
    browsePhotos() {
      this.$refs.uploader.click();
    },

    async preview() {
      await this.$nextTick();

      const file = this.$refs.uploader.files[0];
      const reader = new FileReader();

      reader.addEventListener('load', (event) => {
        this.$emit('loaded', { file, event });
      });

      reader.readAsDataURL(file);
    },

    removeAvatar() {
      this.$emit('remove', null);
    },
  },
};
</script>
