<template>
  <vx-card
    title="Satisfaction Score"
    class="h-full flex flex-col justify-start vx-card__full-height"
  >
    <template slot="no-body">
      <div data-chart>
        <vue-apex-charts
          type="radialBar"
          height="320"
          :options="chart.options"
          :series="chart.series"
        />
      </div>

      <!-- Support Tracker Meta Data -->
      <div class="flex flex-row justify-between px-8 pb-4">
        <p
          v-for="(item, index) in metadata"
          :key="index"
          class="text-center"
        >
          <span class="block">{{ item.label }}</span>
          <span class="text-2xl font-semibold">{{ item.value }}</span>
        </p>
      </div>
    </template>
  </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
// import { rjky } from '@/ky';

export default {
  name: 'RjSatisfactionScoreChart',

  components: { VueApexCharts },

  props: {
    completionRate: {
      type: Number,
      required: true,
    },
    satisfactionScore: {
      type: Number,
      required: true,
    },
    surveysSent: {
      type: Number,
      required: true,
    },
    visitRate: {
      type: Number,
      required: true,
    },
  },

  computed: {
    metadata() {
      return [
        {
          label: 'Surveys Sent',
          value: this.surveysSent,
        },
        {
          label: 'Visit Rate',
          value: `${Math.round(this.visitRate)}%`,
        },
        {
          label: 'Completion Rate',
          value: `${Math.round(this.completionRate)}%`,
        },
      ];
    },
    chart() {
      return {
        series: [this.satisfactionScore.toPrecision(3)],
        options: {
          colors: ['#05DA89'],
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              hollow: {
                margin: 5,
                size: '78%',
                position: 'front',
                dropShadow: {
                  enabled: true,
                  top: 1,
                  left: 0,
                  blur: 4,
                  opacity: 0.50,
                },
              },
              track: {
                startAngle: -135,
                endAngle: 135,
                strokeWidth: '40%',
                background: '#D1D5DB',
                dropShadow: {
                  enabled: false,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: '600',
                  fontSize: '68px',
                  offsetY: 26,
                  color: '#A0AEC0',
                  show: true,
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              gradientToColors: ['#09BEA8'],
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
            curve: 'smooth',
          },
        },
      };
    },
  },
};
</script>
