<template>
  <vs-prompt
    title="Warning: Duplicate!"
    :active="active"
    :buttons-hidden="true"
    class="rj-confirm-modal"
    :style="{ zIndex: 52008 }"
    @close="close"
  >
    <p>
      The following {{ duplicates.length > 1 ? 'contacts have' : 'contact has' }} already been sent a survey.
      Are you sure you want to send them another one?
    </p>

    <div
      class="my-5 modal-content"
    >
      <div
        v-for="duplicate in duplicates"
        :key="duplicate.index"
        class="flex items-center justify-between py-1 px-2 rounded"
      >
        <p class="text-sm">
          {{ duplicate.firstName + (duplicate.lastName ? ` ${duplicate.lastName}` : '') }}
        </p>
        <div>
          <vs-switch
            v-if="duplicates.length > 1"
            v-model="shouldSend[duplicate.index]"
            class="inverted-switch mt-2"
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-end border-t border-gray-300 mt-4 pt-3">
      <vs-button
        class="mr-3"
        type="border"
        @click.prevent="approveAll"
      >
        {{ duplicates.length > 1 ? 'Yes to All' : 'Yes' }}
      </vs-button>

      <vs-button @click.prevent="rejectAll">
        {{ duplicates.length > 1 ? 'No to All' : 'No' }}
      </vs-button>
    </div>
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjCsvDuplicatesDialog',

  components: {},

  props: {
    active: {
      type: Boolean,
      required: true,
    },
    duplicates: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      shouldSend: [],
    };
  },

  created() {
    this.shouldSend = this.duplicates.map(() => false);
    this.duplicates.forEach((duplicate) => this.reject(duplicate.index));
  },

  methods: {
    approve(index) {
      this.shouldSend[index] = true;
    },

    reject(index) {
      this.shouldSend[index] = false;
    },

    approveAll() {
      this.duplicates.forEach((duplicate) => this.approve(duplicate.index));
      this.close();
    },

    rejectAll() {
      this.duplicates.forEach((duplicate) => this.reject(duplicate.index));
      this.close();
    },

    close() {
      this.$emit('approve-duplicates', this.duplicates.filter((duplicate, index) => this.shouldSend[index]));
      this.$emit('update:active', false);
    },
  },
};
</script>
<style scoped>
.modal-content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
