<template>
  <section>
    <rj-email-signature :company="company" />
    <rj-email-domain-auth :company="company" />
  </section>
</template>

<script>
import RjEmailSignature from '@/views/common/email-settings/RjEmailSignature.vue';
import RjEmailDomainAuth from '@/views/common/email-settings/RjEmailDomainAuth.vue';

export default {
  name: 'RjEmailSettings',

  components: { RjEmailSignature, RjEmailDomainAuth },

  computed: {
    company() {
      return this.$store.getters['companies/company'];
    },
  },
};
</script>
