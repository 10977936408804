<template>
  <div class="mt-4">
    <label class="vs-input--label">Source</label>
    <div class="flex items-center flex-wrap space-x-3 sm:space-x-6 mt-1">
      <vs-checkbox
        :value="sourceConfig.testimonials"
        :vs-value="true"
        class="text-base p-0 m-0"
        @input="(value) => update('sourceConfig', 'testimonials', value)"
      >
        Testimonials
      </vs-checkbox>

      <vs-checkbox
        :value="sourceConfig.reviews"
        :vs-value="true"
        class="text-base p-0 m-0"
        @input="(value) => update('sourceConfig', 'reviews', value)"
      >
        Reviews
      </vs-checkbox>

      <template v-if="locations.length >= 2">
        <rj-select
          :value="locationId"
          :options="allLocations"
          :reduce="(location) => location.id"
          :clearable="false"
          placeholder="Select a location..."
          class="text-base w-64"
          @input="(value) => updateLocations(value)"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RjWidgetSourcesCard',

  props: {
    sourceConfig: {
      type: Object,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },

    update: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      locationId: null,
    };
  },

  computed: {
    allLocations() {
      return [
        { id: -1, label: 'All Locations' },
        ...this.locations,
      ];
    },
  },

  mounted() {
    this.locationId = this.$store.getters['widgets/sourceConfig'].locationIds?.length ? this.$store.getters['widgets/sourceConfig'].locationIds[0] : -1;
  },

  methods: {
    updateLocations(value) {
      if (value > 0) {
        this.update('sourceConfig', 'locationIds', [value]);
      } else {
        this.update('sourceConfig', 'locationIds', null);
      }
      this.locationId = value;
    },
  },
};
</script>
