<template>
  <vs-prompt
    title="Infusionsoft Tag Settings"
    :active="active"
    accept-text="Save"
    class="rj-medium-modal rj-confirm-modal"
    @accept="submit"
    @close="close"
  >
    <div class="space-y-3">
      <p>You’re connected to Infusionsoft app {{ application.appId }}!</p>
      <p>Select the Infusionsoft tag that will trigger the survey:</p>
    </div>

    <div class="vx-row">
      <!-- Tag -->
      <div class="vx-col w-full mt-2">
        <label class="vs-input--label">Infusionsoft Tag</label>
        <rj-select
          v-model="form.tag"
          v-validate="'required'"
          name="Tag"
          :options="tags"
          :reduce="(tag) => tag"
          :clearable="false"
          placeholder="Select a tag..."
        />
        <span class="text-sm text-red-500">
          {{ errors.first('Tag') }}
        </span>
      </div>
    </div>

    <div
      v-show="form.tag"
      class="mt-3"
    >
      <p>Specify the survey details in RevenueJump:</p>

      <div class="vx-row">
        <!-- Location -->
        <div class="vx-col w-full mt-2">
          <label class="vs-input--label">Location*</label>
          <rj-select
            v-model="form.location"
            v-validate="'required'"
            name="Location"
            label="name"
            :options="locations"
            :reduce="(location) => location.id"
            :clearable="false"
            placeholder="Select a location..."
          />
          <span class="text-sm text-red-500">
            {{ errors.first('Location') }}
          </span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full sm:w-1/2 mt-3">
          <!-- Funnel -->
          <label class="vs-input--label">Funnel*</label>
          <rj-select
            v-model="form.funnel"
            v-validate="'required'"
            name="Funnel"
            label="name"
            :options="funnels"
            :reduce="(funnel) => funnel.id"
            :clearable="false"
            placeholder="Select a funnel..."
          />
          <span class="text-sm text-red-500">
            {{ errors.first('Funnel') }}
          </span>
        </div>

        <div class="vx-col w-full sm:w-1/2 mt-3">
          <label class="vs-input--label">Employee*</label>
          <rj-select
            v-model="form.employee"
            v-validate="'required'"
            name="Employee"
            label="fullName"
            :options="employees"
            :reduce="(user) => user.id"
            :clearable="false"
            placeholder="Select an employee..."
          />
          <span class="text-sm text-red-500">
            {{ errors.first('Employee') }}
          </span>
        </div>
      </div>
    </div>
  </vs-prompt>
</template>

<script>
const { defaultky } = require('@/ky');

export default {
  name: 'RjTagSettings',

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    application: {
      type: Object,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },

    funnels: {
      type: Array,
      required: true,
    },

    employees: {
      type: Array,
      required: true,
    },

    tag: {
      type: [Object, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      form: {
        tag: null,
        location: null,
        funnel: null,
        employee: null,
      },
      apiUrl: process.env.VUE_APP_INFUSIONSOFT_API_URL,
      tags: [],
      oldTag: null,
    };
  },

  computed: {
    action() {
      return this.tag === null ? 'create' : 'edit';
    },
  },

  watch: {
    active: {
      handler: 'buildFormObject',
    },
  },

  methods: {
    async submit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) {
        return;
      }

      this.updateTags();
    },

    async updateTags() {
      const { id, tags = {} } = await this.$store.dispatch('companies/getCloudBridgeCredentialsById', this.application.cloudBridgeCredentialsId);

      if (this.oldTag) {
        delete tags[this.oldTag.tag.id];
      }

      tags[this.form.tag.id] = {
        name: this.form.tag.label,
        locationId: this.form.location,
        userId: this.form.employee,
        surveyTemplateId: this.form.funnel,
      };

      const payload = {
        tags,
      };

      await this.$store.dispatch('companies/updateCloudBridgeCredentials', { id, payload });
      this.$store.commit('companies/SET_INFUSIONSOFT_CONNECTED', true);
      this.$emit('tagged');
      this.close();
    },

    close() {
      this.reset();
      this.$store.commit('companies/SET_INFUSIONSOFT_TAGGING', false);
      this.$emit('update:active', false);
    },

    async fetchTags() {
      // fetch client's tags from infusionsoft API
      this.tags = [];
      let url = `${this.apiUrl}/tags?access_token=${this.application.accessToken}`;
      try {
        let { next, tags } = await defaultky.get(url).json();
        for (const tag of tags) {
          this.tags.push({
            id: tag.id,
            label: tag.name,
          });
        }
        /* eslint-disable no-await-in-loop */
        while (next && tags.length) {
          url = `${next}&access_token=${this.application.accessToken}`;
          ({ next, tags } = await defaultky.get(url).json());
          for (const tag of tags) {
            this.tags.push({
              id: tag.id,
              label: tag.name,
            });
          }
        }
        /* eslint-enable no-await-in-loop */
      } catch (error) {
        console.log(error);
      }
    },

    buildFormObject() {
      this.fetchTags();
      if (this.action === 'create') {
        this.reset();
      } else {
        this.hydrate();
      }
    },

    hydrate() {
      this.oldTag = this.tag;
      this.form = {
        tag: this.tag.tag,
        funnel: this.tag.funnel,
        location: this.tag.location,
        employee: this.tag.employee,
      };
    },

    async reset() {
      this.$validator.pause();
      await this.$nextTick();

      for (const key in this.form) {
        if ({}.hasOwnProperty.call(this.form, key)) {
          this.form[key] = null;
        }
      }

      this.$validator.resume();
    },
  },
};
</script>
