<template>
  <vs-prompt
    title="Facebook"
    :active="active"
    accept-text="Add Connection"
    class="rj-medium-modal rj-confirm-modal"
    :style="{ zIndex: 52004 }"
    @accept="addConnection"
    @close="close"
  >
    <div class="space-y-2">
      <p>You’re connected to Facebook!</p>
      <p>Below are the Facebook page(s) currently connected to RevenueJump.</p>
    </div>

    <vs-table
      id="table__location-mapping"
      stripe
      hover-flat
      :data="connectedPages"
      class="my-3 pr-4 border-0 modal-content"
      :no-data-text="'No connected pages'"
    >
      <template #thead>
        <vs-th class="p-2">
          <span class="uppercase">
            Facebook Page
          </span>
        </vs-th>
        <vs-th class="p-2">
          <span class="uppercase">
            RevenueJump Locations
          </span>
        </vs-th>
        <vs-th class="p-2">
          <span class="uppercase">
            Actions
          </span>
        </vs-th>
      </template>

      <template #default="{ data }">
        <vs-tr
          v-for="(page, index) in data"
          :key="index"
        >
          <vs-td>
            {{ page.label }}
          </vs-td>
          <vs-td>
            <span>{{ page.locations.map((l) => l.name).join(', ') }}</span>
          </vs-td>
          <vs-td>
            <div class="flex">
              <vs-button
                type="flat"
                size="small"
                icon-pack="feather"
                icon="icon-settings"
                title="Settings"
                @click.stop="edit(page)"
              />
              <vs-button
                type="flat"
                size="small"
                icon-pack="feather"
                icon="icon-slash"
                title="Delete"
                @click.stop="deleteConfirmation(page)"
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <v-facebook-login
      class="hidden"
      :app-id="appId"
      :login-options="options"
      @sdk-init="handleSdkInit"
    />
  </vs-prompt>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component';

export default {
  name: 'RjConnectedLocations',

  components: { VFacebookLogin },

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },

    facebookPages: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      facebook: null,
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      options: {
        return_scopes: true,
        scope: 'pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_metadata,pages_manage_engagement,public_profile,email',
        auth_type: 'reauthorize',
      },
    };
  },

  computed: {
    connectedPages() {
      const connectedLocations = [];
      for (const page of this.facebookPages) {
        const locations = this.locations.filter((l) => l.facebookPageId === page.id);
        if (locations.length) {
          connectedLocations.push({
            label: page.label,
            locations: locations.filter((l) => l.facebookPageId === page.id),
          });
        }
      }
      return connectedLocations;
    },
  },

  methods: {
    handleSdkInit({ FB }) {
      this.facebook = FB;
    },

    addConnection() {
      this.facebook.login((response) => {
        if (response.status === 'connected') {
          this.$store.commit('companies/SET_FACEBOOK_AUTH_RESPONSE', response.authResponse);
          this.$emit('connected');
          this.close();
        }
      }, this.options);
    },

    edit() {
      this.$emit('show-match-dialog');
      // this.close()
    },

    deleteConfirmation(page) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are You Sure?',
        text: 'This action will erase all current settings for connecting to your Facebook application.',
        acceptText: 'Yes',
        accept: () => this.delete(page),
      });
    },

    async delete(page) {
      const updates = [];
      for (const location of page.locations) {
        const data = {
          locationId: location.id,
          payload: {
            facebookPageId: null,
            facebookAccessToken: null,
          },
        };
        updates.push(this.$store.dispatch('locations/updateLocation', data));
      }
      await Promise.all(updates);
      if (!this.connectedPages.length) {
        this.$store.commit('companies/SET_FACEBOOK_CONNECTED', false);
        this.$store.commit('companies/SET_APP_MANAGING', { code: 'facebook', isManaging: false });
      }
    },

    close() {
      if (this.connectedPages.length) {
        this.$store.commit('companies/SET_FACEBOOK_CONNECTED', true);
      }
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'facebook', isManaging: false });
      this.$emit('update:active', false);
    },
  },
};
</script>
<style scoped>
.modal-content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
