<template>
  <div class="flex flex-wrap flex-col sm:flex-row sm:space-x-10 mt-2">
    <!-- <div class="mt-4 w-full relative sm:w-64">
      <span class="absolute right-0 top-0 mt-8 text-sm z-40 mr-3 bg-white">pixels</span>
      <vs-input
        :value="settings.width"
        name="width"
        label="Widget Width"
        class="w-full"
        @input="(value) => update('width', parseInt(value))"
      />
    </div> -->

    <div class="mt-4">
      <label class="vs-input--label">RevenueJump Branding</label>
      <vs-switch
        :value="designConfig.branding"
        class="mt-2"
        @input="(value) => update('branding', value)"
      >
        <span slot="on">SHOW</span>
        <span slot="off">HIDE</span>
      </vs-switch>
    </div>

    <div class="mt-4">
      <label class="vs-input--label">Display On Mobile</label>
      <vs-switch
        :value="designConfig.mobile"
        class="mt-2"
        @input="(value) => update('mobile', value)"
      >
        <span slot="on">SHOW</span>
        <span slot="off">HIDE</span>
      </vs-switch>
    </div>

    <!-- Show/Hide Company Location -->
    <div class="mt-4">
      <label class="vs-input--label">Display Location</label>
      <vs-switch
        :value="designConfig.displayLocation"
        class="mt-2"
        @input="(value) => update('displayLocation', value)"
      >
        <span slot="on">SHOW</span>
        <span slot="off">HIDE</span>
      </vs-switch>
    </div>

    <div class="mt-4">
      <label class="vs-input--label">Bounding Box</label>
      <vs-switch
        :value="designConfig.boundingBox"
        class="mt-2"
        @input="(value) => update('boundingBox', value)"
      >
        <span slot="on">SHOW</span>
        <span slot="off">HIDE</span>
      </vs-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RjWidgetBaseSettings',

  props: {
    designConfig: {
      type: Object,
      required: true,
    },
  },

  methods: {
    update(key, value) {
      this.$store.commit('widgets/UPDATE_DESIGN_CONFIG', { key, value });
    },
  },
};
</script>
