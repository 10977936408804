<template>
  <div>
    <vx-card
      class="overflow-hidden cursor-pointer transform hover:-translate-y-1"
      @click="onClick(application)"
    >
      <template #no-body>
        <div class="p-3 flex flex-col items-center justify-center text-center">
          <div class="flex items-center justify-between mb-4">
            <img
              :src="application.logo"
              :alt="application.name"
            >
          </div>

          <div class="truncate">
            <h2 class="mb-1 font-bold text-lg">
              {{ application.name }}
            </h2>
            <span class="text-gray-500">
              {{ application.type }}
            </span>
          </div>
        </div>
      </template>
    </vx-card>
    <p
      v-if="hasErrors"
      class="cursor-pointer text-primary text-sm pt-2 text-left flex items-start"
      @click="onClick(application)"
    >
      <feather-icon
        icon="AlertCircleIcon"
        svg-classes="w-4 h-4 mt-1 mr-2"
      />There may be an issue with this integration. Click for details.
    </p>

    <rj-connect
      :active.sync="connecting"
      @connected="handleConnection"
    />

    <rj-connected-locations
      :active="managing"
      :connected-locations="connectedLocations"
    />
  </div>
</template>

<script>
import RjConnect from '@/views/company-dashboard/integrations/apps/google/RjConnect.vue';
import RjConnectedLocations from '@/views/company-dashboard/integrations/apps/google/RjConnectedLocations.vue';

export default {
  name: 'RjGoogleBusinessApp',

  components: {
    RjConnect,
    RjConnectedLocations,
  },

  props: {
    application: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      connecting: false,
      googlePlaces: [],
    };
  },

  computed: {
    locations() {
      return this.$store.getters['locations/locations'];
    },

    locationPlaceIds() {
      return this.locations.map((l) => l.googlePlaceId);
    },

    connectedLocations() {
      return this.locations.filter((l) => this.googlePlaces.find((p) => p.placeId === l.googlePlaceId))
        .map((l) => ({
          ...l,
          errors: this.googlePlaces.find((p) => p.placeId === l.googlePlaceId).errors,
        }));
    },

    hasErrors() {
      return this.connectedLocations.some((l) => l.errors.length);
    },

    managing() {
      return this.application.managing;
    },
  },

  mounted() {
    if (this.application.connected) {
      this.getGooglePlaces();
    }
  },

  methods: {
    onClick() {
      return (this.application.connected) ? this.manage() : this.connect();
    },

    manage() {
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'google', isManaging: true });
    },

    connect() {
      this.connecting = true;
    },

    async getGooglePlaces() {
      try {
        const { accessToken } = this.application;

        const { accounts, error } = await this.$store.dispatch('companies/getGmbAccounts', accessToken);
        if (error) {
          return;
        }

        const getLocations = [];

        for (const account of accounts) {
          getLocations.push(this.$store.dispatch('companies/getGmbLocations', {
            accessToken,
            accountName: account.name,
          }));
        }

        const locationResults = await Promise.all(getLocations);

        for (const result of locationResults) {
          const { locations = [], error } = result;
          if (error) {
            return;
          }

          for (const location of locations) {
            const {
              metadata, title, name,
            } = location;

            // some companies' GMB setups have duplicate locations (duplicated by place id)
            // with some of the duplicate locations being valid (not suspended, not disconnected)
            // if we have at least one valid duplicate, don't show the 'suspended or disconnected'
            // error message
            // const activeDuplicateLocations = locations.filter((otherLocation) => {
            //   if (location.name === otherLocation.name) {
            //     return false;
            //   }
            //   if (!location.metadata || !otherLocation.metadata) {
            //     return false;
            //   }
            //   if (location.metadata.placeId === otherLocation.metadata.placeId) {
            //     return !otherLocation.locationState.isDisconnected && !otherLocation.locationState.isSuspended;
            //   }
            //   return false;
            // });

            const errors = [];
            // if (isSuspended && !activeDuplicateLocations.length) {
            //   errors.push('suspended');
            // }
            // if (isDisconnected && !activeDuplicateLocations.length) {
            //   errors.push('disconnected');
            // }
            this.googlePlaces.push({
              placeId: metadata?.placeId,
              name: title,
              url: name,
              errors,
            });
          }
        }
      } catch (error) {
        //
      }
    },

    async handleConnection() {
      await this.getGooglePlaces();
      this.manage();
    },
  },
};
</script>
