<template>
  <div>
    <vx-card
      class="overflow-hidden cursor-pointer transform hover:-translate-y-1"
      @click="onClick(application)"
    >
      <template #no-body>
        <div class="p-3 flex flex-col items-center justify-center text-center">
          <div class="flex items-center justify-between mb-4">
            <img
              :src="application.logo"
              :alt="application.name"
            >
          </div>

          <div class="truncate">
            <h2 class="mb-1 font-bold text-lg">
              {{ application.name }}
            </h2>
            <span class="text-gray-500">
              {{ application.type }}
            </span>
          </div>
        </div>
      </template>
    </vx-card>

    <rj-connect
      :active.sync="connecting"
      :locations="locations"
      :funnels="funnels"
      :employees="employees"
      :webhook="webhook"
      @connected="handleConnection"
    />

    <rj-connected-locations
      :active="managing"
      :webhooks="application.webhooks"
      @create-webhook="connect"
      @edit-webhook="edit"
    />
  </div>
</template>

<script>
import RjConnect from '@/views/company-dashboard/integrations/apps/activecampaign/RjConnect.vue';
import RjConnectedLocations from '@/views/company-dashboard/integrations/apps/activecampaign/RjConnectedLocations.vue';
import { httpBuildQuery } from '@/utils';

export default {
  name: 'RjActiveCampaignApp',

  components: {
    RjConnect,
    RjConnectedLocations,
  },

  props: {
    application: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      connecting: false,
      webhook: null,
    };
  },

  computed: {
    locations() {
      return this.$store.getters['locations/locations'];
    },

    funnels() {
      return this.$store.getters['funnels/funnels'];
    },

    employees() {
      return this.$store.getters['users/users'].map((user) => ({
        fullName: `${user.firstName} ${user.lastName}`, ...user,
      }));
    },

    managing() {
      return this.application.managing;
    },

  },

  async mounted() {
    const companyId = this.$store.getters['companies/company'].id;
    const usersFilter = httpBuildQuery({
      filter: JSON.stringify({
        where: {
          companyId,
        },
        include: [
          {
            relation: 'roleMappings',
            scope: { include: ['role'] },
          },
        ],
      }),
    });
    this.$store.dispatch('users/fetchUsers', usersFilter);

    const funnelFilter = httpBuildQuery({
      filter: JSON.stringify({
        where: {
          companyId,
        },
      }),
    });
    this.$store.dispatch('funnels/fetchFunnels', funnelFilter);
  },

  methods: {
    onClick() {
      return (this.application.connected) ? this.manage() : this.connect();
    },

    connect() {
      this.webhook = null;
      this.connecting = true;
    },

    handleConnection() {
      this.$store.commit('companies/SET_ACTIVE_CAMPAIGN_CONNECTED', true);
      this.manage();
    },

    manage() {
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'activecampaign', isManaging: true });
    },

    edit(webhook) {
      this.webhook = webhook;
      this.connecting = true;
    },
  },
};
</script>
