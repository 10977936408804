<template>
  <vs-prompt
    title="Facebook"
    :active="active"
    accept-text="Connect"
    class="rj-medium-modal rj-confirm-modal"
    @accept="connect"
    @close="close"
  >
    <div class="space-y-2">
      <p>To monitor Facebook reviews in your RevenueJump dashboard, connect your Facebook page(s).</p>
      <p>Click the Connect button below to be redirected to a Facebook authentication screen.</p>
    </div>

    <v-facebook-login
      class="hidden"
      :app-id="appId"
      :login-options="options"
      @sdk-init="handleSdkInit"
    />
  </vs-prompt>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component';

export default {
  name: 'RjConnect',

  components: {
    VFacebookLogin,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      facebook: null,
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      options: {
        return_scopes: true,
        scope: 'pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_metadata,pages_manage_engagement,public_profile,email',
      },
    };
  },

  methods: {
    handleSdkInit({ FB }) {
      this.facebook = FB;
    },

    connect() {
      this.facebook.login((response) => {
        if (response.status !== 'connected') {
          return;
        }
        this.$store.commit('companies/SET_FACEBOOK_AUTH_RESPONSE', response.authResponse);
        this.$emit('connected');
        this.close();
      }, this.options);
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
