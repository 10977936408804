import { bus } from '@/utils';
import RjSendSurveyDialog from '@/views/company-dashboard/surveys/modals/RjSendSurveyDialog.vue';

const events = [
  { name: 'show-modal', handler: '$_events_openModal' },
  { name: 'hide-modal', handler: '$_events_closeModal' },
  { name: 'upgrade-company', handler: '$_events_upgradeCompanyPlan' },
];

export default {
  components: {
    'send-survey': RjSendSurveyDialog,
  },

  data() {
    return {
      csp_modal: null,
      csp_show: false,
      csp_props: {},
    };
  },

  created() {
    for (const { name, handler } of events) {
      bus.$on(name, this[handler]);
    }
  },

  beforeDestroy() {
    for (const { name, handler } of events) {
      bus.$off(name, this[handler]);
    }
  },

  methods: {
    $_events_upgradeCompanyPlan() {
      // const company = this.$store.getters['companies/company'];
      const partner = this.$store.getters['partners/partner'];

      const dialog = (attributes) => this.$vs.dialog(attributes);

      const notifyPartner = {
        type: 'alert',
        color: 'primary',
        title: 'High Jump Feature',
        text: `Smart move! We just sent ${partner.name} a notification about your interest in High Jump. They should reach out to you soon with more information.`,
        acceptText: 'OK',
        accept: () => {},
      };

      const upgradeIntent = {
        type: 'alert',
        color: 'primary',
        title: 'High Jump Feature',
        text: 'You are on the RevenueJump Low Jump plan and this feature is available on High Jump. Would you like more information about our High Jump plan?',
        acceptText: 'Yes',
        accept: async() => {
          const { id } = this.$store.getters['companies/company'];
          this.$store.dispatch('companies/sendUpgradeRequest', id);
          dialog(notifyPartner);
        },
      };

      dialog(upgradeIntent);
    },

    $_events_openModal({ modal, props = null }) {
      this.csp_show = true;
      this.csp_modal = modal;
      this.csp_props = props;
    },

    $_events_closeModal() {
      this.csp_show = false;
      this.csp_modal = null;
      this.csp_props = {};
    },
  },
};
