<template>
  <section>
    <template v-if="loading">
      Loading Users...
    </template>

    <template v-else>
      <div
        id="table__company-users"
        class="rv-default-data-list data-list-container"
      >
        <rj-users-list
          :users="users"
          @selected="toggleUserModal"
        >
          <template #actions>
            <vs-button
              class="w-full"
              @click="addUser"
            >
              Add User
            </vs-button>
          </template>

          <template #table-row="{ user }">
            <!-- NAME -->
            <vs-td class="whitespace-no-wrap">
              <p class="font-medium truncate">
                {{ user.firstName }} {{ user.lastName }}
              </p>
            </vs-td>

            <!-- ROLE -->
            <vs-td class="whitespace-no-wrap">
              <p class="font-medium truncate w-full text-center">
                {{ user.role.description }}
              </p>
            </vs-td>

            <!-- SURVEYS SENT -->
            <vs-td class="whitespace-no-wrap">
              <p class="font-medium w-full text-center">
                {{ user.surveysCount }}
              </p>
            </vs-td>

            <!-- VISIT RATE -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-progress
                  class="shadow w-16"
                  :percent="user.visitRate"
                  color="danger"
                  height="8"
                />
                <span class="ml-3 text-sm text-gray-600">{{ user.visitRate }}%</span>
              </div>
            </vs-td>

            <!-- COMPLETION RATE -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-progress
                  class="shadow w-16"
                  :percent="user.completionRate"
                  color="success"
                  height="8"
                />
                <span class="ml-3 text-sm text-gray-600">{{ user.completionRate }}%</span>
              </div>
            </vs-td>

            <!-- SATISFACTION SCORE -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-progress
                  class="shadow w-16"
                  :percent="user.satisfactionScore"
                  color="warning"
                  height="8"
                />
                <span class="ml-3 text-sm text-gray-600">{{ user.satisfactionScore }}%</span>
              </div>
            </vs-td>

            <!-- ACTION -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-button
                  icon-pack="feather"
                  icon="icon-settings"
                  type="flat"
                  class="inline-block"
                  title="User Settings"
                />
                <vs-button
                  icon-pack="feather"
                  icon="icon-slash"
                  type="flat"
                  class="inline-block"
                  title="Delete User"
                  @click.stop="removeConfirmation(user)"
                />
              </div>
            </vs-td>
          </template>
        </rj-users-list>
      </div>
    </template>

    <rj-user-modal
      v-if="modal"
      context="company"
      :user="selected"
      :company="company"
      :active.sync="modal"
      @create-user="create"
      @update-user="update"
    />
  </section>
</template>

<script>
import { httpBuildQuery, bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import RjUsersList from '@/views/common/users/RjUsersList.vue';
import RjUserModal from '@/views/common/users/modals/RjUserModal.vue';

export default {
  name: 'RjCompanyUsers',

  components: {
    RjUserModal,
    RjUsersList,
  },

  mixins: [Authorizable],

  data() {
    return {
      modal: false,
      loading: true,
      selected: null,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    users() {
      return this.$store.getters['users/users'];
    },

    counts() {
      return {
        users: this.users.length,
        locations: this.$store.getters['companies/locationsCount'],
        usersAddOns: this.$store.getters['companies/extraUsersAddOns'].length,
      };
    },
  },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      await this.fetchLocations();
      await this.fetchUsers();
      const companyId = this.$store.getters['companies/company'].id;
      await this.$store.dispatch('companies/withCounts', companyId);

      this.loading = false;
    },

    async fetchLocations() {
      const companyId = this.$store.getters['companies/company'].id;
      const filters = httpBuildQuery({
        filter: JSON.stringify({
          where: { companyId, active: true },
        }),
      });

      await this.$store.dispatch('locations/fetchLocations', filters);
    },

    async fetchUsers() {
      const companyId = this.$store.getters['companies/company'].id;
      const filters = httpBuildQuery({
        includeSurveyStats: 1,
        filter: JSON.stringify({
          where: {
            companyId,
            active: true,
          },
          include: [
            {
              relation: 'roleMappings',
              scope: { include: ['role'] },
            },
          ],
        }),
      });

      await this.$store.dispatch('users/fetchUsers', filters);
    },

    addUser() {
      if (this.canAddUser()) {
        this.toggleUserModal();
        return;
      }

      this.isLowJump ? bus.$emit('upgrade-company') : this.buyUsersPackIntent();
    },

    async create(form) {
      try {
        const { locationAccessControl, ...payload } = form;
        const { error, errorMessage, ...user } = await this.$store.dispatch('users/createUser', payload);
        if (error) {
          this.$vs.notify({ title: 'Error', text: errorMessage || error, color: 'danger' });
          return;
        }
        await this.updateLocationsAccess(user, locationAccessControl);
        this.$vs.notify({ title: 'Success', text: 'User created.', color: 'success' });
        this.modal = false;
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async update(form) {
      try {
        const { locationAccessControl, ...payload } = form;
        const user = await this.$store.dispatch('users/updateUser', { userId: form.id, payload });
        await this.updateLocationsAccess(user, locationAccessControl);

        this.$vs.notify({ title: 'Success', text: 'User updated.', color: 'success' });
        this.modal = false;
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async updateLocationsAccess(user, locations) {
      await this.$store.dispatch('users/updateLocationAccessControls', { userId: user.id, locations });
    },

    removeConfirmation(user) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'You are about to delete this user and this action cannot be undone.',
        acceptText: 'Delete',
        accept: () => this.remove(user),
      });
    },

    async remove(user) {
      try {
        await this.$store.dispatch('users/deleteUser', user.id);
        this.$vs.notify({ title: 'Success', text: 'User deleted.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: 'Error while deleting the user.', color: 'danger' });
      }
    },

    buyUsersPackIntent() {
      const partner = this.$store.getters['partners/partner'];

      const dialog = (attributes) => this.$vs.dialog(attributes);

      const additionalUsers = {
        type: 'alert',
        color: 'primary',
        title: 'Limit Reached',
        text: `Sounds good! We just sent ${partner.name} a notification about your interest in more users. They should reach out to you soon with more information.`,
        acceptText: 'OK',
      };

      const limitReached = {
        type: 'alert',
        color: 'primary',
        title: 'Limit Reached',
        text: 'You have reached the maximum number of users. Either delete a user to make room for another or request to add another 5-pack of users. Would you like to more information about adding users?',
        acceptText: 'Yes',
        accept: () => {
          dialog(additionalUsers);
          this.$store.dispatch('companies/sendAdditionalUsersRequest', this.company.id);
        },
      };

      dialog(limitReached);
    },

    canAddUser() {
      // eslint-disable-next-line
      return this.guard_company_plan_allows('USER_ALLOCATION', this.counts.users, (allocation, totalUsers) => {
        return (allocation * this.counts.locations) + (5 * this.counts.usersAddOns) > totalUsers;
      });
    },

    /**
     * Toggles the user modal visibility.
     *
     * @param  {Object} user
     * @return {void}
     */
    toggleUserModal(user = null) {
      this.modal = !this.modal;
      this.selected = user;
    },
  },
};
</script>
