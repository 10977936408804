<template>
  <vs-prompt
    title="ActiveCampaign"
    :active="active"
    accept-text="Add Webhook"
    class="rj-medium-modal rj-confirm-modal"
    :style="{ zIndex: 52004 }"
    @accept="create"
    @close="close"
  >
    <p>Below are the Webhook URLs you’ve created. Copy and paste them into your ActiveCampaign Automation.</p>
    <div>
      <vs-table
        id="table__location-mapping"
        stripe
        hover-flat
        :data="webhooks"
        class="my-3 border-0"
        :no-data-text="'No webhook URLs'"
      >
        <template #thead>
          <vs-th class="w-1/3 p-2">
            <span class="uppercase">
              Location
            </span>
          </vs-th>
          <vs-th class="w-1/3 p-2">
            <span class="uppercase">
              Funnel
            </span>
          </vs-th>
          <vs-th class="w-1/3 p-2">
            <span class="uppercase">
              Employee
            </span>
          </vs-th>
        </template>

        <template #default="{ data }">
          <template v-for="(webhook, index) in data">
            <vs-tr :key="index">
              <vs-td>
                <span>{{ webhook.location.name }}</span>
              </vs-td>
              <vs-td>
                <span>{{ webhook.surveyTemplate.name }}</span>
              </vs-td>
              <vs-td>
                <span>{{ webhook.user.firstName }} {{ webhook.user.lastName }}</span>
              </vs-td>
            </vs-tr>
            <vs-tr :key="webhook.createdAt">
              <vs-td
                colspan="3"
                class="p-0"
              >
                <div class="flex items-center">
                  <autosize-textarea
                    ref="webhook-url"
                    class="border border-gray-300 rounded-lg px-2 py-3 mt-1 bg-gray-200"
                    readonly
                    style="font-family: monospace;"
                    :value="webhook.url"
                  />
                  <div class="flex items-center justify-end ml-2">
                    <vs-button
                      type="flat"
                      icon-pack="feather"
                      icon="icon-copy"
                      title="Copy"
                      @click.stop="copy(webhook, index)"
                    />
                    <vs-button
                      type="flat"
                      icon-pack="feather"
                      icon="icon-settings"
                      title="Settings"
                      @click.stop="edit(webhook)"
                    />
                    <vs-button
                      type="flat"
                      icon-pack="feather"
                      icon="icon-slash"
                      title="Delete"
                      @click.stop="removeConfirmation(webhook)"
                    />
                  </div>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </template>
      </vs-table>
    </div>
  </vs-prompt>
</template>

<script>
import { AutosizeTextarea } from 'vue-autosizer';

export default {
  name: 'RjConnectedLocations',

  components: {
    AutosizeTextarea,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    webhooks: {
      type: Array,
      required: true,
    },
  },

  methods: {
    create() {
      this.$emit('create-webhook');
      this.close();
    },

    edit(webhook) {
      this.$emit('edit-webhook', webhook);
      this.close();
    },

    async copy(webhook, index) {
      await this.clipboard(webhook.url, this.$refs['webhook-url'][index]);
      this.$vs.notify({
        title: 'Copied',
        text: 'Webhook url was copied to your clipboard.',
        color: 'success',
      });
    },

    clipboard(text, element) {
      if (navigator.clipboard) {
        return navigator.clipboard.writeText(text);
      }

      element.$el.select();
      document.execCommand('Copy');
      element.$el.setSelectionRange(0, 0);
      return document.execCommand('Copy');
    },

    removeConfirmation(webhook) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'This action will erase all current settings for this webhook.',
        acceptText: 'Yes',
        accept: () => this.remove(webhook),
      });
    },

    async remove(webhook) {
      try {
        await this.$store.dispatch('companies/deleteWebhook', webhook);
        this.$vs.notify({ title: 'Success', text: 'Webhook deleted', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    close() {
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'activecampaign', isManaging: false });
      this.$emit('update:active', false);
    },
  },
};
</script>
