<template>
  <vs-prompt
    title="Zapier"
    :active="active"
    accept-text="Create"
    class="rj-medium-modal rj-confirm-modal"
    @accept="connect"
    @close="close"
  >
    <p>Generate a unique access token to connect RevenueJump with your Zapier automation.</p>
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjConnect',

  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    async connect() {
      const payload = {
        userId: this.$store.getters['auth/currentUser'].id,
        serviceCode: 'zapier',
        companyId: this.$store.getters['companies/company'].id,
      };

      await this.$store.dispatch('companies/generateIntegrationAccessToken', payload);
      this.$emit('connected');
      this.close();
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
