<template>
  <div class="mt-4">
    <label class="vs-input--label">Sort</label>
    <div class="flex items-center flex-wrap space-x-3 sm:space-x-6 mt-1">
      <vs-radio
        :value="sourceConfig.sort"
        vs-value="most-recent"
        class="text-base"
        @input="(value) => update('sourceConfig', 'sort', value)"
      >
        Most Recent
      </vs-radio>

      <vs-radio
        :value="sourceConfig.sort"
        vs-value="random"
        class="text-base"
        @input="(value) => update('sourceConfig', 'sort', value)"
      >
        Random
      </vs-radio>

      <vs-radio
        :value="sourceConfig.sort"
        vs-value="specific"
        class="text-base"
        @input="(value) => update('sourceConfig', 'sort', value)"
      >
        Specific
      </vs-radio>
    </div>

    <div
      v-if="sourceConfig.sort === 'specific'"
      class="mt-3"
    >
      <vs-input
        :value="itemIdsStr"
        name="itemIds"
        label="Quote ID's (comma separated)"
        class="w-5/12"
        @input="(value) => updateItemIds(value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RjWidgetSortCard',

  props: {
    sourceConfig: {
      type: Object,
      required: true,
    },

    update: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      itemIdsStr: '',
    };
  },

  computed: {
    itemIds() {
      try {
        return this.sourceConfig.itemIds.join(', ');
      } catch (error) {
        return '';
      }
    },
  },

  mounted() {
    this.itemIdsStr = this.sourceConfig.itemIds?.join(', ') || '';
  },

  methods: {
    updateItemIds(value) {
      try {
        const ids = value.split(',').map((id) => parseInt(id, 10));
        this.update('sourceConfig', 'itemIds', ids);
        this.itemIdsStr = value;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
