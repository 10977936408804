<template>
  <section>
    <template v-if="loading">
      <p>Loading Applications...</p>
    </template>

    <template v-else>
      <div class="mb-8">
        <h2 class="mb-4">
          Connected Apps
        </h2>
        <template v-if="!connected.length">
          <p>There are no connected apps at this time. Select an application below to get started.</p>
        </template>

        <template v-else>
          <div
            v-for="(chunked, index) in connected"
            :key="index"
            class="vx-row"
          >
            <div
              v-for="application in chunked"
              :key="application.code"
              class="vx-col w-1/2 sm:w-2/12 application-card py-2"
            >
              <component
                :is="application.component"
                :application="application"
              />
            </div>
          </div>
        </template>
      </div>

      <div>
        <h2 class="mb-4">
          Available Apps
        </h2>
        <div
          v-for="(chunked, index) in available"
          :key="index"
          class="vx-row"
        >
          <div
            v-for="application in chunked"
            :key="application.code"
            class="vx-col w-1/2 sm:w-2/12 application-card py-2"
          >
            <component
              :is="application.component"
              :application="application"
            />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { chunk, httpBuildQuery } from '@/utils';
import applications from '@/views/company-dashboard/integrations/apps/applications';
import RjZapierApp from '@/views/company-dashboard/integrations/apps/zapier/RjZapierApp.vue';
import RjFacebookApp from '@/views/company-dashboard/integrations/apps/facebook/RjFacebookApp.vue';
import RjRevenueJumpApp from '@/views/company-dashboard/integrations/apps/revenuejump/RjRevenueJumpApp.vue';
import RjGoogleBusinessApp from '@/views/company-dashboard/integrations/apps/google/RjGoogleBusinessApp.vue';
import RjInfusionsoftApp from '@/views/company-dashboard/integrations/apps/infusionsoft/RjInfusionsoftApp.vue';
import RjActiveCampaignApp from '@/views/company-dashboard/integrations/apps/activecampaign/RjActiveCampaignApp.vue';

export default {
  name: 'RjApplications',

  components: {
    RjZapierApp,
    RjFacebookApp,
    RjRevenueJumpApp,
    RjInfusionsoftApp,
    RjGoogleBusinessApp,
    RjActiveCampaignApp,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    locations() {
      return this.$store.getters['locations/locations'];
    },

    applications() {
      return this.$store.getters['companies/applications'];
    },

    connected() {
      return chunk(this.applications.filter((app) => app.connected), 6);
    },

    available() {
      return chunk(this.applications.filter((app) => !app.connected), 6);
    },
  },

  watch: {
    $router: {
      handler: 'fetch',
      immediate: true,
    },

    // applications: {
    //   handler: 'checkForConnections',
    // },
  },

  methods: {
    async fetch() {
      const companyId = this.company.id;
      const filters = httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { active: true, companyId },
        }),
      });

      await this.$store.dispatch('locations/fetchLocations', filters);
      this.$store.commit('companies/SET_APPLICATIONS', applications);
      this.checkForConnections();
      this.loading = false;

      // check for successful authorization from infusionsoft oauth
      const { scope = '', code } = this.$route.query;
      if (code && /infusionsoft\.com/.test(scope)) {
        const {
          success, accessToken, appId, cloudBridgeCredentialsId,
        } = await this.$store.dispatch('companies/saveInfusionsoftRefreshToken', { companyId, authCode: code, redirectUri: `${process.env.VUE_APP_RJ_APP_URL}/company/integrations/applications` });

        if (success) {
          this.$store.commit('companies/SET_INFUSIONSOFT_ACCESS_TOKEN', { accessToken, appId, cloudBridgeCredentialsId });
          this.$store.commit('companies/SET_INFUSIONSOFT_TAGGING', true);
        } else {
          this.$vs.notify({ title: 'Error', text: 'Please try reconnecting to Infusionsoft.', color: 'danger' });
        }
      }
    },

    checkForConnections() {
      this.checkFacebookConnection();
      this.checkGoogleConnection();
      this.checkActiveCampaignConnection();
      this.checkInfusionsoftConnection();

      try {
        this.$store.dispatch('companies/getIntegrationAccessTokens', this.company.id);
      } catch (error) {
        //
      }
    },

    async checkFacebookConnection() {
      const locations = this.locations.filter((location) => location.facebookAccessToken && location.facebookPageId);
      const checks = locations.map((location) => this.$store.dispatch('locations/getFacebookPage', location));
      try {
        // if we have access to at least one page, consider Facebook connected
        const page = await Promise.any(checks);
        if (page) {
          this.$store.commit('companies/SET_FACEBOOK_CONNECTED', true);
        }
      } catch (error) {
        //
      }
    },

    async checkGoogleConnection() {
      if (this.company.gmbRefreshToken !== null) {
        const companyId = this.$store.getters['companies/company'].id;
        const { accessToken } = await this.$store.dispatch('companies/refreshGmbToken', companyId);

        if (!accessToken) {
          return;
        }

        this.$store.commit('companies/SET_GOOGLE_CONNECTED', true);
        this.$store.commit('companies/SET_GOOGLE_ACCESS_TOKEN', accessToken);
      }
    },

    async checkActiveCampaignConnection() {
      try {
        const companyId = this.$store.getters['companies/company'].id;
        const filter = httpBuildQuery({
          filter: JSON.stringify({
            where: {
              companyId,
            },
            include: ['user', 'location', 'surveyTemplate'],
          }),
        });
        const webhooks = await this.$store.dispatch('companies/getWebhooks', filter);
        if (webhooks.length) {
          this.$store.commit('companies/SET_ACTIVE_CAMPAIGN_CONNECTED', true);
        }
      } catch (error) {
        //
      }
    },

    async checkInfusionsoftConnection() {
      try {
        const companyId = this.$store.getters['companies/company'].id;
        const filters = httpBuildQuery({
          filter: JSON.stringify({
            where: {
              companyId,
              crm: 'Infusionsoft',
            },
          }),
        });
        const [cloudBridgeCredentials] = await this.$store.dispatch('companies/getCloudBridgeCredentials', filters);
        if (cloudBridgeCredentials) {
          const { accessToken, appId, id } = cloudBridgeCredentials;
          this.$store.commit('companies/SET_INFUSIONSOFT_CONNECTED', true);
          this.$store.commit('companies/SET_INFUSIONSOFT_ACCESS_TOKEN', { accessToken, appId, cloudBridgeCredentialsId: id });
        }
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style scoped>
.application-card {
  min-width: 220px;
}
</style>
