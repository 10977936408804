<template>
  <section v-if="loading">
    Loading...
  </section>

  <section v-else>
    <header class="flex flex-wrap items-center mb-6">
      <h2 class="pr-3 font-semibold text-gray-600">
        Negative Feedback
      </h2>
    </header>

    <div class="rv-default-data-list data-list-container">
      <vs-table
        id="rj-default-table"
        ref="table"
        :data="surveys"
        :max-items="itemsPerPage"
        :no-data-text="noDataText"
        search
        pagination
        @selected="(item) => openEditDialog(item)"
      >
        <template slot="header">
          <div class="flex sm:hidden">
            <vs-button
              type="border"
              icon-pack="feather"
              icon="icon-filter"
              class="mr-4"
              @click="filtering = true"
            />
          </div>

          <div class="hidden sm:flex items-center flex-grow justify-between">
            <div class="flex items-center data-list-btn-container space-x-4">
              <vs-button
                v-if="canSendSurveys"
                @click="sendSurvey"
              >
                Send Survey
              </vs-button>

              <vs-button
                type="border"
                @click="filtering = true"
              >
                Filter
              </vs-button>

              <vs-button
                v-if="hasFilters"
                type="flat"
                class="px-3"
                @click="resetFilters"
              >
                Reset Filters
              </vs-button>
            </div>

            <vs-dropdown
              v-if="surveys.length"
              vs-trigger-click
              class="cursor-pointer mr-4 items-per-page-handler"
            >
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">
                  {{ currentPage * itemsPerPage - (itemsPerPage - 1) }}
                  -
                  {{ surveys.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : surveys.length }}
                  of
                  {{ queriedItems }}
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  svg-classes="h-4 w-4"
                />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 50">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 100">
                  <span>100</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </template>

        <template slot="thead">
          <vs-th>Customer</vs-th>
          <vs-th>Date</vs-th>
          <vs-th>Quote</vs-th>
          <vs-th>Location</vs-th>
          <vs-th>Employee</vs-th>
          <vs-th>Status</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr
              v-for="item in data"
              :key="item.id"
              :data="item"
            >
              <vs-td>
                <span class="name-clamp clamp-2">
                  {{ item.contact.firstName }} {{ item.contact.lastName || '' }}
                </span>
              </vs-td>
              <vs-td class="whitespace-nowrap">
                <p>{{ format(new Date(item.pageVisitedAt), 'MMM. d, yyyy') }}</p>
              </vs-td>
              <vs-td>
                <p class="clamp-2">
                  {{ item.feedback }}
                </p>
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ item.location.name }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ item.user.firstName }} {{ item.user.lastName }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ item.feedbackStatus }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                <div class="flex">
                  <vs-button
                    icon-pack="feather"
                    icon="icon-edit"
                    type="flat"
                    class="inline-block"
                    title="Edit Testimonial"
                    :color="themeColors.rjPurple"
                    @click.stop="openEditDialog(item)"
                  />
                  <vs-button
                    icon-pack="feather"
                    icon="icon-rotate-ccw"
                    type="flat"
                    class="inline-block"
                    title="Respond"
                    :color="themeColors.rjPurple"
                    @click.stop="respond(item)"
                  />
                  <vs-button
                    icon-pack="feather"
                    icon="icon-slash"
                    type="flat"
                    class="inline-block"
                    title="Delete"
                    :color="themeColors.rjPurple"
                    @click.stop="removeConfirmation(item)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>

    <rj-filter-negative-feedback-dialog
      v-if="filtering"
      :active.sync="filtering"
      :applied-filters="filters"
      @apply-filters="applyFilters"
      @reset-filters="resetFilters"
    />

    <rj-edit-negative-feedback-dialog
      v-if="editing"
      :active.sync="editing"
      :feedback="selected"
      @remove="removeConfirmation"
      @saved="save"
    />
  </section>
</template>

<script>
import { format } from 'date-fns';
import { bus, httpBuildQuery } from '@/utils';
import { colors as themeColors } from '@/../themeConfig';
import filters from '@/views/company-dashboard/results/filters/negative-feedback';
import RjEditNegativeFeedbackDialog from '@/views/company-dashboard/results/modals/RjEditNegativeFeedbackDialog.vue';
import RjFilterNegativeFeedbackDialog from '@/views/company-dashboard/results/modals/RjFilterNegativeFeedbackDialog.vue';

export default {
  name: 'RjNegativeFeedback',

  components: {
    RjEditNegativeFeedbackDialog,
    RjFilterNegativeFeedbackDialog,
  },

  data() {
    return {
      itemsPerPage: 20,
      isMounted: false,
      themeColors,
      editing: false,
      selected: {},
      filters: {},
      filtering: false,
      surveys: [],
      loading: true,
      fetching: true,
      hasFilters: false,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    currentPage() {
      return this.isMounted && this.$refs.table ? this.$refs.table.currentx : 0;
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.surveys.length;
    },

    canSendSurveys() {
      return this.auth.user.companyId || (this.auth.user.partnerId && this.company.partnerCanSendSurveys);
    },

    noDataText() {
      if (this.loading) {
        return 'Loading...';
      }
      if (this.fetching) {
        return 'Searching...';
      }
      return 'No feedback found for current filter.';
    },
  },

  async mounted() {
    this.filters = filters();
    this.isMounted = true;

    await this.fetchData();
    this.loading = false;

    this.fetchSurveys();
    this.fetchFunnels();
  },

  methods: {
    format,

    async fetchData() {
      const companyId = this.$store.getters['companies/company'].id;

      const getLocations = this.$store.dispatch('locations/fetchLocations', httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { active: true, companyId },
        }),
      }));

      const getUsers = this.$store.dispatch('users/fetchUsers', httpBuildQuery({
        filter: JSON.stringify({
          where: { active: true, companyId },
          include: [
            {
              relation: 'roleMappings',
              scope: { include: ['role'] },
            },
          ],
        }),
      }));

      await Promise.all([getLocations, getUsers]);
    },

    async fetchSurveys() {
      this.fetching = true;

      const {
        startDate,
        endDate,
        location,
        employee,
        status,
      } = this.filters;

      const filter = {
        where: {
          companyId: this.$store.getters['companies/company'].id,
          active: true,
          sentiment: 0,
        },
        include: [
          'contact',
          {
            relation: 'location',
            scope: {
              fields: ['name', 'timezone'],
            },
          },
          {
            relation: 'user',
            scope: {
              fields: ['firstName', 'lastName'],
              where: {
                active: { inq: [true, false] },
              },
            },
          },
        ],
      };

      if (startDate && endDate) {
        filter.where.createdAt = {
          between: [startDate.toISOString(), endDate.toISOString()],
        };
      } else if (startDate) {
        filter.where.createdAt = {
          gte: startDate.toISOString(),
        };
      } else if (endDate) {
        filter.where.createdAt = {
          lte: endDate.toISOString(),
        };
      }

      if (location) {
        filter.where.locationId = location;
      }

      if (employee) {
        filter.where.userId = employee;
      }

      if (status) {
        filter.where.feedbackStatus = status;
      }

      const surveys = await this.$store.dispatch('surveys/getSurveys', filter);
      surveys.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      this.surveys = surveys;

      this.fetching = false;
    },

    async fetchFunnels() {
      const companyId = this.$store.getters['companies/company'].id;
      await this.$store.dispatch('funnels/fetchFunnels', httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: {
            companyId,
          },
        }),
      }));
    },

    sendSurvey() {
      bus.$emit('show-modal', { modal: 'send-survey' });
    },

    openEditDialog(item) {
      this.editing = true;
      this.selected = item;
    },

    respond(feedback) {
      console.log({ feedback });

      this.$vs.dialog({
        color: 'primary',
        title: 'Alert!',
        text: 'We are working diligently to improve RevenueJump. This feature is coming soon...',
        acceptText: 'Accept',
      });
    },

    removeConfirmation(feedback) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are You Sure?',
        text: 'You are about to delete this feedback and this action cannot be undone.',
        acceptText: 'Delete',
        accept: () => this.remove(feedback),
      });
    },

    async remove(feedback) {
      this.editing = false;
      this.selected = {};
      try {
        await this.$store.dispatch('surveys/deleteSurvey', feedback.id);
        const index = this.surveys.findIndex((s) => s.id === feedback.id);
        if (index < 0) {
          return;
        }
        this.surveys.splice(index, 1);
        this.$vs.notify({
          title: 'Success',
          text: 'Negative feedback deleted.',
          color: 'success',
        });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async save(survey) {
      try {
        await this.$store.dispatch('surveys/updateSurvey', survey);
        const index = this.surveys.findIndex((s) => s.id === survey.id);
        if (index < 0) {
          return;
        }
        this.surveys[index].feedbackStatus = survey.feedbackStatus;
        this.$vs.notify({
          title: 'Success',
          text: 'Negative feedback updated.',
          color: 'success',
        });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    applyFilters(filters) {
      this.filters = filters;
      this.hasFilters = true;
      this.fetchSurveys();
    },

    resetFilters() {
      this.filters = filters();
      this.hasFilters = false;
      this.fetchSurveys();
    },
  },
};
</script>
