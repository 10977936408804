<template>
  <rj-base-widget-builder>
    <template #design="{ designConfig, update }">
      <rj-widget-design-picker v-bind="{ designConfig, update }" />
    </template>

    <template #settings="{ designConfig, update }">
      <rj-widget-base-settings v-bind="{ designConfig, update }" />
    </template>
  </rj-base-widget-builder>
</template>

<script>
import RjBaseWidgetBuilder from '@/views/company-dashboard/integrations/widgets/builder/RjBaseWidgetBuilder.vue';
import RjWidgetBaseSettings from '@/views/company-dashboard/integrations/widgets/partials/RjWidgetBaseSettings.vue';
import RjWidgetDesignPicker from '@/views/company-dashboard/integrations/widgets/partials/RjWidgetDesignPicker.vue';

export default {
  name: 'RjGridWidgetBuilder',

  components: {
    RjBaseWidgetBuilder,
    RjWidgetBaseSettings,
    RjWidgetDesignPicker,
  },
};
</script>
