<template functional>
  <div
    class="widget__container widget__avatar-top"
    :class="[data.class, data.staticClass]"
  >
    <slot
      name="avatar"
      :avatar="props.item.customer.avatar"
      size="large"
    />

    <div class="widget__top">
      <div class="widget__customer-info large">
        <p>{{ props.item.customer.name }}</p>
        <span v-if="props.settings.displayLocation">{{ props.item.customer.location }}</span>
      </div>

      <div>
        <slot
          name="rating"
          :rating="props.item.rating"
          :size="22"
        />
      </div>
    </div>

    <div class="widget__center">
      <h3 class="widget__title large">
        {{ props.item.title }}
      </h3>

      <slot
        name="quote"
        :quote="props.item.quote"
      />
    </div>

    <div class="widget__bottom">
      <slot name="branding" />

      <slot
        name="source"
        :reviewSite="props.item.reviewSite"
        :itemType="props.item.itemType"
        :when="props.item.when"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RjAvatarTopDesignRender',

  props: {
    item: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
};
</script>

