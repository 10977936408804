<template functional>
  <div
    class="widget-layout widget-layout__grid"
    :class="[data.class, data.staticClass]"
  >
    <template v-for="item in props.widget.items">
      <slot
        name="item"
        :item="item"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'RjGridLayoutRender',

  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
};
</script>
