<template>
  <vs-prompt
    title="Infusionsoft"
    :active="active"
    :buttons-hidden="true"
    :style="{ zIndex: 52004 }"
    class="rj-medium-modal rj-confirm-modal"
    @close="close"
  >
    <div class="space-y-2">
      <p>You’re connected to Infusionsoft app {{ application.appId }}!</p>
      <p>Below are the Infusionsoft tag(s) currently connected to RevenueJump.</p>
    </div>

    <div>
      <vs-table
        id="table__location-mapping"
        stripe
        hover-flat
        :data="tags"
        class="my-3 border-0"
        :no-data-text="'No connected tags'"
      >
        <template #thead>
          <vs-th class="p-2">
            <span class="uppercase">
              Infusionsoft Tag
            </span>
          </vs-th>
          <vs-th class="p-2">
            <span class="uppercase">
              Location/Funnel/Employee
            </span>
          </vs-th>
          <vs-th class="p-2">
            <span class="uppercase">
              Action
            </span>
          </vs-th>
        </template>

        <template #default="{ data }">
          <vs-tr
            v-for="(tag, index) in data"
            :key="index"
          >
            <vs-td>
              <span>{{ tag.name }}</span>
            </vs-td>
            <vs-td>
              <p>{{ tag.locationName }}</p>
              <p>{{ tag.funnelName }}</p>
              <p>{{ tag.employeeName }}</p>
            </vs-td>
            <vs-td>
              <div class="flex">
                <vs-button
                  type="flat"
                  icon-pack="feather"
                  icon="icon-settings"
                  title="Settings"
                  @click.stop="editTag(tag)"
                />
                <vs-button
                  type="flat"
                  icon-pack="feather"
                  icon="icon-slash"
                  title="Delete"
                  @click.stop="removeConfirmation(tag)"
                />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div class="flex items-center justify-end border-t border-gray-300 mt-4 pt-3">
      <vs-button
        type="border"
        @click.stop="confirmDisconnect"
      >
        Disconnect
      </vs-button>

      <vs-button
        class="ml-3"
        @click.stop="addTag"
      >
        Add Tag
      </vs-button>
    </div>
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjConnectedTags',

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    application: {
      type: Object,
      required: true,
    },

    tags: {
      type: Array,
      required: true,
    },
  },

  methods: {

    addTag() {
      this.$emit('add-tag');
      this.close();
    },

    editTag(tag) {
      const payload = {
        tag: {
          id: tag.id,
          label: tag.name,
        },
        location: tag.locationId,
        employee: tag.userId,
        funnel: tag.surveyTemplateId,
      };
      this.$emit('edit-tag', payload);
      this.close();
    },

    confirmDisconnect() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'This action will erase all current settings for connecting to your Infusionsoft application.',
        acceptText: 'Yes',
        accept: () => this.disconnect(),
      });
    },

    async disconnect() {
      try {
        await this.$store.dispatch('companies/deleteCloudBridgeCredentials', this.application.cloudBridgeCredentialsId);
        this.$store.commit('companies/SET_INFUSIONSOFT_ACCESS_TOKEN', {
          accessToken: null,
          appId: '',
          cloudBridgeCredentialsId: null,
        });
        this.$vs.notify({ title: 'Success', text: 'Infusionsoft disconnected.', color: 'success' });
        this.$store.commit('companies/SET_INFUSIONSOFT_CONNECTED', false);
        this.$store.commit('companies/SET_APP_MANAGING', { code: 'infusionsoft', isManaging: false });
        this.close();
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    removeConfirmation(tag) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'This action will erase all current settings for this tag.',
        acceptText: 'Yes',
        accept: () => this.remove(tag),
      });
    },

    async remove(tag) {
      const { id, tags = {} } = await this.$store.dispatch('companies/getCloudBridgeCredentialsById', this.application.cloudBridgeCredentialsId);

      delete tags[tag.id];

      const payload = {
        tags,
      };

      await this.$store.dispatch('companies/updateCloudBridgeCredentials', { id, payload });
      this.$emit('tag-removed');
    },

    close() {
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'infusionsoft', isManaging: false });
    },
  },
};
</script>
