<template>
  <section class="widget-layout widget-layout__carousel">
    <button
      class="swiper-button-prev"
      @click="prev()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="15 18 9 12 15 6" />
      </svg>
    </button>

    <div
      ref="swiper"
      class="swiper-container"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in widget.items"
          :key="index"
          class="swiper-slide"
        >
          <slot
            name="item"
            :item="item"
          />
        </div>
      </div>
    </div>

    <button
      class="swiper-button-next"
      @click="next()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="9 18 15 12 9 6" />
      </svg>
    </button>
  </section>
</template>

<script>
import Swiper, { Autoplay } from 'swiper';

Swiper.use([Autoplay]);

export default {
  name: 'RjCarouselLayoutRender',

  props: {
    widget: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      swiper: null,
    };
  },

  async mounted() {
    await this.$nextTick();

    const { autoplay, delay } = this.widget.designConfig;
    this.swiper = new Swiper(this.$refs.swiper, {
      loop: true,
      spaceBetween: 30,
      simulateTouch: false,
      autoplay: !autoplay ? false : { delay },
    });
  },

  beforeDestroy() {
    this.swiper.destroy();
    this.swiper = null;
  },

  methods: {
    prev() {
      // decrement index or loop around if we've reached the beginning
      const prevIndex = this.swiper.activeIndex > 0 ? this.swiper.activeIndex - 1 : this.widget.items.length - 1;
      this.swiper.slideTo(prevIndex);
    },
    next() {
      // increment index or loop around if we've reached the end
      const nextIndex = this.swiper.activeIndex < this.widget.items.length - 1 ? this.swiper.activeIndex + 1 : 0;
      this.swiper.slideTo(nextIndex);
    },
  },
};
</script>
