<template>
  <vs-prompt
    title="Google My Business"
    :active="active"
    :buttons-hidden="true"
    class="rj-medium-modal rj-confirm-modal"
    :style="{ zIndex: 52004 }"
    @close="close"
  >
    <div class="space-y-2">
      <p>You’re connected!</p>
      <p>Below are the Google My Business location(s) currently connected to RevenueJump.</p>
    </div>

    <div>
      <vs-table
        id="table__location-mapping"
        stripe
        hover-flat
        :data="connectedLocations"
        class="my-3 border-0"
        no-data-text="No connected locations. Your Locations must have a valid Google Place Id set in their Review Sites."
      >
        <template #thead>
          <vs-th class="w-full p-2">
            <span class="uppercase">
              Location
            </span>
          </vs-th>
          <vs-th class="p-2">
            <span class="uppercase">
              Google Place ID
            </span>
          </vs-th>
        </template>

        <template #default="{ data }">
          <vs-tr
            v-for="(location, index) in data"
            :key="index"
          >
            <vs-td>
              <div class="flex items-center">
                <p
                  v-if="location.errors.length"
                >
                  <vx-tooltip
                    :text="getErrorText(location.errors)"
                    position="bottom"
                    delay=".3s"
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      svg-classes="mr-2 h-5 w-5 mt-1"
                    />
                  </vx-tooltip>
                </p>
                <p>{{ location.name }}</p>
              </div>
            </vs-td>
            <vs-td>
              <span>{{ location.googlePlaceId }}</span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div class="flex items-center justify-end border-t border-gray-300 mt-4 pt-3">
      <vs-button
        type="border"
        @click="confirm"
      >
        Disconnect
      </vs-button>
    </div>
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjConnectedLocations',

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    connectedLocations: {
      type: Array,
      required: true,
    },
  },

  methods: {
    confirm() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are You Sure?',
        text: 'This action will erase all current settings for connecting to your Google My Business application.',
        acceptText: 'Yes',
        accept: () => this.disconnect(),
      });
    },

    async disconnect() {
      try {
        const company = this.$store.getters['companies/company'];

        if (!company.id) {
          throw new Error('Missing company');
        }

        const payload = {
          id: company.id,
          gmbRefreshToken: null,
        };
        await this.$store.dispatch('companies/updateCompanyDetails', { companyId: company.id, payload });
        this.$store.commit('companies/SET_GOOGLE_CONNECTED', false);
        this.$vs.notify({ title: 'Success', text: 'Google My Business disconnected', color: 'success' });
        this.close();
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    getErrorText(errors) {
      let text = `Google My Business reports this location as ${errors[0]}`;
      if (errors.length > 1) {
        text = `${text} and ${errors[1]}`;
      }
      text = `${text}. This may prevent some reviews from being captured. Please log into Google My Business to check the status of your locations.`;
      return text;
    },

    close() {
      this.$emit('update:active', false);
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'google', isManaging: false });
    },
  },
};
</script>
