<template functional>
  <div
    class="widget__container widget__wide"
    :class="[data.class, data.staticClass]"
  >
    <slot
      name="avatar"
      :avatar="props.item.customer.avatar"
      size="large"
    />

    <div class="widget__right">
      <div class="widget__top">
        <h3 class="widget__title large">
          {{ props.item.title }}
        </h3>
        <slot
          name="rating"
          :rating="props.item.rating"
          :size="18"
        />
      </div>

      <slot
        name="quote"
        :quote="props.item.quote"
      />

      <div class="widget__bottom">
        <div class="widget__customer-info large">
          <p>{{ props.item.customer.name }}</p>
          <span v-if="props.settings.displayLocation">{{ props.item.customer.location }}</span>
        </div>

        <div>
          <slot
            name="source"
            :reviewSite="props.item.reviewSite"
            :itemType="props.item.itemType"
            :when="props.item.when"
          />

          <slot name="branding" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RjWideDesignRender',

  props: {
    item: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
};
</script>
