<template>
  <section>
    <template v-if="loading">
      <p>Loading Funnel...</p>
    </template>

    <template v-else>
      <header class="flex items-center justify-between mb-6">
        <div class="flex flex-wrap items-center">
          <h2 class="pr-3 font-semibold text-gray-600 mr-3 border-0 md:border-r border-solid border-gray-500">
            Funnels
          </h2>
          <rj-breadcrumb :items="breadcrumbs" />
        </div>

        <rj-funnel-form-actions
          v-if="valid"
          :pristine="pristine"
          @save-funnel="onSave"
          @reset-funnel="reset"
        />
      </header>

      <rj-funnel-statistics />

      <div class="flex flex-wrap -mx-4">
        <div class="w-full sm:w-4/12 px-4 mb-4">
          <rj-funnel-form
            ref="funnelForm"
            :mode="mode"
            :is-company-user="guard_is('Company', (role) => /^Company/.test(role))"
          />
        </div>

        <div class="w-full sm:w-8/12 px-4">
          <rj-funnel-contents
            :is-company-user="guard_is('Company', (role) => /^Company/.test(role))"
          />
        </div>
      </div>

      <div
        v-if="valid"
        class="flex justify-end mt-6"
      >
        <rj-funnel-form-actions
          :pristine="pristine"
          @save-funnel="onSave"
          @reset-funnel="reset"
        />
      </div>
    </template>
  </section>
</template>

<script>
import _debounce from 'lodash/debounce';
import Authorizable from '@/mixins/Authorizable';
import useFunnelSettings from '@/views/common/funnels/useFunnelSettings';
import { httpBuildQuery, isPristine } from '@/utils';
import RjFunnelForm from '@/views/common/funnels/partials/RjFunnelForm.vue';
import RjFunnelContents from '@/views/common/funnels/partials/RjFunnelContents.vue';
import RjFunnelStatistics from '@/views/common/funnels/partials/RjFunnelStatistics.vue';
import RjFunnelFormActions from '@/views/common/funnels/partials/RjFunnelFormActions.vue';

export default {
  name: 'RjFunnelSettings',

  components: {
    RjFunnelForm,
    RjFunnelContents,
    RjFunnelStatistics,
    RjFunnelFormActions,
  },

  mixins: [Authorizable, useFunnelSettings],

  data() {
    return {
      loading: true,
      pristine: true,
      old: {},
      mode: 'edit',
    };
  },

  computed: {
    company() {
      return this.$store.getters['companies/company'];
    },

    funnel() {
      return this.$store.getters['funnels/funnel'];
    },

    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    hasOfferImage() {
      return this.$store.getters['funnels/hasOfferImage'];
    },

    offerImage() {
      return this.$store.getters['funnels/offerImage'];
    },

    breadcrumbs() {
      return [
        { title: 'Funnels', url: '/company/funnels' },
        { title: this.funnel.name, active: true },
      ];
    },
  },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },

    funnel: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  methods: {
    /**
     * Fetch the funnel with the given id
     *
     * @return {Promise}
     */
    async fetch() {
      try {
        const id = this.$route.params.funnel;
        const companyId = this.$store.getters['companies/company'].id;

        const filters = httpBuildQuery({
          includeSurveyStats: 1,
          filter: JSON.stringify({
            where: { companyId },
          }),
        });

        await this.$store.dispatch('funnels/fetchFunnel', { id, filters });
        this.old = { ...this.funnel };
        this.loading = false;
      } catch (error) {
        this.$router.replace({ name: 'company-home' });
      }
    },

    /**
     * Updates the current funnel
     *
     * @return {Promise}
     */
    async onSave() {
      try {
        const funnel = await this.$store.dispatch('funnels/updateFunnel', {
          id: this.funnel.id,
          payload: this.funnel,
        });

        if (funnel.errorMessage) {
          this.$vs.notify({ title: 'Error', text: funnel.errorMessage, color: 'danger' });
          return;
        }

        if (this.hasOfferImage) {
          await this.uploadOfferImage(this.funnel);
        }

        this.$vs.notify({ title: 'Success', text: 'Funnel saved.', color: 'success' });
        this.old = { ...this.funnel };
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    /**
     * Asks for confirmation before actually removing the funnel
     *
     * @return {void}
     */
    onDelete() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'This funnel will no longer be available for new surveys.',
        acceptText: 'Delete',
        accept: () => this.remove(),
      });
    },

    /**
     * Deletes the current funnel
     *
     * @return {Promise}
     */
    async remove() {
      try {
        await this.$store.dispatch('funnels/deleteFunnel', this.funnel.id);
        this.$vs.notify({ title: 'Success', text: 'Funnel deleted.', color: 'success' });

        this.$router.replace({
          name: 'partner-company',
          params: { company: this.company.id },
        });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: 'Error while deleting Funnel.', color: 'danger' });
      }
    },

    async uploadOfferImage(funnel) {
      await this.$store.dispatch('funnels/updateOfferImage', { id: funnel.id, file: this.offerImage });
    },

    reset() {
      this.$refs.funnelForm.reset();
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      this.pristine = isPristine(current, this.old);
    }, 150),
  },
};
</script>
