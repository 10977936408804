<template>
  <vs-prompt
    title="Edit Review"
    :active="active"
    class="rj-medium-modal rj-confirm-modal"
    :buttons-hidden="true"
    :style="{ zIndex: 52004 }"
    @close="close"
  >
    <div class="flex flex-col sm:flex-row items-center sm:px-8">
      <div
        v-if="review.photoUrl"
        class="relative flex-shrink-0 overflow-hidden"
      >
        <img
          :src="review.photoUrl"
          alt="Customer Photo"
          class="w-32 h-32 rounded-full object-fill"
        >
        <span class="absolute bottom-0 right-0 w-10 h-10 border-4 border-white rounded-full">
          <img
            v-if="reviewSiteLogo"
            :src="reviewSiteLogo"
            alt="Review Site Logo"
          >
        </span>
      </div>
      <div
        v-else
        class="relative bg-primary w-32 h-32 rounded-full flex-shrink-0"
      >
        <span class="relative flex items-center justify-center h-full">
          <img
            :src="defaultAvatar"
            alt="Customer Avatar"
            class="rounded-full w-1/2 h-auto"
          >
        </span>
        <span class="absolute bottom-0 right-0 w-10 h-10 border-4 border-white rounded-full">
          <img
            v-if="reviewSiteLogo"
            :src="reviewSiteLogo"
            alt="Review Site Logo"
          >
        </span>
      </div>

      <div class="self-end py-4 sm:ml-8 flex-grow w-full">
        <p class="text-center sm:text-left font-bold text-xl mb-4">
          {{ form.author }}
        </p>
        <div class="flex justify-between">
          <div class="flex flex-col justify-between">
            <p>
              {{ format(new Date(review.date), 'MMM. d, yyyy') }}
            </p>
            <p class="text-sm">
              ID: {{ form.id }}
            </p>
          </div>

          <div class="flex flex-col items-center">
            <label class="text-base mb-1">Rating</label>
            <star-rating
              border-color="#F3F4F6"
              :rating="review.rating * 5"
              :border-width="1"
              :rounded-corners="true"
              :star-size="16"
              :show-rating="false"
              :read-only="true"
              :increment="0.5"
              :padding="2"
              :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
            />
          </div>

          <div class="flex flex-col items-center">
            <label class="text-base mb-1">Widget</label>
            <vs-switch
              v-model="form.appearOnWidget"
              icon-pack="feather"
              vs-icon-off="icon-eye-off"
              vs-icon-on="icon-eye"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 mt-3">
        <vs-input
          v-model="form.title"
          label="Review Title"
          class="w-full"
        />
      </div>

      <div class="vx-col w-full sm:w-1/2 mt-3">
        <vs-input
          v-model="form.label"
          label="Customer Label"
          class="w-full"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 mt-3">
        <label class="vs-input--label block mt-3">Visible Quote</label>
        <vs-textarea
          v-model="form.visibleText"
          height="6rem"
          class="w-full p-3 mb-0"
        />
      </div>
      <div class="vx-col w-full sm:w-1/2 mt-3">
        <label class="vs-input--label block mt-3">Original Quote</label>
        <vs-textarea
          v-model="form.text"
          height="6rem"
          readonly
          class="w-full p-3 mb-0"
        />
      </div>
    </div>

    <template v-if="!pristine">
      <div class="pt-4 flex justify-end border-t border-gray-300 mt-5">
        <vs-button @click="save">
          Save
        </vs-button>
      </div>
    </template>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import StarRating from 'vue-star-rating';
import { format } from 'date-fns';
import { isPristine } from '@/utils';

export default {
  name: 'RjEditReviewDialog',

  components: { StarRating },

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    review: {
      type: Object,
      required: true,
    },

    reviewSiteLogo: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      pristine: true,
      form: {
        id: this.review.id,
        title: this.review.title || '',
        label: this.review.label || '',
        appearOnWidget: this.review.appearOnWidget,
        text: this.review.text,
        visibleText: this.review.visibleText || this.review.text || '',
      },
      defaultAvatar: require('@/assets/images/pages/default-avatar.png'),
    };
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  created() {
    this.old = { ...this.form };
  },

  methods: {
    format,

    save() {
      this.$emit('saved', this.form);
      this.close();
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      this.pristine = isPristine(current, this.old);
    }, 150),

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
