<template>
  <vs-prompt
    title="ActiveCampaign"
    :active="active"
    :accept-text="acceptBtnText"
    class="rj-medium-modal rj-confirm-modal"
    @accept="connect"
    @close="close"
  >
    <div class="space-y-3">
      <p>Connect your ActiveCampaign account via a webhook to automatically send surveys to contacts.</p>
      <p>Generate a link to copy and paste in your ActiveCampaign Automation.</p>
    </div>

    <div class="vx-row">
      <!-- Location-->
      <div class="vx-col w-full mt-3">
        <label class="vs-input--label">Location*</label>
        <rj-select
          v-model="form.location"
          v-validate="'required'"
          name="Location"
          label="name"
          :options="locations"
          :reduce="(location) => location.id"
          :clearable="false"
          placeholder="Select a location..."
        />
        <span class="text-sm text-red-500">
          {{ errors.first('Location') }}
        </span>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 mt-3">
        <!-- Funnel -->
        <label class="vs-input--label">Funnel*</label>
        <rj-select
          v-model="form.funnel"
          v-validate="'required'"
          name="Funnel"
          label="name"
          :options="funnels"
          :reduce="(funnel) => funnel.id"
          :clearable="false"
          placeholder="Select a funnel..."
        />
        <span class="text-sm text-red-500">
          {{ errors.first('Funnel') }}
        </span>
      </div>

      <div class="vx-col w-full sm:w-1/2 mt-3">
        <label class="vs-input--label">Employee*</label>
        <rj-select
          v-model="form.employee"
          v-validate="'required'"
          name="Employee"
          label="fullName"
          :options="employees"
          :reduce="(user) => user.id"
          :clearable="false"
          placeholder="Select an employee..."
        />
        <span class="text-sm text-red-500">
          {{ errors.first('Employee') }}
        </span>
      </div>
    </div>
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjConnect',

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },

    funnels: {
      type: Array,
      required: true,
    },

    employees: {
      type: Array,
      required: true,
    },

    webhook: {
      type: [Object, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      form: {
        id: null,
        location: null,
        funnel: null,
        employee: null,
      },
    };
  },

  computed: {
    action() {
      return this.webhook === null ? 'create' : 'edit';
    },

    acceptBtnText() {
      return this.action === 'create' ? 'Create' : 'Save';
    },
  },

  watch: {
    action: {
      handler: 'buildFormObject',
    },
  },

  methods: {
    async connect() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) {
        return;
      }

      (this.action === 'create') ? this.create() : this.edit();
    },

    async create() {
      try {
        const companyId = this.$store.getters['companies/company'].id;
        const payload = {
          companyId,
          serviceCode: 'activecampaign',
          userId: this.form.employee,
          locationId: this.form.location,
          surveyTemplateId: this.form.funnel,
        };
        await this.$store.dispatch('companies/createWebhook', payload);
        this.$emit('connected', this.form);
        this.close();
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async edit() {
      try {
        const payload = {
          ...this.webhook,
          userId: this.form.employee,
          locationId: this.form.location,
          surveyTemplateId: this.form.funnel,
        };
        await this.$store.dispatch('companies/updateWebhook', payload);
        this.$emit('connected', this.form);
        this.close();
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
      this.$emit('connected', this.form);
      this.close();
    },

    buildFormObject(action) {
      return (action === 'create') ? this.reset() : this.hydrate();
    },

    hydrate() {
      this.form = {
        id: this.webhook.id,
        funnel: this.webhook.surveyTemplateId,
        location: this.webhook.locationId,
        employee: this.webhook.userId,
      };
    },

    async reset() {
      this.$validator.pause();
      await this.$nextTick();

      for (const key in this.form) {
        if ({}.hasOwnProperty.call(this.form, key)) {
          this.form[key] = null;
        }
      }

      this.$validator.resume();
    },

    close() {
      this.reset();
      this.$emit('update:active', false);
    },
  },
};
</script>
