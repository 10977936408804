<template>
  <div class="widget__rating">
    <div
      class="stars"
      :style="`--rating: ${rating};`"
      :aria-label="`Rating ${rating} out of 5 stars`"
    />
  </div>
</template>

<script>
export default {
  name: 'RjWidgetRating',

  props: {
    rating: {
      type: Number,
      required: true,
    },

    size: {
      type: Number,
      required: false,
      default: 16,
    },
  },
};
</script>
