export default [
  {
    logo: require('@/assets/images/review-sites/FacebookRSB.png'),
    name: 'Facebook',
    code: 'facebook',
    component: 'RjFacebookApp',
    type: 'Social Media Site',
    token: null,
    connected: false,
    managing: false,
  },
  {
    logo: require('@/assets/images/review-sites/GoogleRSB.png'),
    name: 'Google My Business',
    code: 'google',
    component: 'RjGoogleBusinessApp',
    type: 'Social Media Site',
    token: null,
    connected: false,
    managing: false,
  },
  {
    logo: require('@/assets/images/review-sites/ActiveCampaignRSB.png'),
    name: 'ActiveCampaign',
    code: 'activecampaign',
    component: 'RjActiveCampaignApp',
    type: 'CRM',
    token: null,
    connected: false,
    webhooks: [],
    managing: false,
  },
  {
    logo: require('@/assets/images/review-sites/InfusionsoftRSB.png'),
    name: 'Infusionsoft',
    code: 'infusionsoft',
    component: 'RjInfusionsoftApp',
    type: 'CRM',
    accessToken: null,
    appId: '',
    cloudBridgeCredentialsId: null,
    connected: false,
    tagging: false,
    managing: false,
  },
  {
    logo: require('@/assets/images/review-sites/ZapierRSB.png'),
    name: 'Zapier',
    code: 'zapier',
    component: 'RjZapierApp',
    type: 'CRM',
    token: null,
    connected: false,
    managing: false,
  },
  {
    logo: require('@/assets/images/review-sites/revenuejump-logo.png'),
    name: 'RevenueJump',
    code: 'revenuejump',
    component: 'RjRevenueJumpApp',
    type: 'API',
    token: null,
    connected: false,
    managing: false,
  },
];
