<template>
  <section data-company-root>
    <Main
      v-if="!loading"
      :vertical-nav-menu-items="verticalNavMenuItems"
      :horizontal-nav-menu-items="horizontalNavMenuItems"
      :is-admin-or-partner="isAdminOrPartner"
    />

    <component
      :is="csp_modal"
      :props="csp_props"
      :active.sync="csp_show"
    />
  </section>
</template>

<script>
import Main from '@/layouts/main/Main.vue';
import { bus, httpBuildQuery } from '@/utils';
import Company from '@/http/middleware/Company';
import Auth from '@/http/middleware/Authenticate';
import Authorizable from '@/mixins/Authorizable';
import CompanyServiceProvider from '@/views/company-dashboard/CompanyServiceProvider';

export default {
  name: 'RjCompanyDashboard',

  components: { Main },

  mixins: [Authorizable, CompanyServiceProvider],

  middleware: [Auth, Company],

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    horizontalNavMenuItems() {
      const items = [
        {
          url: null,
          component: 'NotificationDropDown',
        },
      ];

      if (
        this.guard_can({ surveys: ['add'] })
        && (this.auth.user.companyId || (this.auth.user.partnerId && this.company.partnerCanSendSurveys))
      ) {
        items.push({
          url: null,
          icon: 'SendIcon',
          color: 'rjPurple',
          title: 'Send Survey',
          action: this.sendSurvey,
        });
      }

      items.push({
        url: '/company/help',
        icon: 'HelpCircleIcon',
        color: 'rjPurple',
        title: 'Help',
        action: null,
      });

      if (this.auth.user.companyId) {
        items.push({
          url: null,
          icon: 'LogOutIcon',
          color: 'rjPurple',
          title: 'Logout',
          action: this.logout,
        });
      }

      return items;
    },

    verticalNavMenuItems() {
      const items = [];

      items.push({
        url: '/company',
        name: 'Home',
        slug: 'company-home',
        icon: 'HomeIcon',
      });

      if (this.guard_can({ surveys: ['add'] })) {
        items.push({
          url: '/company/surveys',
          name: 'Surveys',
          slug: 'company-surveys',
          icon: 'FileTextIcon',
        });
      }

      items.push({
        url: null,
        name: 'Results',
        slug: 'company-results',
        icon: 'TrendingUpIcon',
        submenu: [
          {
            url: '/company/results/testimonials',
            name: 'Testimonials',
            slug: 'company-testimonials',
          },
          {
            url: '/company/results/referrals',
            name: 'Referrals',
            slug: 'company-referrals',
          },
          {
            url: '/company/results/reviews',
            name: 'Reviews',
            slug: 'company-reviews',
          },
          {
            url: '/company/results/negative-feedback',
            name: 'Negative Feedback',
            slug: 'company-negative-feedback',
          },
        ],
      });

      items.push({
        url: null,
        name: 'Integrations',
        slug: 'company-integrations',
        icon: 'LinkIcon',
        submenu: [
          {
            url: '/company/integrations/widgets',
            name: 'Widgets',
            slug: 'company-widgets',
          },
          {
            url: '/company/integrations/applications',
            name: 'Applications',
            slug: 'company-applications',
          },
        ],
      });

      items.push({
        url: null,
        name: 'Settings',
        slug: 'company-settings',
        icon: 'SettingsIcon',
        submenu: [
          {
            url: '/company/details',
            name: 'Company Details',
            slug: 'company-details',
          },
          {
            url: '/company/locations',
            name: 'Locations',
            slug: 'company-locations',
          },
          {
            url: '/company/users',
            name: 'Users',
            slug: 'company-users',
          },
          {
            url: '/company/funnels',
            name: 'Funnels',
            slug: 'company-funnels',
          },
          {
            url: '/company/email-settings',
            name: 'Email Settings',
            slug: 'company-email-settings',
          },
        ],
      });

      items.push({
        url: '/company/help',
        name: 'Help',
        slug: 'company-help',
        icon: 'HelpCircleIcon',
      });

      return items;
    },

    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    isAdminOrPartner() {
      const currentRole = this.$store.getters['auth/currentRole'];
      return /^Admin/.test(currentRole) || /^Partner/.test(currentRole);
    },
  },

  created() {
    this.fetch();
  },

  methods: {
    async fetch() {
      await this.fetchCompany();
      this.loading = false;

      this.$store.dispatch('companies/fetchSmsStatus', this.company.id);
      this.$store.dispatch('revenuejump/getCategories');
      this.$store.dispatch('revenuejump/getReviewSites');
      this.$store.dispatch('revenuejump/getPlanFeatures');
    },

    async fetchCompany() {
      // check for actAsCompanyId in localStorage
      // if present, we are a partner viewing one of our company's
      // dashboards or our In-House company
      let actAsCompanyId;
      try {
        actAsCompanyId = localStorage.getItem('actAsCompanyId');
        localStorage.removeItem('actAsCompanyId');
        actAsCompanyId = parseInt(actAsCompanyId, 10);
        if (!actAsCompanyId) {
          actAsCompanyId = null;
        }
      } catch (error) {
        //
      }

      // are we a company user or a partner/admin user accessing company dashboard?
      const companyId = actAsCompanyId ?? this.auth.user.companyId;

      if (!companyId && this.auth.user.partnerId) {
        return this.$router.replace({
          name: 'partner-home',
        });
      }

      if (!companyId && this.auth.user.adminId) {
        return this.$router.replace({
          name: 'admin-home',
        });
      }

      const filters = httpBuildQuery({
        filter: JSON.stringify({
          include: [
            'plan',
            'mainContact',
            'partner',
            {
              relation: 'companyPlans',
              scope: {
                where: {
                  active: true,
                },
                include: ['plan'],
              },
            },
          ],
        }),
      });

      return this.$store.dispatch('companies/getCompany', { companyId, filters });
    },

    sendSurvey() {
      bus.$emit('show-modal', { modal: 'send-survey' });
    },

    backToDashboard() {
      if (this.auth.user.partnerId) {
        this.$router.replace({
          name: 'partner-home',
        });
      } else if (this.auth.user.adminId) {
        this.$router.replace({
          name: 'admin-home',
        });
      }
    },

    async logout() {
      await this.$store.dispatch('auth/logout');

      return this.$router.replace({ name: 'login' });
    },
  },
};
</script>
