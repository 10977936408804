<template>
  <section>
    <table class="w-full mt-3">
      <!-- Date -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Date
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ format(new Date(survey.createdAt), 'MMM. d, yyyy') }}
        </td>
      </tr>

      <!-- Customer -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Customer
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.contact.firstName }} {{ survey.contact.lastName || '' }}
        </td>
      </tr>

      <!-- Contact Phone -->
      <tr v-if="survey.contact.phone">
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Phone Number
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ formatE164Friendly(survey.contact.phone) }}
        </td>
      </tr>

      <!-- Contact Email -->
      <tr v-if="survey.contact.email">
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Email
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.contact.email }}
        </td>
      </tr>

      <!-- Location -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Location
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.location.name }}
        </td>
      </tr>

      <!-- Employee -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Employee
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.user.firstName }} {{ survey.user.lastName }}
        </td>
      </tr>

      <!-- Sent By -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Sent By
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.sentByUser.firstName }} {{ survey.sentByUser.lastName }}
        </td>
      </tr>

      <!-- Funnel -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Funnel
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.surveyTemplate.name }}
        </td>
      </tr>

      <!-- Goal -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Goal
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ " " }}
        </td>
      </tr>

      <!-- Status -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Status
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.status }}
        </td>
      </tr>

      <!-- Share Method -->
      <tr v-if="survey.type === 'Referral'">
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Offer Share Method
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.selectedOfferShareMethod || '' }}
        </td>
      </tr>

      <!-- Share Method -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Sentiment
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.sentiment === null ? '' : survey.sentiment ? 'Positive' : 'Negative' }}
        </td>
      </tr>

      <!-- Share Method -->
      <tr>
        <td class="w-4/12 py-1 px-2 font-semibold text-right">
          Origin
        </td>
        <td class="w-8/12 py-1 px-2">
          {{ survey.origin }}
        </td>
      </tr>
    </table>

    <div class="pt-4 flex justify-end border-t border-gray-300 mt-5">
      <vs-button
        type="border"
        @click="remove"
      >
        Delete
      </vs-button>

      <vs-button
        class="ml-3"
        @click="confirmResend"
      >
        Resend
      </vs-button>
    </div>
  </section>
</template>

<script>
import { format } from 'date-fns';
import { formatE164Friendly } from '@/utils';

export default {
  name: 'RjSurveyDetailsTab',

  props: {
    survey: {
      type: Object,
      required: true,
    },
  },

  methods: {
    format,
    formatE164Friendly,
    confirmResend() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are You Sure?',
        text: 'To start the survey messages over, click the Yes button below.',
        acceptText: 'Yes',
        accept: this.resend,
      });
    },

    async resend() {
      const {
        contact: {
          firstName,
          lastName,
          email,
          phone,
        },
        locationId,
        sentByUserId,
        surveyTemplateId,
        userId,
      } = this.survey;

      const newSurvey = {
        allowDuplicate: true,
        email: email || null,
        firstName,
        lastName,
        locationId,
        origin: 'Dashboard',
        phone: phone || null,
        sentByUserId,
        surveyTemplateId,
        userId,
      };

      this.$emit('resent', newSurvey);
      this.$emit('close');
    },

    async remove() {
      this.$emit('deleted', this.survey.id);
      this.$emit('close');
    },
  },
};
</script>
