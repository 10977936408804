<template>
  <vx-card
    title="Review Ratings"
    class="h-full"
  >
    <template slot="no-body">
      <vue-apex-charts
        type="pie"
        height="220"
        :options="chart.options"
        :series="chart.series"
      />

      <div>
        <ul class="divide-y divide-gray-400 divide-solid">
          <li
            v-for="(label, index) in labels"
            :key="index"
            class="px-4 py-2 flex items-center justify-between text-sm"
          >
            <div class="flex items-center">
              <span
                class="w-3 h-3 block rounded-full mr-2"
                :style="{background: label.color }"
              />
              <p class="font-bold">
                {{ label.text }}
              </p>
            </div>
            <span>{{ label.amount }}</span>
          </li>
        </ul>
      </div>
    </template>
  </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { colors } from '@/../themeConfig';

export default {
  name: 'RjReviewRatingsChart',

  components: { VueApexCharts },

  props: {
    starRatings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    labels() {
      return [
        { text: '5 Stars', color: colors.rjPurple, amount: this.starRatings[5] },
        { text: '4 Stars', color: colors.rjBlue, amount: this.starRatings[4] },
        { text: '3 Stars', color: colors.rjOrange, amount: this.starRatings[3] },
        { text: '2 Stars', color: colors.rjGreen, amount: this.starRatings[2] },
        { text: '1 Star', color: colors.rjRed, amount: this.starRatings[1] },
      ];
    },

    chart() {
      return {
        series: this.labels.map((label) => label.amount),
        options: {
          labels: this.labels.map((label) => label.text),
          colors: this.labels.map((label) => label.color),
          dataLabels: { enabled: false },
          legend: { show: false },
        },
      };
    },
  },
};
</script>
