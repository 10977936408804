<template>
  <vs-prompt
    :title="notification.title"
    :active="active"
    accept-text="Close"
    class="rj-large-modal rj-confirm-modal"
    @accept="close"
    @close="close"
  >
    <!-- eslint-disable vue/no-v-html -->
    <p v-html="notification.text" />
    <!-- eslint-enable -->
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjLoginNotification',

  props: {
    active: {
      type: Boolean,
      required: true,
    },
    notification: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {

    };
  },

  computed: {
  },

  created() {
  },

  methods: {
    /**
     * Programatic way of closing the modal.
     *
     * @return {void}
     */
    close() {
      const viewedAt = new Date().toISOString();
      const payload = {
        id: this.notification.id,
        viewedAt,
      };
      this.$store.dispatch('notifications/updateNotification', payload);
      this.$store.commit('notifications/SET_LOGIN_NOTIFICATION', null);
      this.$emit('update:active', false);
    },
  },
};


</script>
