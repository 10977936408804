<template>
  <vs-prompt
    title="Edit Testimonial"
    :active="active"
    class="rj-medium-modal rj-confirm-modal"
    :buttons-hidden="true"
    :style="{ zIndex: 52004 }"
    @close="close"
  >
    <div class="flex flex-col sm:flex-row items-center sm:px-8">
      <rj-avatar-uploader
        :avatar="contact.avatar"
        class="w-32 h-32 rounded-full flex-shrink-0"
        @loaded="avatarLoaded"
        @remove="removeAvatar"
      />

      <div class="self-end py-4 sm:ml-8 flex-grow w-full">
        <p class="text-center sm:text-left font-bold text-xl mb-4">
          {{ testimonial.contact.firstName }} {{ testimonial.contact.lastName || '' }}
        </p>
        <div class="flex justify-between">
          <div class="flex flex-col justify-between">
            <p>
              {{ format(new Date(testimonial.pageVisitedAt), 'MMM. d, yyyy') }}
            </p>
            <p class="text-sm">
              ID: {{ testimonial.contact.id }}
            </p>
          </div>

          <div class="flex flex-col items-center">
            <label class="text-base mb-1">Rating</label>
            <feather-icon
              :icon="
                testimonial.sentiment === null
                  ? ''
                  : testimonial.sentiment
                    ? 'ThumbsUpIcon'
                    : 'ThumbsDownIcon'
              "
              svg-classes="w-6 h-6"
              class="block"
              :style="{
                color: testimonial.sentiment ? themeColors.rjGreen : themeColors.rjRed,
              }"
            />
          </div>

          <div class="flex flex-col items-center">
            <label class="text-base mb-1">Widget</label>
            <vs-switch
              v-model="survey.appearOnWidget"
              icon-pack="feather"
              vs-icon-off="icon-eye-off"
              vs-icon-on="icon-eye"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 mt-3">
        <vs-input
          v-model="survey.title"
          label="Testimonial Title"
          class="w-full"
        />
      </div>

      <div class="vx-col w-full sm:w-1/2 mt-3">
        <vs-input
          v-model="contact.label"
          label="Customer Label"
          class="w-full"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 mt-3">
        <label class="vs-input--label block mt-3">Visible Quote</label>
        <vs-textarea
          v-model="survey.visibleFeedback"
          height="6rem"
          class="w-full p-3 mb-0"
        />
      </div>
      <div class="vx-col w-full sm:w-1/2 mt-3">
        <label class="vs-input--label block mt-3">Original Quote</label>
        <vs-textarea
          v-model="survey.feedback"
          height="6rem"
          readonly
          class="w-full p-3 mb-0"
        />
      </div>
    </div>

    <div class="pt-4 flex justify-end border-t border-gray-300 mt-5">
      <vs-button
        type="border"
        @click="remove"
      >
        Delete
      </vs-button>

      <vs-button
        v-if="!pristine"
        class="ml-3"
        @click="save"
      >
        Save
      </vs-button>
    </div>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import { format } from 'date-fns';
import { isPristine } from '@/utils';
import { colors as themeColors } from '@/../themeConfig';
import RjAvatarUploader from '@/components/RjAvatarUploader.vue';

export default {
  name: 'RjEditTestimonialDialog',

  components: { RjAvatarUploader },

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    testimonial: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      survey: {
        id: this.testimonial.id,
        title: this.testimonial.title || '',
        feedback: this.testimonial.feedback || '',
        appearOnWidget: this.testimonial.appearOnWidget,
        visibleFeedback: this.testimonial.visibleFeedback || '',
      },
      contact: {
        id: this.testimonial.contact.id,
        avatar: this.testimonial.contact.avatar,
        label: this.testimonial.contact.label || '',
      },
      oldSurvey: {},
      oldContact: {},
      avatar: null,
      themeColors,
      pristine: true,
    };
  },

  watch: {
    survey: {
      handler: 'hasChanges',
      deep: true,
    },

    contact: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  created() {
    this.oldSurvey = { ...this.survey };
    this.oldContact = { ...this.contact };
  },

  methods: {
    format,

    async save() {
      try {
        await this.$store.dispatch('surveys/updateSurvey', this.survey);

        if (this.avatar) {
          await this.uploadAvatar();
        }

        await this.$store.dispatch('contacts/updateContact', this.contact);

        this.$emit('saved', { survey: this.survey, contact: this.contact });
        this.$vs.notify({ title: 'Success', text: 'Testimonial updated.', color: 'success' });

        this.close();
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async uploadAvatar() {
      const uploadedFilePath = await this.$store.dispatch('contacts/uploadAvatar', {
        id: this.testimonial.contact.id,
        file: this.avatar,
      });

      this.contact.avatar = uploadedFilePath;
    },

    remove() {
      this.$emit('remove', this.testimonial.id);
    },

    avatarLoaded({ file, event }) {
      this.avatar = file;
      this.contact.avatar = event.target.result;
    },

    removeAvatar() {
      this.avatar = null;
      this.contact.avatar = null;
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function() {
      const surveyPristine = isPristine(this.survey, this.oldSurvey);
      const contactPristine = isPristine(this.contact, this.oldContact);

      this.pristine = surveyPristine && contactPristine;
    }, 150),

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
