<template>
  <section>
    <header class="mb-6">
      <h2 class="font-semibold text-gray-600">
        Widgets
      </h2>
    </header>

    <div class="mb-6">
      <vs-button @click="$router.push({ name: 'company-create-widget' })">
        Add Widget
      </vs-button>
    </div>

    <div
      v-for="(row, index) in rows"
      :key="index"
      class="vx-row"
    >
      <div
        v-for="widget in row"
        :key="widget.id"
        class="vx-col w-full sm:w-1/2 lg:w-3/12 mb-base"
      >
        <vx-card class="transform hover:-translate-y-1 cursor-pointer">
          <img
            src="@/assets/images/widget/layouts/single.jpg"
            srcset="@/assets/images/widget/layouts/single.jpg 1x, @/assets/images/widget/layouts/single@2x.jpg 2x"
            :alt="widget.name"
            class="rounded-lg border border-gray-400 mx-auto"
            @click="edit(widget)"
          >
          <div class="my-6">
            <h5 class="mb-2">
              {{ widget.name }}
            </h5>
            <p class="text-gray-500 capitalize">
              {{ widget.type }}
            </p>
          </div>

          <vs-divider />

          <div class="flex items-center justify-between flex-wrap">
            <div class="pl-2">
              <p class="text-xl">
                {{ widget.views }}
              </p>
              <p class="text-gray-500">
                30-Day Views
              </p>
            </div>

            <div
              v-if="action.widgetId !== widget.id"
              class="mt-1"
            >
              <div class="flex">
                <vs-button
                  type="flat"
                  size="small"
                  icon-pack="feather"
                  icon="icon-edit"
                  title="Edit"
                  @click.stop="edit(widget)"
                />
                <vs-button
                  type="flat"
                  size="small"
                  icon-pack="feather"
                  icon="icon-code"
                  title="Embed"
                  @click.stop="copyToClipboard(widget)"
                />
                <vs-button
                  type="flat"
                  size="small"
                  icon-pack="feather"
                  icon="icon-external-link"
                  title="Preview"
                  @click.stop="preview(widget)"
                />
                <vs-button
                  type="flat"
                  size="small"
                  icon-pack="feather"
                  icon="icon-copy"
                  title="Copy"
                  @click.stop="copy(widget)"
                />
                <vs-button
                  type="flat"
                  size="small"
                  icon-pack="feather"
                  icon="icon-slash"
                  title="Delete"
                  @click.stop="removeConfirmation(widget)"
                />
              </div>
            </div>

            <div v-if="action.widgetId === widget.id">
              <span class="block p-2 text-sm text-gray-600">{{ action.message }}</span>
            </div>
          </div>
        </vx-card>
        <!-- eslint-disable vue/no-v-html -->
        <autosize-textarea
          :ref="'code'+ widget.id"
          class="break-all opacity-0 h-0 absolute"
          style="font-family: monospace; z-index: -1;"
          :value="snippetForWidget(widget)"
        />
        <!--eslint-enable-->
      </div>
    </div>

    <rj-widget-previewer
      v-if="previewing"
      :active.sync="previewing"
    />
  </section>
</template>

<script>
import sub from 'date-fns/sub';
import { AutosizeTextarea } from 'vue-autosizer';
import { chunk } from '@/utils';

import RjWidgetPreviewer from '@/views/company-dashboard/integrations/widgets/RjWidgetPreviewer.vue';


export default {
  name: 'RjWidgets',

  components: { AutosizeTextarea, RjWidgetPreviewer },

  data() {
    return {
      previewing: false,
      widgets: [],
      widgetUrl: process.env.VUE_APP_RJ_SOCIAL_PROOF_WIDGET_URL,
      action: {
        widgetId: null,
        message: null,
      },
    };
  },

  computed: {
    rows() {
      return chunk(this.widgets, 4);
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      const thirtyDaysAgo = sub(new Date(), { days: 30 }).toISOString();
      const filter = {
        where: {
          companyId: this.$store.getters['companies/company'].id,
          active: true,
        },
        include: [
          {
            relation: 'views',
            scope: {
              where: {
                viewedAt: { gte: thirtyDaysAgo },
              },
            },
          },
        ],
        order: 'name ASC',
      };
      const widgets = await this.$store.dispatch('widgets/getWidgets', filter);

      this.widgets = widgets.map((w) => ({
        id: w.id,
        name: w.name,
        type: w.designConfig.layout,
        views: new Intl.NumberFormat().format(w.views.length),
      }));
    },

    edit(widget) {
      this.$router.push({
        name: 'company-edit-widget',
        params: { widget: widget.id },
      });
    },

    snippetForWidget(widget) {
      // eslint-disable-next-line
      return `<script src="https://cdn.jsdelivr.net/npm/vue@2.6.14/dist/vue.min.js"><\/script><script src="${process.env.VUE_APP_RJ_SOCIAL_PROOF_WIDGET_URL}" async><\/script><rj-social-proof-widget id=\"${widget.id}\"><\/rj-social-proof-widget>`;
    },

    async copyToClipboard(widget) {
      const snippet = this.snippetForWidget(widget);

      await this.clipboard(snippet, this.$refs[`code${widget.id}`][0]);
      this.$vs.notify({
        title: 'Copied',
        text: 'Your widget code snippet was copied to your clipboard.',
        color: 'success',
      });
    },

    clipboard(text, element) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        return;
      }

      element.$el.select();
      document.execCommand('Copy');
      element.$el.setSelectionRange(0, 0);
    },

    async preview(widget) {
      this.setAction(widget.id, 'Building preview....');
      await this.$store.dispatch('widgets/getWidgetItems', widget.id);

      this.resetAction();
      this.previewing = true;
    },

    async copy(widget) {
      this.setAction(widget.id, 'Copying widget....');

      await this.$store.dispatch('widgets/getWidgetItems', widget.id);
      const w = await this.$store.dispatch('widgets/createWidget', {
        companyId: this.$store.getters['companies/company'].id,
        name: `Copy of ${this.$store.getters['widgets/widget'].name}`,
        designConfig: this.$store.getters['widgets/designConfig'],
        sourceConfig: this.$store.getters['widgets/sourceConfig'],
        enabled: true,
        active: true,
      });

      this.widgets.unshift({
        id: w.id,
        name: w.name,
        type: w.designConfig.layout,
        views: 0,
      });
      this.resetAction();
    },

    removeConfirmation(widget) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are You Sure?',
        text: 'You are about to delete this widget and this action cannot be undone.',
        acceptText: 'Delete',
        accept: () => this.remove(widget),
      });
    },

    async remove(widget) {
      await this.$store.dispatch('widgets/deleteWidget', widget.id);
      await this.fetch();
    },

    resetAction() {
      this.setAction(null, null);
    },

    setAction(widgetId, message) {
      this.action.widgetId = widgetId;
      this.action.message = message;
    },
  },
};
</script>
