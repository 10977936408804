<template>
  <p :class="['widget__quote', size, !readMore ? 'locked' : '']">
    <!-- eslint-disable vue/no-v-html -->
    <span
      ref="quote"
      v-html="quote"
    />
    <!-- eslint-enable -->

    <template v-if="overflow">
      <span
        class="read-more"
        @click.stop="readMore = !readMore"
      >
        {{ readMore ? '[Read Less]' : '[Read More]' }}
      </span>
    </template>
  </p>
</template>

<script>
export default {
  name: 'RjWidgetQuote',

  props: {
    quote: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      required: false,
      default: 'large',
    },
  },

  data() {
    return {
      overflow: true,
      readMore: false,
    };
  },

  mounted() {
    this.checkForHiddenText();
  },

  methods: {
    async checkForHiddenText() {
      await this.$nextTick();
      const { quote } = this.$refs;
      this.overflow = (quote.offsetHeight < quote.scrollHeight) || (quote.offsetWidth < quote.scrollWidth);
    },
  },
};
</script>
