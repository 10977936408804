<template>
  <vs-prompt
    title="Facebook Page Matching"
    :active="active"
    accept-text="Save"
    class="rj-medium-modal rj-confirm-modal"
    @accept="save"
    @close="close"
  >
    <div class="space-y-2">
      <p>You’re connected to Facebook as {{ connectedAs }}!</p>
      <p>Next, match the Facebook page to each location in RevenueJump.</p>
    </div>

    <vs-table
      id="table__location-mapping"
      stripe
      hover-flat
      :data="matches"
      class="my-3 pr-4 border-0 modal-content"
      :no-data-text="'No Locations to match'"
    >
      <template #thead>
        <vs-th class="p-2">
          <span class="uppercase">
            RevenueJump Location
          </span>
        </vs-th>
        <vs-th class="w-64 p-2">
          <span class="uppercase">
            Facebook Page ID
          </span>
        </vs-th>
      </template>

      <template #default="{ data }">
        <vs-tr
          v-for="(location, index) in data"
          :key="index"
        >
          <vs-td>
            <span>{{ location.name }}</span>
          </vs-td>
          <vs-td>
            <rj-select
              v-model="location.facebookPageId"
              :options="facebookPages"
              :reduce="(page) => page.id"
              :clearable="false"
              placeholder="@FacebookPageID"
              class="w-64"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjMatchLocations',

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    facebookPages: {
      type: Array,
      required: true,
    },

    connectedAs: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      matches: [],
    };
  },

  computed: {
    locations() {
      return this.$store.getters['locations/locations'];
    },
  },

  watch: {
    locations: {
      handler: 'buildMatchesArray',
      immediate: true,
    },
  },

  methods: {
    async save() {
      const updateLocation = async(location) => {
        const exchangedToken = await this.$store.dispatch('locations/exchangeFbToken', { pageId: location.payload.facebookPageId, userToken: location.payload.facebookAccessToken });
        await this.$store.dispatch('locations/updateLocation', {
          locationId: location.locationId,
          payload: {
            ...location.payload,
            facebookAccessToken: exchangedToken,
          },
        });
      };

      const updates = this.matches.filter((m) => m.facebookPageId).map((location) => {
        const payload = {
          locationId: location.id,
          payload: {
            facebookPageId: location.facebookPageId,
            facebookAccessToken: this.facebookPages.find((page) => page.id === location.facebookPageId).accessToken,
          },
        };
        return updateLocation(payload);
      });

      try {
        await Promise.all(updates);
        this.$emit('matched');
        this.close();
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    buildMatchesArray(locations) {
      this.matches = locations.map((location) => ({
        id: location.id,
        name: location.name,
        facebookPageId: location.facebookPageId,
      }));
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
<style scoped>
.modal-content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
