<template>
  <section>
    <div
      ref="swiper"
      class="swiper-container"
    >
      <div class="swiper-wrapper">
        <template v-if="isText || isTextAndEmail">
          <div class="swiper-slide">
            <rj-phone-message-screen :sms="message.text" />
          </div>
        </template>

        <template v-if="isEmail || isTextAndEmail">
          <div class="swiper-slide">
            <rj-phone-email-screen
              :message="message"
              :survey="survey"
            />
          </div>
        </template>
      </div>
    </div>

    <template v-if="isTextAndEmail">
      <rj-phone-switch-button
        :slider="swiper"
        :selected="tab"
        @slide-to="(index) => tab = index"
      />
    </template>

    <div class="pt-4 flex flex-col-reverse sm:flex-row items-center justify-between border-t border-gray-300 mt-5">
      <p class="my-2 text-xs sm:text-sm">
        {{ message.type }} sent on <span class="font-semibold"> {{ formatToTimeZone(new Date(message.createdAt), 'MMM D, YYYY @ h:mma z', { timeZone: survey.location.timezone }) }}</span>
      </p>
      <!-- Only show if all messages have NOT been sent yet -->
      <vs-button
        @click="stopMessages"
      >
        Opt-Out
      </vs-button>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper';
import { format } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';
import RjPhoneMessageScreen from '@/views/common/funnels/smartphone/RjPhoneMessageScreen.vue';
import RjPhoneEmailScreen from '@/views/common/funnels/smartphone/RjPhoneEmailScreen.vue';
import RjPhoneSwitchButton from '@/views/common/funnels/partials/RjPhoneSwitchButton.vue';

export default {
  name: 'RjSurveyMessageTab',

  components: {
    RjPhoneEmailScreen,
    RjPhoneSwitchButton,
    RjPhoneMessageScreen,
  },

  props: {
    survey: {
      type: Object,
      required: true,
    },

    message: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tab: 0,
      swiper: null,
    };
  },

  computed: {
    isText() {
      return this.message.type === 'Text';
    },

    isEmail() {
      return this.message.type === 'Email';
    },

    isTextAndEmail() {
      return this.message.type === 'TextEmail';
    },

    hasScheduledMessages() {
      return this.survey.surveyMessageSchedule.length;
    },
  },

  async mounted() {
    await this.$nextTick();
    this.swiper = new Swiper(this.$refs.swiper);
  },

  beforeDestroy() {
    this.swiper.destroy();
    this.swiper = null;
  },

  methods: {
    format,
    formatToTimeZone,

    async stopMessages() {
      this.$emit('stopped', this.survey);
    },
  },
};
</script>
