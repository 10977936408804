<template>
  <section>
    <template v-if="loading">
      <p>Loading...</p>
    </template>

    <template v-else>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-8/12">
          <rj-company-form :company="company" />
        </div>

        <div class="vx-col w-full lg:w-4/12">
          <rj-company-overview
            :company="company"
            class="mb-6"
          />

          <rj-company-logo :company="company" />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import RjCompanyLogo from '@/views/common/companies/RjCompanyLogo.vue';
import RjCompanyForm from '@/views/common/companies/RjCompanyForm.vue';
import RjCompanyOverview from '@/views/common/companies/RjCompanyOverview.vue';

export default {
  name: 'RjCompanyDetails',

  components: {
    RjCompanyLogo,
    RjCompanyForm,
    RjCompanyOverview,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    company() {
      return this.$store.getters['companies/company'];
    },
  },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      await this.$store.dispatch('companies/withCounts', this.company.id);
      this.$store.dispatch('companies/fetchSmsStatus', this.company.id);

      this.loading = false;
    },
  },
};
</script>
