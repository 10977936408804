import RjGridLayoutRender from '@/views/company-dashboard/integrations/widgets/previewer/layouts/RjGridLayoutRender.vue';
import RjCornerLayoutRender from '@/views/company-dashboard/integrations/widgets/previewer/layouts/RjCornerLayoutRender.vue';
import RjSingleLayoutRender from '@/views/company-dashboard/integrations/widgets/previewer/layouts/RjSingleLayoutRender.vue';
import RjCarouselLayoutRender from '@/views/company-dashboard/integrations/widgets/previewer/layouts/RjCarouselLayoutRender.vue';

export default {
  RjGridLayoutRender,
  RjSingleLayoutRender,
  RjCornerLayoutRender,
  RjCarouselLayoutRender,
};
