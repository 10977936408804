<template>
  <div class="excel-import">
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      accept=".xlsx, .xls, .csv"
      @change="handleClick"
    >
    <div
      class="flex flex-col flex-1 items-center px-6 py-8 cursor-pointer text-center border-2 border-dashed border-gray-400 bg-gray-200 rounded-lg"
      :class="{ 'opacity-75 cursor-not-allowed' : disabled }"
      @click="(e) => disabled ? e.preventDefault() : $refs.fileInput.click()"
      @drop="handleDrop"
      @dragover="handleDragover"
      @dragenter="handleDragover"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';

export default {
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      excelData: {
        header: null,
        results: null,
        meta: null,
      },
    };
  },
  methods: {
    generateData({ header, results, meta }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.excelData.meta = meta;
      if (this.onSuccess) {
        this.onSuccess(this.excelData);
      }
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let C;
      const R = range.s.r;
      // Start in the first row.
      for (C = range.s.c; C <= range.e.c; ++C) { // Walk every column in the range.
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        // Find the cell in the first row.
        let hdr = `UNKNOWN ${C}`; // Replace with your desired default.
        if (cell && cell.t) {
          hdr = XLSX.utils.format_cell(cell);
        }
        headers.push(hdr);
      }
      return headers;
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });
        return;
      }
      const rawFile = files[0]; // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .xlsx, .xls, .csv suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });
        return;
      }
      this.uploadFile(rawFile);
    },
    readerData(rawFile) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet, { blankrows: true });
          const meta = { sheetName: firstSheetName };
          this.generateData({ header, results, meta });
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    handleClick(e) {
      if (this.disabled) {
        e.preventDefault();
        return;
      }

      const { files } = e.target;
      const rawFile = files[0];
      if (!rawFile) {
        return;
      }
      this.uploadFile(rawFile);
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    },
    uploadFile(file) {
      this.$refs.fileInput.value = null; // Fix not being able to select the same.
      this.readerData(file);
    },

  },
};
</script>
