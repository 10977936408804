<template>
  <rj-base-widget-builder>
    <template #design="{ designConfig, update }">
      <div class="flex flex-wrap items-end mt-2 sm:mt-0">
        <!-- Small -->
        <div class="flex flex-col items-center py-3 w-1/2 sm:w-auto sm:mr-16">
          <label for="small">
            <img
              src="@/assets/images/widget/design/wide.jpg"
              srcset="@/assets/images/widget/design/wide.jpg 1x, @/assets/images/widget/design/wide@2x.jpg 2x"
              alt="Widget Small Layout"
              class="cursor-pointer w-48 sm:w-auto"
            >
          </label>
          <vs-radio
            id="small"
            :value="designConfig.design"
            vs-value="small"
            vs-name="design"
            class="text-base mt-1"
            @input="(value) => update('designConfig', 'design', value)"
          >
            Small
          </vs-radio>
        </div>
      </div>
    </template>

    <template #settings="{ designConfig, update }">
      <div class="flex flex-wrap flex-col sm:flex-row sm:space-x-10">
        <!-- Branding -->
        <div class="mt-4">
          <label class="vs-input--label">RevenueJump Branding</label>
          <vs-switch
            :value="designConfig.branding"
            class="mt-2"
            @input="(value) => update('designConfig', 'branding', value)"
          >
            <span slot="on">SHOW</span>
            <span slot="off">HIDE</span>
          </vs-switch>
        </div>

        <!-- Mobile -->
        <div class="mt-4">
          <label class="vs-input--label">Display On Mobile</label>
          <vs-switch
            :value="designConfig.mobile"
            class="mt-2"
            @input="(value) => update('designConfig', 'mobile', value)"
          >
            <span slot="on">SHOW</span>
            <span slot="off">HIDE</span>
          </vs-switch>
        </div>
        <!-- Show/Hide Company Location -->
        <div class="mt-4">
          <label class="vs-input--label">Display Location</label>
          <vs-switch
            :value="designConfig.displayLocation"
            class="mt-2"
            @input="(value) => update('designConfig', 'displayLocation', value)"
          >
            <span slot="on">SHOW</span>
            <span slot="off">HIDE</span>
          </vs-switch>
        </div>
      </div>

      <div class="flex flex-wrap flex-col sm:flex-row sm:space-x-10">
        <!-- display time -->
        <div class="mt-4 w-full relative sm:w-64">
          <span class="absolute right-0 top-0 mt-8 text-sm z-40 mr-3 bg-white">ms</span>
          <vs-input
            :value="designConfig.displayTime"
            name="displayTime"
            label="Display Time"
            class="w-full"
            @input="(value) => update('designConfig', 'displayTime', parseInt(value))"
          />
        </div>

        <!-- break time -->
        <div class="mt-4 w-full relative sm:w-64">
          <span class="absolute right-0 top-0 mt-8 text-sm z-40 mr-3 bg-white">ms</span>
          <vs-input
            :value="designConfig.delay"
            name="delay"
            label="Break Time"
            class="w-full"
            @input="(value) => update('designConfig', 'delay', parseInt(value))"
          />
        </div>

        <div class="mt-4 w-full relative sm:w-64">
          <label class="vs-input--label">Lower Corner Placement</label>
          <div class="mt-3 space-x-6">
            <vs-radio
              :value="designConfig.position"
              vs-value="left"
              vs-name="position"
              class="text-base"
              @input="(value) => update('designConfig', 'position', value)"
            >
              Left
            </vs-radio>
            <vs-radio
              :value="designConfig.position"
              vs-value="right"
              vs-name="position"
              class="text-base"
              @input="(value) => update('designConfig', 'position', value)"
            >
              Right
            </vs-radio>
          </div>
        </div>
      </div>
    </template>
  </rj-base-widget-builder>
</template>

<script>
import RjBaseWidgetBuilder from '@/views/company-dashboard/integrations/widgets/builder/RjBaseWidgetBuilder.vue';

export default {
  name: 'RjCornerWidgetBuilder',

  components: { RjBaseWidgetBuilder },

  mounted() {
    if (this.$route.name === 'company-create-widget') {
      this.$store.commit('widgets/SET_DEFAULT_DESIGN_CONFIG', { design: 'small' });
    }
  },
};
</script>
