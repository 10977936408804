<template>
  <section>
    <p>
      Send up to 500 surveys by uploading a CSV spreadsheet of contacts.
    </p>

    <form @submit.prevent="send">
      <div v-show="isHighJump">
        <div
          v-if="locations.length > 1"
          class="mt-3"
        >
          <label class="vs-input--label">Location*</label>
          <rj-select
            v-model="form.location"
            v-validate="'required'"
            label="name"
            name="location"
            :options="locations"
            :reduce="(location) => location.id"
            :clearable="false"
            placeholder="Select a location..."
          />
          <span class="text-danger text-sm">
            {{ errors.first('location') }}
          </span>
        </div>

        <div class="vx-row">
          <!-- Funnel -->
          <div
            v-show="funnels.length > 1"
            class="vx-col w-full mt-3"
            :class="{ 'sm:w-1/2': funnels.length > 1 }"
          >
            <label class="vs-input--label">Funnel*</label>
            <rj-select
              v-model="form.funnel"
              v-validate="'required'"
              name="funnel"
              label="name"
              :options="funnels"
              :reduce="(funnel) => funnel.id"
              :clearable="false"
              placeholder="Select a funnel..."
            />
            <span class="text-danger text-sm">
              {{ errors.first('funnel') }}
            </span>
          </div>

          <!-- Employee -->
          <div
            v-show="employees.length > 1"
            class="vx-col w-full mt-3"
            :class="{ 'sm:w-1/2': employees.length > 1 }"
          >
            <label class="vs-input--label">Employee*</label>
            <rj-select
              v-model="form.employee"
              v-validate="'required'"
              name="employee"
              :options="employees"
              :reduce="(employee) => employee.id"
              :clearable="false"
              placeholder="Select a employee..."
            />
            <span class="text-danger text-sm">
              {{ errors.first('employee') }}
            </span>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <import-excel
          :on-success="readFromCSV"
          :disabled="isLowJump"
        >
          <div
            v-if="!csv.loaded"
            class="flex flex-col items-center"
          >
            <feather-icon
              icon="UploadCloudIcon"
              svg-classes="h-10 w-10 stroke-current text-gray-600"
              class="block"
            />
            <div class="mt-2 text-gray-700">
              <span>Drop CSV File or </span>
              <span :class="[isHighJump ? 'text-primary hover:underline' : 'text-gray-700']">
                Browse
              </span>
            </div>
          </div>

          <div v-else>
            <p>{{ csv.contents.length }} {{ csv.contents.length === 1 ? 'contact' : 'contacts' }} found!</p>
            <p v-if="csv.doNotContactDuplicates.length">
              {{ csv.doNotContactDuplicates.length }} {{ csv.doNotContactDuplicates.length === 1 ? 'contact does' : 'contacts do' }} not wish to be contacted.
            </p>
          </div>
        </import-excel>
      </div>

      <div class="mt-4">
        <vs-checkbox
          v-model="form.permission"
          name="contact permission"
          :disabled="isLowJump"
        >
          I have permission to contact each of these people.
        </vs-checkbox>
      </div>

      <template v-if="isValid">
        <div class="pt-4 flex justify-end border-t border-gray-300 mt-5">
          <vs-button button="submit">
            {{ sending ? 'Sending...' : 'Send' }}
          </vs-button>
        </div>
      </template>
    </form>

    <rj-csv-fields-mapper-dialog
      ref="mapper"
      :headers="csv.headers"
      :active.sync="csv.mappingModalOpen"
      @success="onMappingSuccess"
    />

    <rj-csv-error-dialog
      :csv-errors="csv.errors"
      :too-big="csv.tooBig"
      :active.sync="csv.errorModalOpen"
      @start-over="startOver"
      @send-valid="removeErrors"
    />

    <rj-csv-duplicates-dialog
      :duplicates="csv.duplicates"
      :active.sync="csv.duplicateModalOpen"
      @approve-duplicates="approveDuplicates"
    />
  </section>
</template>

<script>
import { bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import ImportExcel from '@/components/excel/ImportExcel.vue';
import RjCsvErrorDialog from '@/views/company-dashboard/surveys/modals/RjCsvErrorDialog.vue';
import RjCsvDuplicatesDialog from '@/views/company-dashboard/surveys/modals/RjCsvDuplicatesDialog.vue';
import RjCsvFieldsMapperDialog from '@/views/company-dashboard/surveys/modals/RjCsvFieldsMapperDialog.vue';

export default {
  name: 'RjSendBulkSurveysTab',

  components: {
    ImportExcel,
    RjCsvErrorDialog,
    RjCsvDuplicatesDialog,
    RjCsvFieldsMapperDialog,
  },

  mixins: [Authorizable],

  props: {
    company: {
      type: Object,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },

    funnels: {
      type: Array,
      required: true,
    },

    employees: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      form: {
        location: null,
        employee: null,
        funnel: null,
        permission: true,
      },
      csv: {
        loaded: false,
        ready: false,
        mappingModalOpen: false,
        mapping: null,
        errorModalOpen: false,
        duplicateModalOpen: false,
        headers: [],
        contents: [],
        valids: [],
        errors: [],
        duplicates: [],
        doNotContactDuplicates: [],
        tooBig: false,
      },
      sending: false,
    };
  },

  computed: {
    isValid() {
      let requiredFields = true;
      if (Object.keys(this.fields).length) {
        requiredFields = Object.keys(this.fields).every((key) => this.fields[key].valid);
      }

      return requiredFields && this.csv.ready;
    },
    mappedRows() {
      return this.csv.contents.map((row) => {
        const obj = {};
        Object.keys(this.csv.mapping).forEach((key) => {
          obj[key] = row[this.csv.mapping[key]]?.toString() ?? '';
        });
        obj.locationId = this.form.location;
        return obj;
      });
    },
  },

  watch: {
    'form.location': function() {
      if (this.csv.mapping && this.form.location) {
        this.validateMappedRows();
      }
    },
    'csv.mapping': function() {
      if (this.csv.mapping && this.form.location) {
        this.validateMappedRows();
      }
    },
  },

  mounted() {
    if (this.isLowJump) {
      bus.$emit('upgrade-company');
    }

    this.setFormDefaults();
  },

  methods: {
    setFormDefaults() {
      this.form.funnel = (this.funnels.length === 1) ? this.funnels[0].id : null;
      this.form.location = (this.locations.length === 1) ? this.locations[0].id : null;
      this.form.employee = (this.employees.length === 1) ? this.employees[0].id : null;
    },

    readFromCSV({ header, results }) {
      // Reset the mapping.
      this.$refs.mapper.reset();
      this.csv.mapping = null;
      this.csv.mappingModalOpen = true;

      this.csv.loaded = true;

      this.csv.headers = header;
      this.csv.contents = results.map((row, index) => ({ ...row, rowIndex: index })).slice(0, 500);
      if (results.length > 500) {
        this.csv.tooBig = true;
      }
    },
    setMappedFields(fields) {
      this.form.fields = fields;
    },
    // Mapping rows
    onMappingSuccess(mapping) {
      if (mapping.phone !== null) {
        this.$emit('check-sms-limit');
      }

      this.csv.mapping = mapping;
    },
    openduplicate() {
      this.csv.duplicates = [
        {
          index: 0,
          firstName: 'Garen',
          lastName: 'Tyler',
        },
        {
          index: 1,
          firstName: 'El',
          lastName: 'Chino',
        },
        {
          index: 2,
          firstName: 'Jaren',
          lastName: 'Tiller',
        },
      ];
      this.csv.duplicateModalOpen = true;
    },
    async validateMappedRows() {
      this.$vs.notify({
        title: 'Validating Surveys',
        text: 'Please wait..',
        color: 'success',
      });
      // Validate using the api.
      const response = await this.$store.dispatch('surveys/validateSurveyCsv', this.mappedRows);
      if (!response.success) {
        // Assumes that the errors are in the form of "7 - Missing required contact information: First Name = \"missing phone and email\", Last Name = \"lname\", Phone = \"\", Email = \"\""
        /* eslint-disable */
        const { errorMessages = [] } = response;
        this.csv.errors = errorMessages.map((err) => {
          const { message, index } = err;
          return {
            original: message,
            index,
            error: message,
          };
        });
        /* eslint-enable */
        this.csv.errorModalOpen = true;
      } else {
        this.csv.duplicates = response.duplicates.map((d, i) => ({ ...d, index: i }));
        this.csv.valids = response.originals;
        this.csv.doNotContactDuplicates = response.doNotContactDuplicates;
        if (this.csv.duplicates.length !== 0) {
          this.csv.duplicateModalOpen = true;
        } else {
          this.csv.ready = true;
        }
      }
    },
    approveDuplicates(duplicates) {
      this.csv.valids = [
        ...this.csv.valids,
        ...duplicates.map((duplicate) => {
          this.csv.duplicates = this.csv.duplicates.filter((d) => d.index !== duplicate.index);
          delete duplicate.index; /* eslint-disable-line no-param-reassign */
          return duplicate;
        }),
      ];
      this.csv.ready = true;
    },
    // Errors
    startOver() {
      this.csv = {
        loaded: false,
        ready: false,
        mappingModalOpen: false,
        mapping: null,
        errorModalOpen: false,
        duplicateModalOpen: false,
        headers: [],
        contents: [],
        valids: [],
        errors: [],
        duplicates: [],
        doNotContactDuplicates: [],
        tooBig: false,
      };
    },
    removeErrors(errorRows) {
      errorRows.sort((a, b) => b - a).forEach((index) => {
        this.csv.contents.splice(index, 1);
      });
      this.validateMappedRows();
    },
    // Send the surveys
    async send() {
      if (this.sending) {
        return;
      }

      try {
        if (!this.csv.valids.length) {
          this.$vs.notify({ title: 'Warning', text: 'No surveys were sent.', color: 'success' });
          return;
        }

        const surveys = this.csv.valids.map((c) => ({
          allowDuplicate: true,
          email: c.email,
          firstName: c.firstName,
          lastName: c.lastName,
          locationId: this.form.location,
          origin: 'CSV',
          phone: c.phone,
          surveyTemplateId: this.form.funnel,
          type: this.funnels.find((f) => f.id === this.form.funnel)?.type ?? '',
          userId: this.form.employee,
        }));

        this.sending = true;

        await this.$store.dispatch('surveys/sendSurveys', surveys);

        bus.$emit('surveys-sent');

        this.$vs.notify({
          title: 'Success', text: 'Surveys queued.', color: 'success', time: 3000,
        });
      } catch (error) {
        this.$vs.notify({
          title: 'Error', text: error.message || 'Something went wrong.', color: 'danger', time: 3000,
        });
      } finally {
        this.sending = false;
      }
      this.$emit('close');
    },
  },
};
</script>
