<template>
  <section v-if="loading">
    Loading...
  </section>

  <section v-else>
    <header class="flex flex-wrap items-center mb-6">
      <h2 class="pr-3 font-semibold text-gray-600">
        Surveys
      </h2>
    </header>

    <div class="rv-default-data-list data-list-container">
      <vs-table
        id="rj-default-table"
        ref="table"
        :data="surveys"
        :max-items="itemsPerPage"
        :sst="true"
        :total="itemsCount"
        pagination
        search
        :no-data-text="noDataText"
        @search="onSearch"
        @change-page="onChangePage"
        @selected="(survey) => openSurveyDetails(survey)"
      >
        <template slot="header">
          <div class="flex sm:hidden">
            <vs-button
              type="border"
              icon-pack="feather"
              icon="icon-filter"
              class="mr-4"
              @click="filtering = true"
            />
          </div>

          <div class="hidden sm:flex items-center flex-grow justify-between">
            <div class="flex items-center data-list-btn-container space-x-4">
              <vs-button
                v-if="canSendSurveys"
                @click="sendSurvey"
              >
                Send Survey
              </vs-button>

              <vs-button
                type="border"
                @click="filtering = true"
              >
                Filter
              </vs-button>

              <vs-button
                v-if="hasFilters || searchText"
                type="flat"
                class="px-3"
                @click="resetFilters"
              >
                Reset Filters
              </vs-button>

              <vs-button
                v-else
                type="border"
                @click="resetFilters"
              >
                Refresh
              </vs-button>
            </div>

            <vs-dropdown
              v-if="itemsCount"
              vs-trigger-click
              class="cursor-pointer mr-4 items-per-page-handler"
            >
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                  {{
                    itemsCount - currentPage * itemsPerPage > 0
                      ? currentPage * itemsPerPage
                      : itemsCount
                  }}
                  of {{ itemsCount }}
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  svg-classes="h-4 w-4"
                />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item @click="onItemsPerPage(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="onItemsPerPage(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="onItemsPerPage(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="onItemsPerPage(100)">
                  <span>100</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </template>

        <template slot="thead">
          <vs-th>
            Customer
          </vs-th>
          <vs-th>
            Date
          </vs-th>
          <vs-th>
            Location
          </vs-th>
          <vs-th>
            Employee
          </vs-th>
          <vs-th>
            Funnel Name
          </vs-th>
          <vs-th>
            Funnel Goal
          </vs-th>
          <vs-th>
            Status
          </vs-th>
          <vs-th>
            <span class="w-full text-center uppercase">
              Sentiment
            </span>
          </vs-th>
          <vs-th>
            Action
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr
              v-for="survey in data"
              :key="survey.id"
              :data="survey"
            >
              <vs-td class="whitespace-nowrap">
                {{ survey.contact.firstName }}
                {{ survey.contact.lastName || '' }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ format(new Date(survey.createdAt), 'MMM. d, yyyy') }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ survey.location.name }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ survey.user.firstName }} {{ survey.user.lastName }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ survey.surveyTemplate.name }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ survey.type }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                {{ survey.status }}
              </vs-td>
              <vs-td class="whitespace-nowrap">
                <div class="flex items-center justify-center">
                  <feather-icon
                    :icon="
                      survey.sentiment === null
                        ? ''
                        : survey.sentiment
                          ? 'ThumbsUpIcon'
                          : 'ThumbsDownIcon'
                    "
                    svg-classes="w-6 h-6"
                    class="inline-block"
                    :style="{
                      color: survey.sentiment
                        ? themeColors.rjGreen
                        : themeColors.rjRed,
                    }"
                  />
                </div>
              </vs-td>
              <vs-td class="whitespace-nowrap">
                <div class="flex">
                  <vs-button
                    icon-pack="feather"
                    icon="icon-external-link"
                    type="flat"
                    class="inline-block"
                    title="Open Survey Details"
                    :color="themeColors.rjPurple"
                    @click.stop="openSurveyDetails(survey)"
                  />
                  <vs-button
                    icon-pack="feather"
                    icon="icon-slash"
                    type="flat"
                    class="inline-block"
                    title="Delete Survey"
                    :color="themeColors.rjPurple"
                    @click.stop="deleteConfirmation(survey.id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>

    <rj-filter-surveys-dialog
      v-if="filtering"
      :active.sync="filtering"
      :applied-filters="filters"
      @apply-filters="applyFilters"
      @reset-filters="resetFilters"
    />

    <rj-survey-details-dialog
      v-if="details"
      :survey="selected"
      :active.sync="details"
      @deleted="deleteConfirmation"
      @resent="resendSurvey"
      @stopped="stopSurvey"
    />
  </section>
</template>

<script>
import { format, sub } from 'date-fns';
import _debounce from 'lodash/debounce';
import { bus, httpBuildQuery } from '@/utils';
import { colors as themeColors } from '@/../themeConfig';
import filters from '@/views/company-dashboard/surveys/filters';
import RjFilterSurveysDialog from '@/views/company-dashboard/surveys/modals/RjFilterSurveysDialog.vue';
import RjSurveyDetailsDialog from '@/views/company-dashboard/surveys/modals/RjSurveyDetailsDialog.vue';

export default {
  name: 'RjSurveys',

  components: {
    RjFilterSurveysDialog,
    RjSurveyDetailsDialog,
  },

  data() {
    return {
      themeColors,
      selected: null,
      currentPage: 1,
      details: false,
      itemsPerPage: 20,
      itemsCount: 0,
      isMounted: false,
      filtering: false,
      filters: {},
      searchText: '',
      surveys: [],
      loading: true,
      hasFilters: false,
      fetching: false,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.surveys.length;
    },

    canSendSurveys() {
      return this.auth.user.companyId || (this.auth.user.partnerId && this.company.partnerCanSendSurveys);
    },

    noDataText() {
      if (this.loading) {
        return 'Loading...';
      }
      if (this.fetching) {
        return 'Searching...';
      }
      return 'No surveys found for current filter.';
    },
  },

  async mounted() {
    // refresh surveys when survey(s) sent
    bus.$on('surveys-sent', () => {
      this.fetchSurveys();
    });

    this.filters = filters();
    this.isMounted = true;

    await this.fetchData();
    this.loading = false;

    this.fetchSurveys();
    this.fetchFunnels();
  },

  methods: {
    format,

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    onSearch: _debounce(function(text) {
      this.searchText = text;
      this.$refs.table.currentx = 1;
      this.fetchSurveys();
    }, 250),

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    onChangePage: _debounce(function(page) {
      this.currentPage = page;
      this.fetchSurveys();
    }, 250),

    onItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.$refs.table.currentx = 1;
      this.fetchSurveys();
    },

    async fetchData() {
      const companyId = this.$store.getters['companies/company'].id;

      const getLocations = this.$store.dispatch('locations/fetchLocations', httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { active: true, companyId },
        }),
      }));

      const getUsers = this.$store.dispatch('users/fetchUsers', httpBuildQuery({
        filter: JSON.stringify({
          where: { active: true, companyId },
          include: [
            {
              relation: 'roleMappings',
              scope: { include: ['role'] },
            },
          ],
        }),
      }));

      await Promise.all([getLocations, getUsers]);
    },

    async fetchSurveys() {
      this.fetching = true;

      const {
        startDate,
        endDate,
        location,
        employee,
        funnel,
        goal,
        status,
        sentiment,
      } = this.filters;

      const filter = {
        where: {
          companyId: this.$store.getters['companies/company'].id,
          active: true,
        },
        include: [
          'contact',
          'surveyMessageSchedule',
          {
            relation: 'location',
            scope: {
              pending: false,
              fields: ['name', 'timezone'],
            },
          },
          {
            relation: 'user',
            scope: {
              fields: ['firstName', 'lastName'],
              where: {
                active: { inq: [true, false] },
              },
            },
          },
          {
            relation: 'sentByUser',
            scope: {
              fields: ['firstName', 'lastName'],
              where: {
                active: { inq: [true, false] },
              },
            },
          },
          {
            relation: 'surveyTemplate',
            scope: {
              fields: ['name'],
            },
          },
          {
            relation: 'surveyMessages',
            scope: {
              fields: ['createdAt', 'step', 'text', 'type'],
            },
          },
        ],
      };

      if (startDate && endDate) {
        filter.where.createdAt = {
          between: [startDate.toISOString(), endDate.toISOString()],
        };
      } else if (startDate) {
        filter.where.createdAt = {
          gte: startDate.toISOString(),
        };
      } else if (endDate) {
        filter.where.createdAt = {
          lte: endDate.toISOString(),
        };
      }

      if (location) {
        filter.where.locationId = location;
      }

      if (employee) {
        filter.where.userId = employee;
      }

      if (funnel) {
        filter.where.surveyTemplateId = funnel;
      }

      if (goal) {
        filter.where.type = goal;
      }

      if (status) {
        filter.where.status = status;
      }

      if (typeof sentiment === 'number') {
        filter.where.sentiment = sentiment;
      }

      const limit = this.itemsPerPage;
      const skip = this.itemsPerPage * (this.currentPage - 1);
      const order = 'id DESC';

      if (/\w+/.test(this.searchText)) {
        const { count, surveys } = await this.$store.dispatch('surveys/searchSurveys', {
          searchText: this.searchText, where: filter.where, limit, skip, order,
        });

        this.itemsCount = count;

        if (count > 0) {
          surveys.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          this.surveys = surveys;
        } else {
          this.surveys = [];
        }
        this.fetching = false;
        return;
      }

      const { count } = await this.$store.dispatch('surveys/countSurveys', filter.where);

      this.itemsCount = count;

      let surveys = [];

      if (count > 0) {
        surveys = await this.$store.dispatch('surveys/getSurveys', {
          ...filter, limit, skip, order,
        });
        surveys.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      }

      this.surveys = surveys;
      this.fetching = false;
    },

    async deleteSurvey(id) {
      try {
        await this.$store.dispatch('surveys/deleteSurvey', id);
        const index = this.surveys.findIndex((s) => s.id === id);
        if (index < 0) {
          return;
        }
        this.surveys.splice(index, 1);
        this.$vs.notify({
          title: 'Success',
          text: 'Survey deleted.',
          color: 'success',
        });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async resendSurvey(survey) {
      try {
        await this.$store.dispatch('surveys/sendSurvey', survey);
        this.$vs.notify({
          title: 'Success',
          text: 'Survey sent.',
          color: 'success',
        });
        this.fetchSurveys();
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async stopSurvey(survey) {
      try {
        await this.$store.dispatch('surveys/deleteScheduledMessages', survey.surveyMessageSchedule);
        this.$store.dispatch('contacts/updateContact', { id: survey.contactId, doNotContact: true });
        this.$store.dispatch('surveys/updateSurvey', { id: survey.id, status: 'Stopped' });
        const index = this.surveys.findIndex((s) => s.id === survey.id);
        this.surveys.splice(index, 1, { ...survey, surveyMessageSchedule: [], status: 'Stopped' });

        this.$vs.notify({
          title: 'Success',
          text: 'Survey messages stopped.',
          color: 'success',
        });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async fetchFunnels() {
      const companyId = this.$store.getters['companies/company'].id;
      await this.$store.dispatch('funnels/fetchFunnels', httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: {
            companyId,
          },
        }),
      }));
    },

    sendSurvey() {
      bus.$emit('show-modal', { modal: 'send-survey' });
    },

    openSurveyDetails(survey) {
      const surveyTemplate = this.$store.getters[
        'funnels/funnels'
      ].find((st) => st.id === survey.surveyTemplate.id);
      this.selected = {
        ...survey,
        surveyTemplate,
      };
      this.details = true;
    },

    applyFilters(filters) {
      this.filters = filters;
      this.hasFilters = true;
      // go to page 1
      this.$refs.table.currentx = 1;
      this.fetchSurveys();
    },

    resetFilters() {
      this.filters = filters();
      this.filters.startDate = sub(new Date(), { days: 180 });
      this.filters.endDate = new Date();
      this.hasFilters = false;
      // go to page 1
      this.$refs.table.currentx = 1;
      this.searchText = '';
      this.$refs.table.searchx = '';
      this.fetchSurveys();
    },

    deleteConfirmation(id) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are You Sure?',
        text: 'You are about to delete this survey and this action cannot be undone.',
        acceptText: 'Yes',
        accept: () => this.deleteSurvey(id),
      });
    },
  },
};
</script>
