<template>
  <vs-prompt
    title="Filter"
    :active="active"
    :buttons-hidden="true"
    class="rj-confirm-modal"
    @close="close"
  >
    <div class="vx-row">
      <!-- Start Date -->
      <div class="vx-col w-full">
        <label class="vs-input--label">Timeframe</label>
        <div class="flex justify-between">
          <datepicker
            v-model="filters.startDate"
            :disabled-dates="disabledDates.startDate"
            format="MMM dd, yyyy"
            input-class="p-2"
            placeholder="Start Date"
          />
          <datepicker
            v-model="filters.endDate"
            :disabled-dates="disabledDates.endDate"
            format="MMM dd, yyyy"
            input-class="p-2"
            placeholder="End Date"
          />
        </div>
      </div>
    </div>

    <!-- Location -->
    <div
      v-if="isHighJump"
      class="mt-3"
    >
      <label class="vs-input--label">Location</label>
      <rj-select
        v-model="filters.location"
        :options="locations"
        :reduce="(location) => location.id"
        :clearable="false"
        placeholder="Select a location..."
      />
    </div>

    <!-- Employee -->
    <div
      v-if="isHighJump"
      class="mt-3"
    >
      <label class="vs-input--label">Employee</label>
      <rj-select
        v-model="filters.employee"
        :options="employees"
        :reduce="(employee) => employee.id"
        :clearable="false"
        placeholder="Select a Employee..."
      />
    </div>

    <!-- Funnel -->
    <div class="mt-3">
      <label class="vs-input--label">Funnel</label>
      <rj-select
        v-model="filters.funnel"
        :options="funnels"
        :reduce="(funnel) => funnel.id"
        :clearable="false"
        placeholder="Select a Funnel..."
      />
    </div>

    <!-- Goal -->
    <div class="mt-3">
      <label class="vs-input--label">Goal</label>
      <rj-select
        v-model="filters.goal"
        :options="goals"
        :reduce="(goal) => goal.id"
        :clearable="false"
        placeholder="Select a Goal..."
      />
    </div>

    <!-- Status -->
    <div class="mt-3">
      <label class="vs-input--label">Status</label>
      <rj-select
        v-model="filters.status"
        :options="statuses"
        :reduce="(status) => status.id"
        :clearable="false"
        placeholder="Select a Status..."
      />
    </div>

    <!-- Sentiment -->
    <div class="mt-3">
      <label class="vs-input--label">Sentiment</label>
      <rj-select
        v-model="filters.sentiment"
        :options="sentiments"
        :reduce="(sentiment) => sentiment.id"
        :clearable="false"
        placeholder="Select a Sentiment..."
      />
    </div>

    <template v-if="hasFiltersApplied || !pristine">
      <div class="pt-4 flex justify-end border-t border-gray-300 mt-5">
        <vs-button
          v-if="hasFiltersApplied"
          type="border"
          @click="reset"
        >
          Reset
        </vs-button>

        <vs-button
          v-if="!pristine"
          class="ml-3"
          @click="apply"
        >
          Filter
        </vs-button>
      </div>
    </template>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import Datepicker from 'vuejs-datepicker';
import { isPristine } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import filters from '@/views/company-dashboard/surveys/filters';

export default {
  name: 'RjFilterSurveysDialog',

  components: { Datepicker },

  mixins: [Authorizable],

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    appliedFilters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      old: {},
      pristine: true,
      filters: { ...this.appliedFilters },
      goals: [
        { id: null, label: 'All Goals' },
        { id: 'Testimonial', label: 'Testimonial' },
        { id: 'Review', label: 'Review' },
        { id: 'Referral', label: 'Referral' },
      ],
      statuses: [
        { id: null, label: 'All Statuses' },
        { id: 'Sent', label: 'Sent' },
        { id: 'Visited', label: 'Visited' },
        { id: 'Completed', label: 'Completed' },
        { id: 'Feedback', label: 'Feedback' },
        { id: 'Review site', label: 'Review site' },
        { id: 'Stopped', label: 'Stopped' },
        { id: 'Undelivered', label: 'Undelivered' },
      ],
      sentiments: [
        { id: null, label: 'All Sentiments' },
        { id: 1, label: 'Positive' },
        { id: 0, label: 'Negative' },
      ],
    };
  },

  computed: {
    disabledDates() {
      return {
        startDate: { from: new Date() },
        endDate: { from: new Date(), to: this.filters.startDate },
      };
    },

    hasFiltersApplied() {
      return !isPristine(this.appliedFilters, filters());
    },

    employees() {
      const employees = this.$store.getters['users/users'].map((user) => ({
        id: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));
      return [
        { id: null, label: 'All Employees' },
        ...employees,
      ];
    },

    funnels() {
      const funnels = this.$store.getters['funnels/funnels'].map((st) => ({
        id: st.id,
        label: st.name,
      }));
      return [
        { id: null, label: 'All Funnels' },
        ...funnels,
      ];
    },

    locations() {
      const locations = this.$store.getters['locations/locations'].map((location) => ({
        id: location.id,
        label: location.name,
      }));
      return [
        { id: null, label: 'All Locations' },
        ...locations,
      ];
    },
  },

  watch: {
    filters: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  mounted() {
    this.old = { ...this.filters };
  },

  methods: {
    apply() {
      this.$emit('apply-filters', this.filters);
      this.close();
    },

    reset() {
      this.filters = filters();
      this.old = { ...this.filters };
      this.$emit('reset-filters', filters());
      // this.close();
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      this.pristine = isPristine(current, filters());
    }),

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
