<template>
  <vs-prompt
    title="Google My Business"
    :active="active"
    accept-text="Connect"
    class="rj-medium-modal rj-confirm-modal"
    @accept="connect"
    @close="close"
  >
    <div class="space-y-2">
      <p>To monitor Google reviews in your RevenueJump dashboard, connect your Google My Business location(s).</p>
      <p>Note, only the matched location(s) between your RevenueJump and Google accounts will be connected.</p>
      <p>Click the Connect button below to be redirected to a Google authentication screen.</p>
      <p class="font-bold">
        Important: After clicking the Connect button, make sure that the checkbox labeled "See, edit, create and delete your Google business listings" is <span class="italic">checked</span> in the pop-up window.
      </p>
    </div>
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjConnect',

  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      authCode: '',
    };
  },

  methods: {
    async connect() {
      try {
        this.authCode = await this.$gAuth.getAuthCode();
        if (/\w+/.test(this.authCode)) {
          const company = this.$store.getters['companies/company'];

          if (!company.id) {
            throw new Error('Missing company');
          }

          const { success, accessToken, message } = await this.$store.dispatch('companies/saveGmbRefreshToken', { companyId: company.id, authCode: this.authCode });

          if (success) {
            this.$store.commit('companies/SET_GOOGLE_CONNECTED', true);
            this.$store.commit('companies/SET_GOOGLE_ACCESS_TOKEN', accessToken);
            this.$emit('connected');
            this.close();
          } else {
            this.$vs.notify({ title: 'Error', text: message, color: 'danger' });
          }
        }
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
