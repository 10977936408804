<template>
  <div>
    <vx-card
      class="overflow-hidden cursor-pointer transform hover:-translate-y-1"
      @click="onClick(application)"
    >
      <template #no-body>
        <div class="p-3 flex flex-col items-center justify-center text-center">
          <div class="flex items-center justify-between mb-4">
            <img
              :src="application.logo"
              :alt="application.name"
            >
          </div>

          <div class="truncate">
            <h2 class="mb-1 font-bold text-lg">
              {{ application.name }}
            </h2>
            <span class="text-gray-500">
              {{ application.type }}
            </span>
          </div>
        </div>
      </template>
    </vx-card>

    <rj-connect
      :active.sync="connecting"
      @connected="handleConnection"
    />

    <rj-match-locations
      :active.sync="matching"
      :facebook-pages="facebookPages"
      :connected-as="`${firstName} ${lastName}`"
      @matched="handleMatch"
    />

    <rj-connected-locations
      :active="managing"
      :locations="locations"
      :facebook-pages="facebookPages"
      @connected="handleConnection"
      @show-match-dialog="matching = true"
    />

    <v-facebook-login
      class="hidden"
      :app-id="appId"
      @sdk-init="handleSdkInit"
    />
  </div>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component';
import RjConnect from '@/views/company-dashboard/integrations/apps/facebook/RjConnect.vue';
import RjMatchLocations from '@/views/company-dashboard/integrations/apps/facebook/RjMatchLocations.vue';
import RjConnectedLocations from '@/views/company-dashboard/integrations/apps/facebook/RjConnectedLocations.vue';

export default {
  name: 'RjFacebookApp',

  components: {
    RjConnect,
    RjMatchLocations,
    RjConnectedLocations,
    VFacebookLogin,
  },

  props: {
    application: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      facebook: null,
      matching: false,
      connecting: false,
      facebookPages: [],
      firstName: '',
      lastName: '',
    };
  },

  computed: {
    locations() {
      return this.$store.getters['locations/locations'];
    },

    managing() {
      return this.application.managing;
    },
  },

  methods: {
    onClick() {
      return (this.application.connected && this.application.auth?.userID) ? this.manage() : this.connect();
    },

    connect() {
      this.connecting = true;
    },

    manage() {
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'facebook', isManaging: true });
    },

    handleSdkInit({ FB }) {
      this.facebook = FB;
      this.facebook.getLoginStatus((response) => {
        if (response.status === 'connected') {
          this.getFacebookNameAndPages();
          this.$store.commit('companies/SET_FACEBOOK_AUTH_RESPONSE', response.authResponse);
        }
      });
    },

    async getFacebookNameAndPages(cb) {
      this.facebookPages = [];

      // retrieve page ids and access tokens that we have already tied to a location in case
      // we have logged in as a different facebook user
      const getConnectedPages = this.locations.filter((l) => l.facebookPageId && l.facebookAccessToken)
        .map((l) => this.$store.dispatch('locations/getFacebookPage', l));
      const connectedPages = await Promise.allSettled(getConnectedPages);

      if (connectedPages.length) {
        // reduce to unique fb pages
        this.facebookPages = connectedPages.reduce((pages, page) => {
          if (page.value && !pages.find((p) => p.id === page.value.facebookPageId)) {
            pages.push({
              id: page.value.facebookPageId,
              label: page.value.name,
              accessToken: page.value.accessToken,
            });
          }
          return pages;
        }, []);
      }

      this.facebook.api('/me', { fields: ['last_name', 'first_name'] }, (response) => {
        this.firstName = response.first_name;
        this.lastName = response.last_name;
        const { userID } = this.application.auth;
        this.facebook.api(`/${userID}/accounts`, (response) => {
          const pages = response.data;
          for (const page of pages) {
            if (!this.facebookPages.find((p) => p.id === page.id)) {
              this.facebookPages.push({
                id: page.id,
                label: page.name,
                accessToken: page.access_token,
              });
              this.facebook.api(`/${page.id}/locations`, { fields: ['name', 'location', 'access_token'] }, (response) => {
                for (const locationPage of response.data) {
                  this.facebookPages.push({
                    id: locationPage.id,
                    label: `${locationPage.name} - ${locationPage.location.city}`,
                    accessToken: locationPage.access_token,
                  });
                }
              });
            }
          }
          cb && cb();
        });
      });
    },

    handleConnection() {
      this.getFacebookNameAndPages(() => {
        if (this.hasMultipleLocations()) {
          this.matching = true;
          return;
        }

        this.matchSingleLocation();
      });
    },

    handleMatch() {
      this.manage();
    },

    async matchSingleLocation() {
      const data = {
        locationId: this.locations[0].id,
        payload: {
          facebookPageId: this.facebookPages[0].id,
          facebookAccessToken: this.facebookPages[0].accessToken,
        },
      };
      try {
        await this.$store.dispatch('locations/updateLocation', data);
        this.handleMatch();
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    hasMultipleLocations() {
      return this.locations.length > 1 || this.facebookPages.length > 1;
    },
  },
};
</script>
