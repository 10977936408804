<template>
  <div class="widget__source">
    <template v-if="itemType === 'testimonial'">
      <span v-if="when">Feedback captured {{ when }} </span>
    </template>

    <template v-else>
      <div class="widget__review_site">
        <img :src="reviewSite.logoUrl">{{ reviewSite ? `From ${reviewSite.name}` : '' }} {{ when }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RjWidgetSource',

  props: {
    itemType: {
      type: String,
      required: true,
    },
    reviewSite: {
      type: Object,
      required: false,
      default: () => {},
    },
    when: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
