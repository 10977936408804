<template>
  <vs-prompt
    title="Filter"
    :active="active"
    :buttons-hidden="true"
    class="rj-confirm-modal"
    @close="close"
  >
    <div class="vx-row">
      <!-- Start Date -->
      <div class="vx-col w-full">
        <label class="vs-input--label">Timeframe</label>
        <div class="flex justify-between">
          <datepicker
            v-model="filters.startDate"
            :disabled-dates="disabledDates.startDate"
            format="MMM dd, yyyy"
            input-class="p-2"
            placeholder="Start Date"
          />
          <datepicker
            v-model="filters.endDate"
            :disabled-dates="disabledDates.endDate"
            format="MMM dd, yyyy"
            input-class="p-2"
            placeholder="End Date"
          />
        </div>
      </div>
    </div>

    <!-- Review sites -->
    <div class="mt-3">
      <label class="vs-input--label">Review Site</label>
      <rj-select
        v-model="filters.reviewSite"
        :options="reviewSites"
        :reduce="(site) => site.id"
        :clearable="false"
        placeholder="Select a Review Site..."
      />
    </div>

    <!-- Rating -->
    <div class="mt-3">
      <label class="vs-input--label">Rating</label>
      <rj-select
        v-model="filters.rating"
        :options="ratings"
        :reduce="(rating) => rating.value"
        :clearable="false"
        placeholder="Select a Rating..."
      />
    </div>

    <!-- Location -->
    <div
      v-if="isHighJump"
      class="mt-3"
    >
      <label class="vs-input--label">Location</label>
      <rj-select
        v-model="filters.location"
        :options="locations"
        :reduce="(location) => location.id"
        :clearable="false"
        placeholder="Select a Location..."
      />
    </div>

    <div
      v-if="hasSharedReviewSites"
      class="mt-3"
    >
      <label class="vs-input--label">Shared Review Sites</label>
      <vs-switch
        v-model="filters.getSharedReviewSites"
      >
        <span slot="on">SHOW</span>
        <span slot="off">HIDE</span>
      </vs-switch>
    </div>

    <!-- Widget -->
    <div class="mt-3">
      <label class="vs-input--label">Widget Visibility</label>
      <rj-select
        v-model="filters.appearOnWidget"
        :options="widgets"
        :reduce="(widget) => widget.value"
        :clearable="false"
        placeholder="Select a Widget..."
      />
    </div>

    <template v-if="hasFiltersApplied || !pristine">
      <div class="pt-4 flex justify-end border-t border-gray-300 mt-5">
        <vs-button
          v-if="hasFiltersApplied"
          type="border"
          @click="reset"
        >
          Reset
        </vs-button>

        <vs-button
          v-if="!pristine"
          class="ml-3"
          @click="apply"
        >
          Filter
        </vs-button>
      </div>
    </template>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import Datepicker from 'vuejs-datepicker';
import { isPristine } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import filters from '@/views/company-dashboard/results/filters/reviews';

export default {
  name: 'RjFilterReviewsDialog',

  components: { Datepicker },

  mixins: [Authorizable],

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    appliedFilters: {
      type: Object,
      required: true,
    },

    duplicateReviewSites: {
      type: Object,
      required: true,
      default: () => {},
    },

    allLocationReviewSites: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      old: {},
      pristine: true,
      filters: { ...this.appliedFilters },
      ratings: [
        { value: null, label: 'All Ratings' },
        { value: 0.2, label: '1 star' },
        { value: 0.4, label: '2 stars' },
        { value: 0.6, label: '3 stars' },
        { value: 0.8, label: '4 stars' },
        { value: 1, label: '5 stars' },
      ],
      widgets: [
        { value: null, label: 'Both' },
        { value: false, label: 'Hidden' },
        { value: true, label: 'Visible' },
      ],
    };
  },

  computed: {
    locations() {
      const locations = this.$store.getters['locations/locations'].map((location) => ({
        id: location.id,
        label: location.name,
      }));
      return [
        { id: null, label: 'All Locations' },
        ...locations,
      ];
    },

    reviewSites() {
      // filter to only display review sites that company is using
      const reviewSites = this.$store.getters['revenuejump/reviewSites'].filter((site) => this.allLocationReviewSites.find((s) => s.reviewSiteId === site.id)).map((site) => ({
        id: site.id,
        label: site.name,
      }));
      return [
        { id: null, label: 'All Review Sites' },
        ...reviewSites,
      ];
    },

    disabledDates() {
      return {
        start_date: { from: new Date() },
        end_date: { from: new Date(), to: this.filters.startDate },
      };
    },

    hasFiltersApplied() {
      return !isPristine(this.appliedFilters, filters());
    },

    hasSharedReviewSites() {
      if (!this.filters.location) {
        return false;
      }
      for (const reviewSite of Object.values(this.duplicateReviewSites)) {
        if (reviewSite.locationIds.includes(this.filters.location)) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    filters: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  methods: {
    apply() {
      this.$emit('apply-filters', this.filters);
      this.close();
    },

    reset() {
      this.filters = filters();
      this.old = { ...this.filters };
      this.$emit('reset-filters', filters());
      // this.close();
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      this.pristine = isPristine(current, this.old);
    }),

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
