<template>
  <div>
    <div class="w-full sm:w-1/2 mt-4">
      <vs-input
        :value="widget.name"
        name="name"
        label="Widget Name"
        class="w-full"
        @input="(value) => update('widget', 'name', value)"
      />
    </div>

    <div class="flex w-full flex-wrap sm:justify-between sm:flex-nowrap mt-6">
      <!-- Single Layout -->
      <div class="flex flex-col items-center w-1/2 sm:w-auto mb-6 sm:mb-0 pb-4">
        <label for="single">
          <img
            src="@/assets/images/widget/layouts/single.jpg"
            srcset="@/assets/images/widget/layouts/single.jpg 1x, @/assets/images/widget/layouts/single@2x.jpg 2x"
            alt="Widget Single Layout"
            class="rounded-lg border border-gray-500 cursor-pointer w-32 sm:w-auto"
          >
        </label>
        <vs-radio
          id="single"
          :value="widget.designConfig.layout"
          vs-value="single"
          vs-name="layout"
          class="text-base mt-3"
          @input="(value) => setLayout(value)"
        >
          Single
        </vs-radio>
      </div>

      <!-- Grid Layout -->
      <div
        class="flex flex-col items-center w-1/2 sm:w-auto mb-6 sm:mb-0 pb-4"
        @click="checkCompanyPlan"
      >
        <label for="grid">
          <img
            src="@/assets/images/widget/layouts/grid.jpg"
            srcset="@/assets/images/widget/layouts/grid.jpg 1x, @/assets/images/widget/layouts/grid@2x.jpg 2x"
            alt="Widget Grid Layout"
            :style="{ filter: isLowJump ? 'grayscale(100%)' : 'none' }"
            class="rounded-lg border border-gray-500 cursor-pointer w-32 sm:w-auto"
          >
        </label>
        <vs-radio
          id="grid"
          :value="widget.designConfig.layout"
          vs-value="grid"
          vs-name="layout"
          class="text-base mt-3"
          :disabled="isLowJump"
          @input="(value) => setLayout(value)"
        >
          Grid
        </vs-radio>
      </div>

      <!-- Carousel Layout -->
      <div
        class="flex flex-col items-center w-1/2 sm:w-auto pb-4"
        @click="checkCompanyPlan"
      >
        <label for="carousel">
          <img
            src="@/assets/images/widget/layouts/carousel.jpg"
            srcset="@/assets/images/widget/layouts/carousel.jpg 1x, @/assets/images/widget/layouts/carousel@2x.jpg 2x"
            alt="Widget Carousel Layout"
            :style="{ filter: isLowJump ? 'grayscale(100%)' : 'none' }"
            class="rounded-lg border border-gray-500 cursor-pointer w-32 sm:w-auto"
          >
        </label>
        <vs-radio
          id="carousel"
          :value="widget.designConfig.layout"
          vs-value="carousel"
          class="text-base mt-3"
          :disabled="isLowJump"
          @input="(value) => setLayout(value)"
        >
          Carousel
        </vs-radio>
      </div>

      <!-- Corner Layout -->
      <div
        class="flex flex-col items-center w-1/2 sm:w-auto pb-4"
        @click="checkCompanyPlan"
      >
        <label for="corner">
          <img
            src="@/assets/images/widget/layouts/corner.jpg"
            srcset="@/assets/images/widget/layouts/corner.jpg 1x, @/assets/images/widget/layouts/corner@2x.jpg 2x"
            alt="Widget Corner Layout"
            :style="{ filter: isLowJump ? 'grayscale(100%)' : 'none' }"
            class="rounded-lg border border-gray-500 cursor-pointer w-32 sm:w-auto"
          >
        </label>
        <vs-radio
          id="corner"
          :value="widget.designConfig.layout"
          :disabled="isLowJump"
          vs-value="corner"
          class="text-base mt-3"
          @input="(value) => setLayout(value)"
        >
          Corner
        </vs-radio>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';

export default {
  name: 'RjWidgetLayoutsCard',

  mixins: [Authorizable],

  props: {
    widget: {
      type: Object,
      required: true,
    },

    update: {
      type: Function,
      required: true,
    },
  },

  methods: {
    checkCompanyPlan() {
      if (this.isHighJump) {
        return;
      }

      bus.$emit('upgrade-company');
    },

    setLayout(value) {
      this.update('designConfig', 'layout', value);
      // set design to 'small' if corner is selected
      // otherwise default to standard
      if (value === 'corner') {
        this.update('designConfig', 'design', 'small');
      } else if (this.$store.getters['widgets/designConfig'].design === 'small') {
        this.update('designConfig', 'design', 'standard');
      }
    },
  },
};
</script>
