<template>
  <section>
    <template v-if="loading">
      Loading Dashboard...
    </template>

    <template v-else>
      <div class="vx-row">
        <!-- Welcome Card -->
        <div class="vx-col w-full sm:w-6/12 mb-4">
          <vx-card
            title-color="#fff"
            card-background="primary"
            content-color="#fff"
            class="flex-1 h-full items-center justify-center p-4 vx-card__full-height"
          >
            <template slot="no-body">
              <div class="flex flex-col items-center justify-center h-full">
                <feather-icon
                  icon="SmileIcon"
                  svg-classes="w-16 h-16"
                />
                <div class="mt-3 text-center">
                  <p class="text-2xl">
                    Welcome <span class="font-semibold">{{ `${auth.user.firstName} ${auth.user.lastName}` }}</span>!
                  </p>
                  <p>
                    We hope we’re making your day a little bit brighter.
                  </p>
                </div>
              </div>
            </template>
          </vx-card>
        </div>

        <!-- Reputation Performance Grade -->
        <div class="vx-col w-full sm:w-3/12 mb-4">
          <statistics-card-line
            icon="StarIcon"
            :statistic="reputationPerformanceGrade.current"
            :chart-data="reputationPerformanceGrade.chartData"
            statistic-title="Reputation Performance Grade"
            type="area"
            color="rjBlue"
          />
        </div>

        <!-- Competitor Review Score -->
        <div class="vx-col w-full sm:w-3/12 mb-4">
          <statistics-card-line
            icon="MapIcon"
            :statistic="competitorReviewScoreHistory.current"
            :chart-data="competitorReviewScoreHistory.chartData"
            statistic-title="Competitor Review Score"
            type="area"
            color="rjOrange"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-5">
          <span class="font-bold mr-4 uppercase text-xs">Filter by:</span>
          <div class="flex items-center flex-wrap sm:space-x-4">
            <div
              v-if="isHighJump"
              class="w-full mb-3 sm:mb-0 sm:w-64"
            >
              <rj-select
                v-model="location"
                :options="[ { label: 'All Locations', id: -1 }, ...locations.map((l) => ({ label: l.name, ...l })) ]"
                :reduce="(location) => location.id"
                :clearable="false"
                placeholder="Select location..."
                class="bg-white w-auto"
              />
            </div>

            <div class="w-full sm:w-64">
              <datepicker
                v-model="startDate"
                format="MMM dd, yyyy"
                input-class="p-2"
                placeholder="Start Date"
              />
            </div>

            <div class="w-full sm:w-64">
              <datepicker
                v-model="endDate"
                format="MMM dd, yyyy"
                input-class="p-2"
                placeholder="End Date"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-6/12 lg:w-7/12 mb-base">
          <rj-satisfaction-score-chart
            :satisfaction-score="satisfactionScore"
            :surveys-sent="surveysSent"
            :completion-rate="completionRate"
            :visit-rate="visitRate"
          />
        </div>

        <div class="vx-col w-full md:w-6/12 lg:w-5/12 mb-base">
          <rj-review-ratings-chart
            :star-ratings="starRatings"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/4 mb-base">
          <statistics-card-line
            icon="StarIcon"
            :statistic="ratingByMonth.current"
            :chart-data="ratingByMonth.chartData"
            statistic-title="Overall Rating"
            type="area"
            color="rjGreen"
            class="min-h-full"
          />
        </div>

        <div class="vx-col w-full md:w-1/4 mb-base">
          <statistics-card-line
            icon="ListIcon"
            :statistic="capturedReviewsCount.current"
            :chart-data="capturedReviewsCount.chartData"
            statistic-title="Captured Reviews"
            type="area"
            color="rjRed"
            class="min-h-full"
          />
        </div>

        <div class="vx-col w-full md:w-1/2 mb-base">
          <rj-top-review-sites-table
            :top-sites="topSites"
          />
        </div>
      </div>
    </template>
    <rj-login-notification
      v-if="loginNotification"
      :notification="loginNotification"
      :active.sync="displayLoginNotification"
    />
  </section>
</template>

<script>
import { sub, set } from 'date-fns';
import Datepicker from 'vuejs-datepicker';
import { httpBuildQuery } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import RjReviewRatingsChart from '@/views/company-dashboard/home/RjReviewRatingsChart.vue';
import RjTopReviewSitesTable from '@/views/company-dashboard/home/RjTopReviewSitesTable.vue';
import RjSatisfactionScoreChart from '@/views/company-dashboard/home/RjSatisfactionScoreChart.vue';
import RjLoginNotification from '@/views/common/notifications/modals/RjLoginNotification.vue';

export default {
  name: 'RjHome',

  components: {
    StatisticsCardLine,
    RjReviewRatingsChart,
    RjTopReviewSitesTable,
    RjSatisfactionScoreChart,
    RjLoginNotification,
    Datepicker,
  },

  mixins: [Authorizable],

  data() {
    return {
      loading: true,
      competitorReviewScoreHistory: {
        current: 0,
        chartData: [{ name: 'Competitor Review Score', data: [5, 6, 10, 8, 12] }],
      },
      completionRate: 0,
      location: -1, // -1 means all, to keep this field only a number.
      startDate: sub(new Date(), { months: 6 }),
      endDate: new Date(),
      ratingByMonth: {
        current: 0,
        chartData: [{ name: 'Rating', data: [5, 6, 10, 8, 12] }],
      },
      reputationPerformanceGrade: {
        current: 0,
        chartData: [{ name: 'Reputation Performance Grade', data: [5, 6, 10, 8, 12] }],
      },
      capturedReviewsCount: {
        current: 0,
        chartData: [{ name: 'Reviews', data: [5, 6, 10, 8, 12] }],
      },
      satisfactionScore: 0,
      starRatings: {
        1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
      },
      surveysSent: 0,
      topSites: [],
      visitRate: 0,
      displayLoginNotification: false,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },
    company() {
      return this.$store.getters['companies/company'];
    },
    locations() {
      return this.$store.getters['locations/locations'] || [];
    },
    startDateZ() {
      return set(this.startDate, { hours: 0, minutes: 0, seconds: 0 });
    },
    endDateZ() {
      return set(this.endDate, { hours: 23, minutes: 59, seconds: 59 });
    },
    loginNotification() {
      return this.$store.getters['notifications/loginNotification'];
    },
    isAdmin() {
      const currentRole = this.$store.getters['auth/currentRole'];
      return /^Admin/.test(currentRole);
    },
  },
  watch: {
    company() {
      this.fetchCompetitorReviewScoreHistory();
    },
    location() {
      this.refresh();
    },
    locations() {
      this.refresh();
    },
    startDate() {
      this.refresh();
    },
    endDate() {
      this.refresh();
    },
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },
  methods: {
    async fetch() {
      const companyId = this.company.id;
      const filters = httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { active: true, companyId },
        }),
      });

      await this.$store.dispatch('locations/fetchLocations', filters);
      this.loading = false;

      this.refresh();
    },

    refresh() {
      this.fetchRating();
      this.fetchCapturedReviewsCount();
      this.fetchRatingDistribution();
      this.fetchTopSites();
      this.fetchSatisfactionScore();
      this.fetchCompletionRate();
      this.fetchVisitRate();
      this.fetchSurveysSent();
      this.fetchReputationPerformanceGrade();
      this.fetchCompetitorReviewScoreHistory();
      if (!this.isAdmin) {
        this.fetchLoginNotification();
      }
    },
    async fetchCompetitorReviewScoreHistory() {
      const crs = await this.$store.dispatch('companies/getCompetitorReviewScoreHistory', this.company.id);
      if (!crs || !crs.scores.length) {
        this.competitorReviewScoreHistory = {
          current: 0,
          chartData: [{ name: 'Competitor Review Score', data: [0] }],
        };
        return;
      }
      this.competitorReviewScoreHistory = {
        current: crs.scores[crs.scores.length - 1],
        chartData: [{ name: 'Competitor Review Score', data: crs.scores }],
      };
    },
    async fetchRating() {
      let locationIds;
      if (this.location === -1) {
        locationIds = this.locations.map((l) => l.id);
      } else {
        locationIds = [this.location];
      }
      if (!locationIds.length) {
        return;
      }
      const filter = {
        where: {
          locationId: { inq: locationIds },
        },
        by: 'range',
        startDate: this.startDateZ,
        endDate: this.endDateZ,
      };
      const ratings = await this.$store.dispatch('companies/getRating', filter);
      if (!ratings) {
        return;
      }
      const current = (ratings[0].value * 5).toPrecision(2);
      const reversed = [...ratings].map((r) => (r.value * 5).toPrecision(2)).reverse();
      this.ratingByMonth = {
        current,
        chartData: [{ name: 'Rating', data: reversed }],
      };
    },
    async fetchReputationPerformanceGrade() {
      let locationIds;
      if (this.location === -1) {
        locationIds = this.locations.map((l) => l.id);
      } else {
        locationIds = [this.location];
      }
      if (!locationIds.length) {
        return;
      }
      const filter = {
        where: {
          locationId: { inq: locationIds },
        },
      };
      const grades = await this.$store.dispatch('companies/getReputationPerformanceGrade', filter);
      if (!grades) {
        return;
      }
      const current = grades[grades.length - 1].rpg;
      this.reputationPerformanceGrade = {
        current,
        chartData: [{ name: 'Reputation Performance Grade', data: grades.map((g) => g.rpg) }],
      };
    },
    async fetchCapturedReviewsCount() {
      let locationIds;
      if (this.location === -1) {
        locationIds = this.locations.map((l) => l.id);
      } else {
        locationIds = [this.location];
      }
      if (!locationIds.length) {
        return;
      }
      const filter = {
        where: {
          locationId: { inq: locationIds },
        },
        by: 'range',
        startDate: this.startDateZ,
        endDate: this.endDateZ,
      };
      const reviews = await this.$store.dispatch('companies/getCapturedReviewsCount', filter);
      if (!reviews) {
        return;
      }
      const current = reviews.count[reviews.count.length - 1].value;
      this.capturedReviewsCount = {
        current,
        chartData: [{ name: 'Reviews', data: reviews.count.map((c) => c.value) }],
      };
    },
    async fetchRatingDistribution() {
      let locationIds;
      if (this.location === -1) {
        locationIds = this.locations.map((l) => l.id);
      } else {
        locationIds = [this.location];
      }
      if (!locationIds.length) {
        return;
      }
      const filter = {
        where: {
          locationId: { inq: locationIds },
          date: { between: [this.startDateZ, this.endDateZ] },
        },
      };
      const { count } = await this.$store.dispatch('companies/getRatingDistribution', filter);
      this.starRatings = count;
    },
    async fetchTopSites() {
      let locationIds;
      if (this.location === -1) {
        locationIds = this.locations.map((l) => l.id);
      } else {
        locationIds = [this.location];
      }
      if (!locationIds.length) {
        return;
      }
      const filter = {
        where: {
          locationId: { inq: locationIds },
          date: { between: [this.startDateZ, this.endDateZ] },
        },
      };
      const { topSites } = await this.$store.dispatch('companies/getTopSites', filter);
      this.topSites = topSites;
    },
    async fetchSatisfactionScore() {
      let locationIds;
      if (this.location === -1) {
        locationIds = this.locations.map((l) => l.id);
      } else {
        locationIds = [this.location];
      }
      if (!locationIds.length) {
        return;
      }
      const filter = {
        where: {
          locationId: { inq: locationIds },
          createdAt: { between: [this.startDateZ, this.endDateZ] },
        },
      };
      const { count } = await this.$store.dispatch('companies/getSatisfactionScore', filter);
      this.satisfactionScore = count;
    },
    async fetchCompletionRate() {
      let locationIds;
      if (this.location === -1) {
        locationIds = this.locations.map((l) => l.id);
      } else {
        locationIds = [this.location];
      }
      if (!locationIds.length) {
        return;
      }
      const filter = {
        where: {
          locationId: { inq: locationIds },
          createdAt: { between: [this.startDateZ, this.endDateZ] },
        },
      };
      const { count } = await this.$store.dispatch('companies/getCompletionRate', filter);
      this.completionRate = count;
    },
    async fetchVisitRate() {
      let locationIds;
      if (this.location === -1) {
        locationIds = this.locations.map((l) => l.id);
      } else {
        locationIds = [this.location];
      }
      if (!locationIds.length) {
        return;
      }
      const filter = {
        where: {
          locationId: { inq: locationIds },
          createdAt: { between: [this.startDateZ, this.endDateZ] },
        },
      };
      const { count } = await this.$store.dispatch('companies/getVisitRate', filter);
      this.visitRate = count;
    },
    async fetchSurveysSent() {
      let locationIds;
      if (this.location === -1) {
        locationIds = this.locations.map((l) => l.id);
      } else {
        locationIds = [this.location];
      }
      if (!locationIds.length) {
        return;
      }
      const filter = {
        where: {
          locationId: { inq: locationIds },
          createdAt: { between: [this.startDateZ, this.endDateZ] },
        },
      };
      const { count } = await this.$store.dispatch('companies/getSurveysSent', filter);
      this.surveysSent = count;
    },
    async fetchLoginNotification() {
      await this.$store.dispatch('notifications/getLoginNotification');
      if (this.$store.getters['notifications/loginNotification']) {
        this.displayLoginNotification = true;
      }
    },
  },
};
</script>
