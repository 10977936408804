<template>
  <vs-prompt
    title="Survey"
    :active="active"
    accept-text="Send"
    class="rj-send-survey-modal rj-confirm-modal"
    :buttons-hidden="true"
    :style="{ zIndex: 52004 }"
    @close="close"
  >
    <vs-tabs alignment="fixed">
      <vs-tab label="Details">
        <rj-survey-details-tab
          :survey="survey"
          @close="close"
          @deleted="($event) => $emit('deleted', $event)"
          @resent="($event) => $emit('resent', $event)"
        />
      </vs-tab>

      <!-- Only show these tabs if the message has been sent -->
      <template v-for="(message, index) in survey.surveyMessages">
        <vs-tab
          :key="index"
          :label="`Message #${index+1}`"
        >
          <rj-survey-message-tab
            :survey="survey"
            :message="message"
            @stopped="(survey) => { $emit('stopped', survey); close(); }"
          />
        </vs-tab>
      </template>
    </vs-tabs>
  </vs-prompt>
</template>

<script>
import RjSurveyDetailsTab from '@/views/company-dashboard/surveys/partials/RjSurveyDetailsTab.vue';
import RjSurveyMessageTab from '@/views/company-dashboard/surveys/partials/RjSurveyMessageTab.vue';

export default {
  name: 'RjSurveyDetailsDialog',

  components: {
    RjSurveyDetailsTab,
    RjSurveyMessageTab,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
