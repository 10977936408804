<template>
  <section>
    <template v-if="loading">
      <p>Loading widget...</p>
    </template>

    <template v-else>
      <header class="flex items-baseline justify-between mb-6">
        <div class="flex flex-wrap items-center">
          <h2 class="pr-3 font-semibold text-gray-600 mr-3 border-0 md:border-r border-solid border-gray-500">
            Widget Builder
          </h2>
          <rj-breadcrumb :items="breadcrumbs" />
        </div>

        <div>
          <vs-button
            v-if="activeWidgetId"
            type="border"
            @click="embedding = true"
          >
            Embed
          </vs-button>

          <vs-button
            class="ml-3"
            :disabled="saving"
            @click="saveAndPreview"
          >
            {{ saveBtnLabel }}
          </vs-button>
        </div>
      </header>

      <main>
        <component :is="builder" />
      </main>

      <div class="flex mt-4 justify-end">
        <vs-button
          type="border"
          @click="removeConfirmation"
        >
          Delete
        </vs-button>
        <vs-button
          class="ml-3"
          :disabled="saving"
          @click="saveAndPreview"
        >
          {{ saveBtnLabel }}
        </vs-button>
      </div>
    </template>

    <rj-widget-previewer
      v-if="previewing"
      :active.sync="previewing"
    />

    <rj-widget-embed-code
      v-if="embedding"
      :widget-id="activeWidgetId"
      :active.sync="embedding"
    />
  </section>
</template>

<script>
import RjWidgetPreviewer from '@/views/company-dashboard/integrations/widgets/RjWidgetPreviewer.vue';
import RjWidgetEmbedCode from '@/views/company-dashboard/integrations/widgets/RjWidgetEmbedCode.vue';
import RjGridWidgetBuilder from '@/views/company-dashboard/integrations/widgets/builder/RjGridWidgetBuilder.vue';
import RjSingleWidgetBuilder from '@/views/company-dashboard/integrations/widgets/builder/RjSingleWidgetBuilder.vue';
import RjCornerWidgetBuilder from '@/views/company-dashboard/integrations/widgets/builder/RjCornerWidgetBuilder.vue';
import RjCarouselWidgetBuilder from '@/views/company-dashboard/integrations/widgets/builder/RjCarouselWidgetBuilder.vue';

export default {
  name: 'RjWidgetsBuilder',

  components: {
    RjWidgetEmbedCode,
    RjWidgetPreviewer,
    RjGridWidgetBuilder,
    RjSingleWidgetBuilder,
    RjCornerWidgetBuilder,
    RjCarouselWidgetBuilder,
  },

  beforeRouteLeave(to, from, next) {
    if (this.pristine) {
      return next();
    }

    // The Accept and Cancel actions are inverted because
    // it is not possible to change the buttons position in the UI.
    return this.$vs.dialog({
      type: 'confirm',
      color: 'primary',
      title: 'Are You Sure?',
      text: 'You will lose all unsaved changes.',
      cancelText: 'Accept',
      buttonCancel: 'filled',
      cancel: () => next(),
      acceptText: 'Cancel',
      buttonAccept: 'border',
      class: 'xpto',
      accept: () => next(false),
    });
  },

  props: {
    widgetId: {
      type: [Number, String, null],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      embedding: false,
      previewing: false,
      loading: true,
      saving: false,
      activeWidgetId: this.widgetId,
    };
  },

  computed: {
    breadcrumbs() {
      return [
        { title: 'Widgets', url: '/company/integrations/widgets' },
        { title: this.widget.name, active: true },
      ];
    },

    saveBtnLabel() {
      return this.saving ? 'Saving...' : 'Save + Preview';
    },

    widget() {
      return this.$store.getters['widgets/widget'];
    },

    layout() {
      return this.$store.getters['widgets/designConfig'].layout;
    },

    builder() {
      return {
        single: 'RjSingleWidgetBuilder',
        grid: 'RjGridWidgetBuilder',
        carousel: 'RjCarouselWidgetBuilder',
        corner: 'RjCornerWidgetBuilder',
      }[this.layout];
    },

    pristine() {
      return this.$store.getters['widgets/pristine'];
    },
  },

  watch: {
    widgetId: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch(id = null) {
      if (!id) {
        this.$store.commit('widgets/SET_DEFAULT_WIDGET');
      } else {
        await this.$store.dispatch('widgets/getWidgetItems', id);
      }

      this.$store.commit('widgets/CACHE_INITIAL_PROPS', this.widget);
      this.loading = false;
    },

    removeConfirmation() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are You Sure?',
        text: 'You are about to delete this widget and this action cannot be undone.',
        acceptText: 'Delete',
        accept: () => this.remove(),
      });
    },

    async remove() {
      await this.$store.dispatch('widgets/deleteWidget', this.widget.id);
      this.$router.replace({
        name: 'company-widgets',
      });
    },

    async saveAndPreview() {
      try {
        this.saving = true;
        const payload = {
          name: this.widget.name,
          designConfig: this.$store.getters['widgets/designConfig'],
          sourceConfig: this.$store.getters['widgets/sourceConfig'],
          enabled: true,
          active: true,
        };

        if (this.widgetId) {
          payload.id = this.widgetId;
          await this.$store.dispatch('widgets/updateWidget', payload);
        } else {
          payload.companyId = this.$store.getters['companies/company'].id;
          const { id } = await this.$store.dispatch('widgets/createWidget', payload);
          this.activeWidgetId = id;
          this.$store.commit('widgets/UPDATE_WIDGET_PROP', { id });
        }

        this.$store.commit('widgets/CACHE_INITIAL_PROPS', this.widget);

        this.previewing = true;
        this.$vs.notify({ title: 'Success', text: 'Widget saved', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
