export default function company({ store, next }) {
  const role = store.getters['auth/currentRole'];
  const foundRole = [
    ...store.getters['roles/companyRoles'],
    ...store.getters['roles/partnerRoles'],
    ...store.getters['roles/adminRoles'],
  ].some((r) => r.name === role);

  return (foundRole) ? next() : next({ name: 'login' });
}
