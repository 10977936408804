<template>
  <vs-prompt
    title="Send Survey"
    :active="active"
    accept-text="Send"
    class="rj-send-survey-modal rj-confirm-modal"
    :buttons-hidden="true"
    @close="close"
  >
    <template v-if="loading">
      <p>Loading...</p>
    </template>

    <template v-else>
      <vs-tabs alignment="fixed">
        <vs-tab label="Single">
          <rj-send-single-survey-tab
            :company="company"
            :locations="locations"
            :funnels="funnels"
            :sms="sms"
            :employees="employees"
            @close="close"
            @check-sms-limit="triggerSmsDialog"
          />
        </vs-tab>

        <vs-tab label="Bulk">
          <rj-send-bulk-surveys-tab
            :company="company"
            :locations="locations"
            :funnels="funnels"
            :employees="employees"
            @close="close"
            @check-sms-limit="triggerSmsDialog"
          />
        </vs-tab>
      </vs-tabs>
    </template>
  </vs-prompt>
</template>

<script>
import { formatToTimeZone } from 'date-fns-timezone';
import { bus, httpBuildQuery } from '@/utils';
import RjSendBulkSurveysTab from '@/views/company-dashboard/surveys/partials/RjSendBulkSurveysTab.vue';
import RjSendSingleSurveyTab from '@/views/company-dashboard/surveys/partials/RjSendSingleSurveyTab.vue';

export default {
  name: 'RjSendSurveyDialog',

  components: {
    RjSendBulkSurveysTab,
    RjSendSingleSurveyTab,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    sms() {
      return this.$store.getters['companies/sms'];
    },

    employees() {
      return this.$store.getters['users/users'].map((e) => ({
        label: `${e.firstName} ${e.lastName}`, ...e,
      }));
    },

    funnels() {
      return this.$store.getters['funnels/funnels'].filter((f) => f.active);
    },

    locations() {
      return this.$store.getters['locations/locations'];
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      await this.fetchLocations();
      await this.fetchFunnels();
      await this.fetchEmployees();
      await this.fetchSmsStatus();

      this.loading = false;
    },

    async fetchLocations() {
      if (this.locations.length) {
        return;
      }

      const companyId = this.company.id;
      await this.$store.dispatch('locations/fetchLocations', httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { active: true, companyId },
        }),
      }));
    },

    async fetchFunnels() {
      if (this.funnels.length) {
        return;
      }

      const companyId = this.company.id;
      const filters = httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { active: true, companyId },
          fields: ['id', 'name', 'locationId', 'type', 'active'],
        }),
      });

      await this.$store.dispatch('funnels/fetchFunnels', filters);
    },

    async fetchEmployees() {
      if (this.employees.length) {
        return;
      }

      const companyId = this.company.id;
      const filters = httpBuildQuery({
        includeSurveyStats: 0,
        filter: JSON.stringify({
          where: { active: true, companyId },
        }),
      });

      await this.$store.dispatch('users/fetchUsers', filters);
    },

    async fetchSmsStatus() {
      await this.$store.dispatch('companies/fetchSmsStatus', this.company.id);
    },

    triggerSmsDialog() {
      if (this.sms.max > this.sms.used) {
        return;
      }

      const partner = this.$store.getters['partners/partner'];
      const renewalDate = formatToTimeZone(new Date(this.sms.renewalDate), 'Do', { timeZone: 'UTC' });
      const dialog = (attributes) => this.$vs.dialog(attributes);

      const upgradeIntent = {
        type: 'alert',
        color: 'primary',
        title: 'Limit Reached',
        text: `Sounds good! We just sent ${partner.name} a notification about your interest in more SMS credits. They should reach out to you soon with more information.`,
        acceptText: 'OK',
      };

      const limitReached = {
        type: 'alert',
        color: 'primary',
        title: 'Limit Reached',
        text: `You have sent the maximum number of SMS messages for your account. Your monthly allotment of ${this.sms.max} SMS credits will refresh on the next ${renewalDate} of the month. Until then, you may only send email surveys. Or, would you like to request more information from ${partner.name} to add another 500-pack of SMS credits?`,
        acceptText: 'Yes',
        accept: () => {
          dialog(upgradeIntent);
          this.$store.dispatch('companies/sendAdditionalSmsRequest', this.company.id);
        },
      };

      dialog(limitReached);
    },

    close() {
      bus.$emit('hide-modal');
    },
  },
};
</script>
