import { render, staticRenderFns } from "./RjReviewRatingsChart.vue?vue&type=template&id=c5365cb6"
import script from "./RjReviewRatingsChart.vue?vue&type=script&lang=js"
export * from "./RjReviewRatingsChart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports