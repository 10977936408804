<template>
  <div
    class="widget-layout widget-corner"
    :class="position"
    @mouseenter="pause"
    @mouseleave="resume"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="visible"
        class="widget-layout__corner"
      >
        <slot
          name="item"
          :item="item"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'RjCornerLayoutRender',

  props: {
    widget: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      current: 0,
      visible: false,
    };
  },

  computed: {
    settings() {
      return this.widget.designConfig;
    },

    total() {
      return this.widget.items.length;
    },

    item() {
      return this.widget.items[this.current];
    },

    position() {
      return `widget-corner__${this.settings.position}`;
    },
  },

  mounted() {
    this.hide();
  },

  beforeDestroy() {
    this.clearTimeouts();
  },

  methods: {
    clearTimeouts() {
      clearTimeout(this.showTimer);
      clearTimeout(this.hideTimer);
    },

    show() {
      this.current = (this.current + (this.total + 1)) % this.total;
      this.visible = true;
      this.hideTimer = setTimeout(this.hide, this.settings.displayTime);
    },

    hide() {
      this.visible = false;
      this.showTimer = setTimeout(this.show, this.settings.breakTime);
    },

    pause() {
      this.clearTimeouts();
    },

    resume() {
      this.hide();
    },
  },
};
</script>
