<template>
  <main>
    <form @submit.prevent="send({ allowDuplicate: false })">
      <div
        v-show="step === 1"
        class="space-y-3"
      >
        <!-- Location -->
        <div v-show="isHighJump && locations.length > 1">
          <label class="vs-input--label">Location*</label>
          <rj-select
            v-model="form.location"
            v-validate="'required'"
            name="location"
            label="name"
            :options="locations"
            :reduce="(location) => location.id"
            :clearable="false"
            placeholder="Select a location..."
          />
          <span class="text-danger text-sm">
            {{ errors.first('location') }}
          </span>
        </div>

        <!-- Funnel -->
        <div v-show="isHighJump && funnels.length > 1">
          <label class="vs-input--label">Funnel*</label>
          <rj-select
            v-model="form.funnel"
            v-validate="'required'"
            name="funnel"
            label="name"
            :options="funnels"
            :reduce="(funnel) => funnel.id"
            :clearable="false"
            placeholder="Select a funnel..."
          />
          <span class="text-danger text-sm">
            {{ errors.first('funnel') }}
          </span>
        </div>

        <!-- Employee -->
        <div v-show="isHighJump && employees.length > 1">
          <label class="vs-input--label">Employee*</label>
          <rj-select
            v-model="form.employee"
            v-validate="'required'"
            name="employee"
            :options="employees"
            :reduce="(employee) => employee.id"
            :clearable="false"
            placeholder="Select a employee..."
          />
          <span class="text-danger text-sm">
            {{ errors.first('employee') }}
          </span>
        </div>

        <div
          v-if="stepOneValidated"
          class="pt-4 flex justify-end border-t border-gray-300 mt-5"
        >
          <vs-button @click="step = 2">
            Next
          </vs-button>
        </div>
      </div>

      <div
        v-show="step === 2"
        class="space-y-3"
      >
        <div class="vx-row">
          <!-- First Name -->
          <div class="vx-col w-full sm:w-1/2">
            <vs-input
              v-model="form.firstName"
              v-validate="'required'"
              name="firstName"
              label="First Name*"
              class="w-full"
            />
            <span class="text-sm text-red-500">
              {{ errors.first('first name') }}
            </span>
          </div>

          <!-- Last Name -->
          <div class="mt-3 vx-col w-full sm:w-1/2 sm:mt-0">
            <vs-input
              v-model="form.lastName"
              name="last name"
              label="Last Name"
              class="w-full"
            />
          </div>
        </div>

        <p class="leading-tight">
          Phone or Email field is required. For best results, complete both.
        </p>

        <!-- Phone Number -->
        <div
          v-show="enableSms"
          class="mt-1"
          @click="$emit('check-sms-limit')"
        >
          <input
            v-model="form.phone"
            v-validate="requiredIf.phone"
            type="hidden"
            name="phone"
          >
          <vs-input
            ref="phone"
            v-model="maskedPhone"
            v-facade="'(###) ###-####'"
            :disabled="sms.used >= sms.max"
            label="Phone Number"
            class="w-full"
          />
          <span class="text-sm text-red-500">
            {{ errors.first('phone') }}
          </span>
        </div>

        <!-- Email -->
        <div>
          <vs-input
            ref="email"
            v-model="form.email"
            v-validate="requiredIf.email"
            name="email"
            label="Email"
            class="w-full"
          />
          <span class="text-sm text-red-500">
            {{ errors.first('email') }}
          </span>
        </div>

        <div>
          <vs-checkbox
            v-model="form.hasPermission"
            v-validate="'required'"
            name="permission"
          >
            I have permission to contact this person.
          </vs-checkbox>
        </div>

        <div>
          <vs-checkbox v-model="keepWindowOpen">
            Keep window open to send another survey.
          </vs-checkbox>
        </div>

        <div
          v-if="isValid || hasStepOne"
          class="pt-4 flex justify-end border-t border-gray-300 mt-5 space-x-4"
        >
          <vs-button
            v-if="hasStepOne"
            type="border"
            @click="step = 1"
          >
            Back
          </vs-button>

          <vs-button
            v-if="isValid"
            button="submit"
          >
            {{ sending ? 'Sending...' : 'Send' }}
          </vs-button>
        </div>
      </div>
    </form>

    <rj-csv-duplicates-dialog
      :active.sync="hasDuplicates"
      :duplicates="duplicates"
      @approve-duplicates="approveDuplicates"
    />
  </main>
</template>

<script>
import { facade } from 'vue-input-facade';
import Authorizable from '@/mixins/Authorizable';
import { bus, httpBuildQuery, isEmptyObject } from '@/utils';
import RjCsvDuplicatesDialog from '@/views/company-dashboard/surveys/modals/RjCsvDuplicatesDialog.vue';

export default {
  name: 'RjSendSingleSurveyTab',

  components: { RjCsvDuplicatesDialog },

  directives: {
    facade,
  },

  mixins: [Authorizable],

  props: {
    company: {
      type: Object,
      required: true,
    },

    locations: {
      type: Array,
      required: true,
    },

    sms: {
      type: Object,
      required: true,
    },

    funnels: {
      type: Array,
      required: true,
    },

    employees: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      step: 1,
      maskedPhone: '',
      form: {
        location: null,
        funnel: null,
        employee: null,
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        hasPermission: true,
      },
      duplicates: [],
      hasDuplicates: false,
      keepWindowOpen: false,
      sending: false,
    };
  },

  computed: {
    hasStepOne() {
      if (this.isLowJump) {
        return false;
      }

      const { locations, employees, funnels } = this;
      return funnels.length > 1 || locations.length > 1 || employees.length > 1;
    },

    stepOneValidated() {
      if (isEmptyObject(this.fields)) {
        return false;
      }

      const required = ['location', 'funnel', 'employee'];
      return required.every((field) => this.fields[field].valid);
    },

    isValid() {
      if (isEmptyObject(this.fields)) {
        return false;
      }

      if (this.isHighJump) {
        const required = ['firstName'];
        const optional = ['email', 'phone'];
        return required.every((field) => this.fields[field].valid) && optional.some((field) => this.fields[field].valid);
      }

      const required = ['firstName', 'email'];
      return required.every((field) => this.fields[field].valid);
    },

    requiredIf() {
      const { phone, email } = this.form;

      return {
        email: (phone) ? 'email|required_if:phone,null' : 'required|email',
        phone: (email) ? 'integer|length:10|required_if:email,null' : 'required|integer|length:10',
      };
    },

    enableSms() {
      return this.isHighJump;
    },
  },

  watch: {
    maskedPhone: {
      handler: 'unmask',
    },
  },

  mounted() {
    this.step = this.hasStepOne ? 1 : 2;
    this.setFormDefaults();
  },

  methods: {
    setFormDefaults() {
      this.form.funnel = (this.funnels.length === 1) ? this.funnels[0].id : null;
      this.form.location = (this.locations.length === 1) ? this.locations[0].id : null;
      this.form.employee = (this.employees.length === 1) ? this.employees[0].id : null;
    },

    async send({ allowDuplicate = false }) {
      try {
        this.sending = true;
        let lookedUpPhone = this.form.phone;
        if (this.form.phone) {
          lookedUpPhone = await this.$store.dispatch('contacts/lookupPhone', this.form.phone);
        }

        if (!allowDuplicate) {
          const survey = await this.getPreviousSurvey(this.form.location, this.form.email, lookedUpPhone);
          if (survey) {
            this.duplicates = [{ firstName: survey.contact.firstName, lastName: survey.contact.lastName, index: 0 }];
            this.hasDuplicates = true;
            return;
          }
        }
        await this.$store.dispatch('surveys/sendSurvey', {
          allowDuplicate,
          email: this.form.email,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          locationId: this.form.location,
          origin: 'Dashboard',
          phone: lookedUpPhone,
          surveyTemplateId: this.form.funnel,
          type: this.funnels.find((f) => f.id === this.form.funnel)?.type ?? '',
          userId: this.form.employee,
        });

        this.$vs.notify({
          title: 'Success', text: 'Survey sent.', color: 'success', time: 3000,
        });

        bus.$emit('surveys-sent');

        if (!this.keepWindowOpen) {
          this.$emit('close');
        }
      } catch (error) {
        this.$vs.notify({
          title: 'Error', text: error.message || 'Something went wrong.', color: 'danger', time: 3000,
        });
      } finally {
        this.sending = false;
      }
    },

    async getPreviousSurvey(locationId, email, phone) {
      const and = [{ locationId }];

      const or = [];
      if (email) {
        or.push({ email });
      }
      if (phone) {
        or.push({ phone: encodeURIComponent(phone) });
      }

      and.push({ or });

      const filters = httpBuildQuery({
        filter: JSON.stringify({
          where: {
            and,
          },
          sort: 'id DESC',
        }),
      });

      const contacts = await this.$store.dispatch('contacts/fetchContacts', filters);
      if (contacts.length) {
        const surveyFilter = {
          where: {
            contactId: contacts[0].id,
          },
          include: ['contact'],
          sort: 'id DESC',
          limit: 1,
        };
        const surveys = await this.$store.dispatch('surveys/getSurveys', surveyFilter);
        if (surveys.length) {
          return surveys[0];
        }
      }
      return null;
    },

    approveDuplicates(approved) {
      if (approved?.length) {
        this.send({ allowDuplicate: true });
      }
    },

    unmask(number) {
      this.form.phone = number.replace(/\D/g, '');
    },
  },
};
</script>
