<template>
  <vs-prompt
    title="RevenueJump"
    :active="active"
    :buttons-hidden="true"
    :style="{ zIndex: 52004 }"
    class="rj-medium-modal rj-confirm-modal"
    @close="close"
  >
    <div class="space-y-2">
      <p>You’ve created your access token!</p>
      <p>
        Copy and paste the access token below as instructed in our
        <a
          href="https://docs.google.com/document/d/1qp2awLR8Uh2Vk3IiIwtVCHdpC2S7OP1XlEbWZ52BUYY/"
          target="_blank"
          rel="noreferrer noopener"
          class="text-primary hover:underline"
        >RevenueJump API Documentation</a>.
      </p>
    </div>

    <div>
      <autosize-textarea
        ref="token"
        class="border border-gray-300 rounded-lg px-2 py-3 mt-1 bg-gray-200"
        readonly
        style="font-family: monospace;"
        :value="token"
      />
    </div>

    <div class="flex items-center justify-end border-t border-gray-300 mt-4 pt-3">
      <vs-button
        type="border"
        @click="removeConfirmation"
      >
        Delete
      </vs-button>

      <vs-button
        class="ml-3"
        @click="copy"
      >
        Copy
      </vs-button>
    </div>
  </vs-prompt>
</template>

<script>
import { AutosizeTextarea } from 'vue-autosizer';

export default {
  name: 'RjRevenueJumpToken',

  components: {
    AutosizeTextarea,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    token() {
      const { token } = this.$store.getters['companies/applications'].find((a) => a.code === 'revenuejump');
      return token;
    },
  },

  methods: {
    async copy() {
      await this.clipboard(this.token, this.$refs.token);
      this.$vs.notify({
        title: 'Copied',
        text: 'RevenueJump token was copied to your clipboard.',
        color: 'success',
      });
    },

    removeConfirmation() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'This action will disable and erase the current access token.',
        acceptText: 'Yes',
        accept: () => this.remove(),
      });
    },

    async remove() {
      const companyId = this.$store.getters['companies/company'].id;
      const payload = {
        companyId,
        tokenId: this.token,
        serviceCode: 'revenuejump',
      };
      await this.$store.dispatch('companies/deleteIntegrationAccessToken', payload);
      this.$emit('token-deleted');
      this.close();
    },

    close() {
      this.$store.commit('companies/SET_APP_MANAGING', { code: 'revenuejump', isManaging: false });
    },

    clipboard(text, element) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        return;
      }

      element.$el.select();
      document.execCommand('Copy');
      element.$el.setSelectionRange(0, 0);
    },
  },
};
</script>

