<template>
  <vs-prompt
    title="Map Fields"
    :active="active"
    :buttons-hidden="!isValid"
    accept-text="Confirm"
    class="rj-confirm-modal"
    :style="{ zIndex: 52008 }"
    @accept="validate"
    @close="close"
  >
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 mb-2 font-bold">
        <p>RevenueJump Fields</p>
      </div>
      <div class="vx-col sm:w-1/2 mb-2 font-bold">
        <p>CSV Fields</p>
      </div>
    </div>

    <!-- First Name -->
    <div class="vx-row items-center">
      <div class="vx-col sm:w-1/2 mt-3">
        <p>First Name:</p>
      </div>
      <div class="vx-col sm:w-1/2 mt-3">
        <rj-select
          v-model="map.firstName"
          v-validate="rules.firstName"
          name="firstName"
          :options="csvFields"
          :reduce="(field) => field.value"
          :clearable="false"
          placeholder="Select field..."
          class="w-full"
        />
      </div>
    </div>

    <!-- Last Name -->
    <div class="vx-row items-center">
      <div class="vx-col sm:w-1/2 mt-3">
        <p>Last Name:</p>
      </div>
      <div class="vx-col sm:w-1/2 mt-3">
        <rj-select
          v-model="map.lastName"
          :options="csvFields"
          :reduce="(field) => field.value"
          :clearable="false"
          placeholder="Select field..."
          class="w-full"
        />
      </div>
    </div>

    <!-- Mobile Phone -->
    <!-- WARNING: Only show if included in the template -->
    <div class="vx-row items-center">
      <div class="vx-col sm:w-1/2 mt-3">
        <p>Mobile Phone:</p>
      </div>
      <div class="vx-col sm:w-1/2 mt-3">
        <rj-select
          ref="phone"
          v-model="map.phone"
          v-validate="rules.phone"
          name="phone"
          :options="csvFields"
          :reduce="(field) => field.value"
          :clearable="false"
          placeholder="Select field..."
          class="w-full"
        />
      </div>
    </div>

    <!-- Email -->
    <!-- WARNING: Only show if included in the template -->
    <div class="vx-row items-center">
      <div class="vx-col sm:w-1/2 mt-3">
        <p>Email:</p>
      </div>
      <div class="vx-col sm:w-1/2 mt-3">
        <rj-select
          ref="email"
          v-model="map.email"
          v-validate="rules.email"
          name="email"
          :options="csvFields"
          :reduce="(field) => field.value"
          :clearable="false"
          placeholder="Select field..."
          class="w-full"
        />
      </div>
    </div>
  </vs-prompt>
</template>

<script>
import { isEmptyObject } from '@/utils';

export default {
  name: 'RjCsvFieldsMapperDialog',

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    headers: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      map: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
      },
    };
  },

  computed: {
    rules() {
      const { phone, email } = this.map;

      return {
        firstName: 'required',
        email: (phone) ? 'required_if:phone,null' : 'required',
        phone: (email) ? 'required_if:email,null' : 'required',
      };
    },

    csvFields() {
      const missing = { label: 'Not Present', value: null };
      const headers = this.headers.map((header) => ({ label: header, value: header }));

      return [missing, ...headers];
    },

    isValid() {
      if (isEmptyObject(this.fields)) {
        return false;
      }

      const { firstName, email, phone } = this.fields;

      return firstName.valid && (email.valid || phone.valid);
    },
  },

  methods: {
    async validate() {
      await this.$validator.validateAll();
      this.$emit('success', this.map);
      this.close();
    },

    reset() {
      this.map = {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
      };
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
