<template>
  <div class="flex flex-wrap w-full items-start sm:space-x-6 mt-2">
    <template v-if="sourceConfig.reviews">
      <div class="mt-4">
        <label class="vs-input--label">Minimum Rating</label>
        <star-rating
          border-color="#F3F4F6"
          :rating="sourceConfig.minRating * 5"
          :border-width="1"
          :rounded-corners="true"
          :star-size="34"
          :show-rating="false"
          :clearable="true"
          :increment="0.5"
          :padding="2"
          :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
          @rating-selected="(rating) => update('sourceConfig' ,'minRating', rating/5)"
        />
      </div>
    </template>

    <div class="mt-4 w-full sm:w-48">
      <vs-input
        :value="sourceConfig.minChars"
        name="minChars"
        label="Minimum Characters"
        class="w-full"
        @input="(value) => update('sourceConfig', 'minChars', parseInt(value))"
      />
    </div>

    <div
      v-if="designConfig.layout !== 'single'"
      class="mt-4 w-full sm:w-48"
    >
      <label class="vs-input--label">Quantity</label>
      <rj-select
        :value="sourceConfig.quantity"
        :options="range(20)"
        :clearable="false"
        placeholder="Select an amount..."
        class="w-full text-base"
        @input="(value) => update('sourceConfig', 'quantity', value)"
      />
    </div>

    <template v-if="sourceConfig.sort === 'random'">
      <div class="mt-4 w-full sm:w-48">
        <label class="vs-input--label">Timeframe</label>
        <rj-select
          :value="sourceConfig.daysAgo"
          :options="periods"
          :reduce="(period) => period.value"
          :clearable="false"
          placeholder="Choose a timeframe..."
          class="w-full text-base"
          @input="(value) => update('sourceConfig' ,'daysAgo', value)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';

export default {
  name: 'RjWidgetFiltersCard',

  components: { StarRating },

  props: {
    sourceConfig: {
      type: Object,
      required: true,
    },

    designConfig: {
      type: Object,
      required: true,
    },

    update: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      periods: [
        { label: '1 Week', value: 7 },
        { label: '30 Days', value: 30 },
        { label: '3 Months', value: 90 },
        { label: '6 Months', value: 180 },
        { label: '1 Year', value: 365 },
      ],
    };
  },

  methods: {
    range(amount) {
      return Array.from(new Array(amount), (x, i) => i + 1);
    },
  },
};
</script>
