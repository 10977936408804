<template>
  <vs-prompt
    title="Preview Widget"
    :active="active"
    :buttons-hidden="true"
    :class="{ 'widget__preview-window': widget.items.length }"
    @close="close"
  >
    <div v-if="widget.items.length">
      <component
        :is="layout"
        :widget="widget"
        :class="[hiddenMobile, settings.design]"
      >
        <template #item="{ item }">
          <component
            :is="design"
            :item="item"
            :settings="settings"
            :class="[boundingBox]"
          >
            <template #avatar="{ avatar, size }">
              <rj-widget-avatar v-bind="{ avatar, size }" />
            </template>

            <template #rating="{ rating, size }">
              <rj-widget-rating v-bind="{ rating, size }" />
            </template>

            <template #quote="{ quote, size }">
              <rj-widget-quote v-bind="{ quote, size }" />
            </template>

            <template #branding>
              <rj-widget-branding v-if="settings.branding" />
            </template>

            <template #source="{ itemType , reviewSite, when }">
              <rj-widget-source v-bind="{ itemType, reviewSite, when }" />
            </template>
          </component>
        </template>
      </component>
    </div>
    <div
      v-else
    >
      <!-- eslint-disable -->
      <p>
        Oops! We are unable to show you a preview of your widget because no testimonials or reviews marked as visible. Please open up the detail window for a
        <router-link
          class="text-primary hover:underline"
          to="/company/results/testimonials">testimonial</router-link> or
        <router-link
          class="text-primary hover:underline"
          to="/company/results/reviews">review</router-link> and turn on the widget visibility.
      </p>
      <!-- eslint-enable -->
    </div>
  </vs-prompt>
</template>

<script>
import { studly } from '@/utils';
import WidgetLayouts from '@/views/company-dashboard/integrations/widgets/previewer/layouts';
import WidgetDesigns from '@/views/company-dashboard/integrations/widgets/previewer/designs';
import RjWidgetQuote from '@/views/company-dashboard/integrations/widgets/previewer/RjWidgetQuote.vue';
import RjWidgetRating from '@/views/company-dashboard/integrations/widgets/previewer/RjWidgetRating.vue';
import RjWidgetAvatar from '@/views/company-dashboard/integrations/widgets/previewer/RjWidgetAvatar.vue';
import RjWidgetSource from '@/views/company-dashboard/integrations/widgets/previewer/RjWidgetSource.vue';
import RjWidgetBranding from '@/views/company-dashboard/integrations/widgets/previewer/RjWidgetBranding.vue';

export default {
  name: 'RjWidgetPreviewer',

  components: {
    RjWidgetQuote,
    RjWidgetRating,
    RjWidgetAvatar,
    RjWidgetSource,
    RjWidgetBranding,
    ...WidgetLayouts,
    ...WidgetDesigns,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    widget() {
      return this.$store.getters['widgets/widget'];
    },

    settings() {
      return this.widget.designConfig;
    },

    layout() {
      return `Rj${studly(this.settings.layout)}LayoutRender`;
    },

    design() {
      return `Rj${studly(this.settings.design)}DesignRender`;
    },

    boundingBox() {
      return this.settings.boundingBox ? 'widget__bounding-box' : null;
    },

    hiddenMobile() {
      return !this.settings.mobile ? 'widget__hidden-mobile' : null;
    },
  },

  methods: {
    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
