<template>
  <vs-prompt
    title="Edit Negative Feedback"
    :active="active"
    class="rj-medium-modal rj-confirm-modal"
    :buttons-hidden="true"
    :style="{ zIndex: 52004 }"
    @close="close"
  >
    <div class="flex flex-row items-center">
      <div class="w-full px-2">
        <p class="text-center sm:text-left font-bold text-xl mb-2">
          {{ feedback.contact.firstName }} {{ feedback.contact.lastName || '' }}
        </p>
        <div class="flex flex-col space-y-1">
          <p>
            <span class="font-bold">Date:</span> {{ format(new Date(feedback.pageVisitedAt), 'MMM. d, yyyy') }}
          </p>
          <p>
            <span class="font-bold">Location:</span> {{ feedback.location.name }}
          </p>
          <p>
            <span class="font-bold">Employee:</span> {{ feedback.user.firstName }} {{ feedback.user.lastName }}
          </p>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <span class="font-medium ml-2">Quote</span>
      <div class="rounded-lg bg-gray-200 p-4 italic">
        <span>"{{ feedback.feedback }}"</span>
      </div>
    </div>

    <div class="mt-3">
      <label class="vs-input--label block mt-3">Status</label>
      <rj-select
        v-model="feedbackStatus"
        :options="statuses"
        :reduce="(s) => s.id"
        :clearable="false"
        placeholder="Select a Status..."
      />
    </div>

    <div class="pt-4 flex justify-end border-t border-gray-300 mt-5">
      <vs-button
        type="border"
        @click="removeConfirmation"
      >
        Delete
      </vs-button>

      <vs-button
        v-if="!pristine"
        class="ml-3"
        @click="save"
      >
        Save
      </vs-button>
    </div>
  </vs-prompt>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'RjEditNegativeFeedbackDialog',

  props: {
    active: {
      type: Boolean,
      required: true,
    },

    feedback: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pristine: true,
      feedbackStatus: this.feedback.feedbackStatus,
      statuses: [
        { id: 'New', label: 'New' },
        { id: 'In Progress', label: 'In Progress' },
        { id: 'Resolved', label: 'Resolved' },
      ],
    };
  },

  watch: {
    feedbackStatus: {
      handler: 'hasChanges',
    },
  },

  methods: {
    format,

    save() {
      const payload = {
        id: this.feedback.id,
        feedbackStatus: this.feedbackStatus,
      };
      this.$emit('saved', payload);
      this.close();
    },

    removeConfirmation() {
      this.$emit('remove', this.feedback);
    },

    hasChanges(current) {
      this.pristine = current === this.feedback.feedbackStatus;
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
