<template>
  <section>
    <header class="flex flex-wrap items-center mb-6">
      <h2 class="pr-3 font-semibold text-gray-600">
        Referrals
      </h2>
    </header>

    <div class="hidden sm:flex items-center py-3">
      <vs-button
        v-if="canSendSurveys"
        @click="sendSurvey"
      >
        Send Survey
      </vs-button>
    </div>


    <template v-if="isHighJump">
      <div class="w-full sm:max-w-sm mx-auto flex flex-col items-center justify-center py-4">
        <img
          src="@/assets/images/pages/ComingSoon.png"
          alt="Coming Soon"
          class="w-64"
        >
        <p class="text-gray-600 text-center font-semibold mt-4">
          When it comes to making the most of your referral surveys, we’re building something really cool.
          <br>
          Stay tuned!
        </p>
      </div>
    </template>

    <template v-else>
      <div class="w-full sm:max-w-sm mx-auto flex flex-col items-center text-center justify-center space-y-6">
        <img
          src="@/assets/images/pages/referrals.png"
          alt="Coming Soon"
        >

        <h2>
          Make it easier for customers to refer friends and family using their choice of media.
        </h2>

        <p class="text-gray-600 font-semibold">
          You are on the RevenueJump Low Jump plan and the Referral Funnel is available on High Jump.
          Would you like more information about our High Jump plan?
        </p>

        <vs-button @click="upgrade">
          Unlock Details
        </vs-button>
      </div>
    </template>
  </section>
</template>

<script>
import { bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';

export default {
  name: 'RjReferrals',

  mixins: [Authorizable],

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    company() {
      return this.$store.getters['companies/company'];
    },

    canSendSurveys() {
      return this.auth.user.companyId || (this.auth.user.partnerId && this.company.partnerCanSendSurveys);
    },
  },

  methods: {
    upgrade() {
      const partner = this.$store.getters['partners/partner'];

      const { id } = this.$store.getters['companies/company'];
      this.$store.dispatch('companies/sendUpgradeRequest', id);

      this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'High Jump Feature',
        text: `Smart move! We just sent ${partner.name} a notification about your interest in High Jump. They should reach out to you soon with more information.`,
        acceptText: 'Ok',
        accept: () => {},
      });
    },

    sendSurvey() {
      return this.isLowJump
        ? bus.$emit('upgrade-company')
        : bus.$emit('show-modal', { modal: 'send-survey' });
    },
  },
};
</script>
