<template>
  <section>
    <template v-if="loading">
      <p>Loading Locations...</p>
    </template>

    <template v-else>
      <rj-locations-list
        :locations="locations"
        @selected="jumpToLocation"
      >
        <template #actions>
          <vs-button
            class="w-full"
            @click="add"
          >
            Add Location
          </vs-button>
        </template>

        <template #table-row="{ location }">
          <!-- Name -->
          <vs-td class="whitespace-no-wrap">
            <p class="font-medium truncate">
              {{ location.name }}
            </p>
          </vs-td>

          <!-- Address -->
          <vs-td class="whitespace-no-wrap">
            <p class="font-medium truncate">
              {{ location.addressStreet }}
            </p>
          </vs-td>

          <!-- Reputation Grade -->
          <vs-td class="whitespace-no-wrap w-full ml-auto mr-auto">
            <p class="w-full text-center">
              {{ location.reputationGrade[location.reputationGrade.length - 1].rpg }}%
            </p>
          </vs-td>

          <!-- Competitor Review Score -->
          <vs-td class="whitespace-no-wrap">
            <p class="w-full text-center">
              {{ location.competitorReviewScore }}
            </p>
          </vs-td>

          <!-- Satisfaction Score -->
          <vs-td class="whitespace-no-wrap">
            <p class="w-full text-center">
              {{ location.satisfactionScore }}%
            </p>
          </vs-td>

          <!-- Thirty Day Count -->
          <vs-td class="whitespace-no-wrap">
            <p class="font-medium w-full text-center">
              {{ location.thirtyDayCount[location.thirtyDayCount.length - 1].value }}
            </p>
          </vs-td>

          <!-- Actions -->
          <vs-td class="whitespace-no-wrap">
            <span class="w-full flex justify-center">
              <vs-button
                icon-pack="feather"
                icon="icon-settings"
                type="flat"
                class="inline-block"
              />
            </span>
          </vs-td>
        </template>
      </rj-locations-list>
    </template>
  </section>
</template>

<script>
import { httpBuildQuery, bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import RjLocationsList from '@/views/common/locations/RjLocationsList.vue';

export default {
  name: 'RjCompanyLocations',

  components: {
    RjLocationsList,
  },

  mixins: [Authorizable],

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    locations() {
      return this.$store.getters['locations/locations'];
    },

    partner() {
      return this.$store.getters['partners/partner'];
    },
  },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      const companyId = this.$store.getters['companies/company'].id;
      const filters = httpBuildQuery({
        includeSurveyStats: 1,
        filter: JSON.stringify({
          where: { active: true, companyId },
        }),
      });

      await this.$store.dispatch('locations/fetchLocations', filters);
      this.loading = false;
    },

    add() {
      if (this.isLowJump) {
        bus.$emit('upgrade-company');
        return;
      }

      this.$vs.dialog({
        color: 'primary',
        title: 'Add Location',
        text: `Click the button below to request that ${this.partner.name} add a location to your RevenueJump account.`,
        acceptText: 'Send Request',
        accept: this.requestLocation,
      });
    },

    requestLocation() {
      // trigger email notification to the partner
      this.$vs.notify({
        title: 'Request Sent',
        text: '[PartnerName] will be notified of your request to add a new location.',
        color: 'success',
      });
    },

    jumpToLocation(location) {
      this.$router.push({
        name: 'company-location',
        params: { location: location.id },
      });
    },
  },
};
</script>
