<template>
  <vx-card title="Top Review Sites">
    <template slot="no-body">
      <vs-table
        :data="topSites"
        class="table-dark-inverted mt-5"
        :no-data-text="'Empty'"
      >
        <template slot="thead">
          <vs-th>Site</vs-th>
          <vs-th class="text-right">
            Count
          </vs-th>
          <vs-th>Rating</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            v-for="(site, index) in data"
            :key="index"
          >
            <vs-td>
              <div class="flex items-center">
                <img
                  :src="`${reviewSiteLogosUrl}/${site.reviewSiteId}/${site.reviewSiteLogo}`"
                  :alt="site.name"
                  class="mr-3 w-6"
                >
                <p class="font-medium">
                  {{ site.name || '' }}
                </p>
              </div>
            </vs-td>
            <vs-td>
              <span class="font-bold">{{ site.count }}</span>
            </vs-td>
            <vs-td>
              <div class="flex items-center">
                <span class="mt-1 font-bold mr-2 text-right">
                  {{ (site.rating * 5).toPrecision(2) }}
                </span>
                <star-rating
                  border-color="#F3F4F6"
                  :rating="site.rating * 5"
                  :border-width="1"
                  :rounded-corners="true"
                  :star-size="16"
                  :show-rating="false"
                  :read-only="true"
                  :increment="0.5"
                  :padding="2"
                  :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </template>
  </vx-card>
</template>

<script>
import StarRating from 'vue-star-rating';

const { VUE_APP_RJ_REVIEW_SITE_LOGOS_URL } = process.env;

export default {
  name: 'RjTopReviewSitesTable',

  components: { StarRating },

  props: {
    topSites: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      reviewSiteLogosUrl: VUE_APP_RJ_REVIEW_SITE_LOGOS_URL,
    };
  },
};
</script>
