<template>
  <div class="flex flex-wrap items-end mt-2 sm:mt-0">
    <!-- Standard -->
    <div class="flex flex-col items-center py-3 w-1/2 sm:w-auto sm:mr-16">
      <label for="standard">
        <img
          src="@/assets/images/widget/design/standard.jpg"
          srcset="@/assets/images/widget/design/standard.jpg 1x, @/assets/images/widget/design/standard@2x.jpg 2x"
          alt="Widget Single Layout"
          class="cursor-pointer w-48 sm:w-auto"
        >
      </label>
      <vs-radio
        id="standard"
        :value="designConfig.design"
        vs-value="standard"
        vs-name="design"
        class="text-base mt-1"
        @input="(value) => setDesign({ design: value, minWidth: 400 })"
      >
        Standard
      </vs-radio>
    </div>

    <!-- Wide -->
    <div
      class="flex flex-col items-center py-3 w-1/2 sm:w-auto sm:mr-16"
      @click="checkCompanyPlan"
    >
      <label for="wide">
        <img
          src="@/assets/images/widget/design/wide.jpg"
          srcset="@/assets/images/widget/design/wide.jpg 1x, @/assets/images/widget/design/wide@2x.jpg 2x"
          alt="Widget Single Layout"
          class="cursor-pointer w-48 sm:w-auto"
          :style="{ filter: isLowJump ? 'grayscale(100%)' : 'none' }"
        >
      </label>
      <vs-radio
        id="wide"
        :value="designConfig.design"
        vs-value="wide"
        vs-name="design"
        class="text-base mt-1"
        :disabled="isLowJump"
        @input="(value) => setDesign({ design: value, minWidth: 510 })"
      >
        Wide
      </vs-radio>
    </div>

    <!-- Quote Top -->
    <div
      class="flex flex-col items-center py-3 w-1/2 sm:w-auto sm:mr-16"
      @click="checkCompanyPlan"
    >
      <label for="quote-top">
        <img
          src="@/assets/images/widget/design/quote-top.jpg"
          srcset="@/assets/images/widget/design/quote-top.jpg 1x, @/assets/images/widget/design/quote-top@2x.jpg 2x"
          alt="Widget Single Layout"
          class="cursor-pointer w-48 sm:w-auto"
          :style="{ filter: isLowJump ? 'grayscale(100%)' : 'none' }"
        >
      </label>
      <vs-radio
        id="quote-top"
        :value="designConfig.design"
        vs-value="quote-top"
        vs-name="design"
        class="text-base mt-1"
        :disabled="isLowJump"
        @input="(value) => setDesign({ design: value, minWidth: 400 })"
      >
        Quote Top
      </vs-radio>
    </div>

    <!-- Avatar Top -->
    <div
      class="flex flex-col items-center py-3 w-1/2 sm:w-auto sm:mr-16"
      @click="checkCompanyPlan"
    >
      <label for="avatar-top">
        <img
          src="@/assets/images/widget/design/avatar-top.jpg"
          srcset="@/assets/images/widget/design/avatar-top.jpg 1x, @/assets/images/widget/design/avatar-top@2x.jpg 2x"
          alt="Widget Single Layout"
          class="cursor-pointer w-48 sm:w-auto"
          :style="{ filter: isLowJump ? 'grayscale(100%)' : 'none' }"
        >
      </label>
      <vs-radio
        id="avatar-top"
        :value="designConfig.design"
        vs-value="avatar-top"
        vs-name="design"
        class="text-base mt-1"
        :disabled="isLowJump"
        @input="(value) => setDesign({ design: value, minWidth: 400 })"
      >
        Avatar Top
      </vs-radio>
    </div>

    <!-- Vertical -->
    <div
      class="flex flex-col items-center w-1/2 sm:w-auto py-3"
      @click="checkCompanyPlan"
    >
      <label for="vertical">
        <img
          src="@/assets/images/widget/design/vertical.jpg"
          srcset="@/assets/images/widget/design/vertical.jpg 1x, @/assets/images/widget/design/vertical@2x.jpg 2x"
          alt="Widget Single Layout"
          class="cursor-pointer w-48 sm:w-auto"
          :style="{ filter: isLowJump ? 'grayscale(100%)' : 'none' }"
        >
      </label>
      <vs-radio
        id="vertical"
        :value="designConfig.design"
        vs-value="vertical"
        vs-name="design"
        class="text-base mt-1"
        :disabled="isLowJump"
        @input="(value) => setDesign({ design: value, minWidth: 250 })"
      >
        Vertical
      </vs-radio>
    </div>
  </div>
</template>

<script>
import { bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';

export default {
  name: 'RjWidgetDesignPicker',

  mixins: [Authorizable],

  props: {
    designConfig: {
      type: Object,
      required: true,
    },

    update: {
      type: Function,
      required: true,
    },
  },

  methods: {
    setDesign({ design, minWidth }) {
      this.update('designConfig', 'design', design);
      this.update('designConfig', 'width', minWidth);
    },

    checkCompanyPlan() {
      if (this.isLowJump) {
        bus.$emit('upgrade-company');
      }
    },
  },
};
</script>
