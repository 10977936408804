<template>
  <vs-prompt
    title="Warning: Error!"
    :active="active"
    :buttons-hidden="true"
    accept-text="Confirm"
    class="rj-confirm-modal"
    :style="{ zIndex: 52008 }"
    @close="close"
  >
    <p>
      We have found the following errors in the CSV file you uploaded:
    </p>

    <p
      v-if="tooBig"
      class="font-bold py-2"
    >
      The file has more than 500 records. Only the first 500 will be processed.
    </p>

    <div class="my-5">
      <table class="table-fixed w-full border-collapse text-sm">
        <thead>
          <tr>
            <th class="w-1/6 py-0 px-2">
              Line
            </th>
            <th class="w-5/6 py-0 px-2">
              Error
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="error in combinedErrors.slice(0, numRowsToShow)"
            :key="error.original"
          >
            <td class="w-1/6 py-1 px-3 rounded-tl rounded-tl rounded-bl">
              {{ error.index + 1 }}
            </td>
            <td class="w-5/6 py-1 px-3">
              {{ error.errors.join(', ') }}
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="combinedErrors.length > numRowsToShow">
        {{ combinedErrors.length - numRowsToShow }} more rows also contain errors
      </p>
    </div>

    <p class="text-gray-600">
      <span class="font-bold">What next?</span>
      You can fix the errors and start over with a new CSV file, or we can skip these rows and send the rest.
    </p>

    <div class="flex items-center justify-end border-t border-gray-300 mt-4 pt-3">
      <vs-button
        type="border"
        class="mr-3"
        @click.prevent="startOver"
      >
        Start Over
      </vs-button>

      <vs-button @click.prevent="sendAnyway">
        Send Anyway
      </vs-button>
    </div>
  </vs-prompt>
</template>

<script>
export default {
  name: 'RjCsvErrorDialog',

  props: {
    active: {
      type: Boolean,
      required: true,
    },
    csvErrors: {
      type: Array,
      default: () => ([]),
    },
    tooBig: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      numRowsToShow: 5,
    };
  },

  computed: {
    combinedErrors() {
      const rowErrors = [];
      this.csvErrors.forEach((err) => {
        const index = rowErrors.findIndex((e) => e.index === err.index);
        if (index === -1) {
          rowErrors.push({
            index: err.index,
            original: err.original,
            errors: [err.error],
          });
        } else {
          rowErrors[index].errors.push(err.error);
        }
      });
      return rowErrors;
    },
  },

  methods: {
    startOver() {
      this.$emit('start-over');
      this.close();
    },

    sendAnyway() {
      console.log(this.csvErrors.map((e) => e.index));
      this.$emit('send-valid', this.csvErrors.map((e) => e.index));
      this.close();
    },

    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
