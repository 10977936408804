import { sub } from 'date-fns';

const now = new Date();
const ninetyDaysAgo = sub(new Date(), { days: 90 });

export default function filters() {
  return {
    startDate: ninetyDaysAgo,
    endDate: now,
    location: null,
    rating: null,
    reviewSite: null,
    appearOnWidget: null,
    getSharedReviewSites: true,
  };
}

